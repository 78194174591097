import React, { useEffect, useState } from "react";

import { Heading, MarketingHero, MarketingLayout, Text } from "components";

import { useWindowSize } from "utilities/hooks";

import { QuoteProps } from "components/Quotes/Quote";
import Quotes from "components/Quotes/Quotes";
import about1 from "images/marketing/about-1.png";
import about2 from "images/marketing/about-2.png";
import bgImage from "images/marketing/about-hero.png";

import sb, { StoryblokService } from "utilities/storyblok";

import css from "./About.module.scss";

const HeroContent = () => {
  const { isBigMobile } = useWindowSize();

  return (
    <MarketingHero bgImage={bgImage}>
      <div className={css.HeroContent}>
        <div className={css.heroLeft}>
          <Heading className={css.HeroHeading} variant="h1">
            About Us
          </Heading>
          <Text color="white" size={isBigMobile ? "large" : "medium"}>
            We made Agility Connect for one reason:
            <br />
            <b>to help more people find a career that they love.</b>
          </Text>
        </div>
      </div>
    </MarketingHero>
  );
};

interface WhoWhatWhy {
  _uid: string;
  title: string;
  text: string;
  image: {
    filename: string;
  };
}

export const AboutIndex = () => {
  const [quotes, setQuotes] = useState<Array<QuoteProps>>([]);
  const [wwws, setWwws] = useState<Array<WhoWhatWhy>>([]);

  useEffect(() => {
    const getStories = async () => {
      const stories = await sb.get("about");
      const testimonials = StoryblokService.getStory(
        "Testimonials",
        stories
      ) as Array<QuoteProps>;
      const wwwData = StoryblokService.getStory(
        "WhoWhatWhys",
        stories
      ) as Array<WhoWhatWhy>;

      setQuotes(testimonials);
      setWwws(wwwData);
    };

    getStories();
  }, []);

  return (
    <MarketingLayout hero={<HeroContent />}>
      <>
        <div className={css.whoWhatWhy}>
          <div className={css.whoWhatWhyContent}>
            {wwws.map((item) => (
              // eslint-disable-next-line no-underscore-dangle
              <div className={css.whoWhatWhyItem} key={item._uid}>
                <div className={css.whoWhatWhyTop}>
                  <img src={item.image.filename} alt={item.title} />
                  <Heading variant="h2">{item.title}</Heading>
                </div>
                <Text size="medium" color="black">
                  {item.text}
                </Text>
              </div>
            ))}
          </div>
        </div>
        <div className={css.bigPoints}>
          <div className={css.bigPoint}>
            <img
              src={about1}
              className={css.bigPointImage}
              alt="We place people, first."
            />
            <div className={css.bigPointText}>
              <h3>We place people, first.</h3>
              <Text size="large" color="black">
                We listen, understand, advise, and connect your referrals to the
                right opportunities with our hiring partners.
              </Text>
              <Text size="large" color="black">
                And if we can&apos;t get them their dream job, we&apos;ll make
                sure they have the tools to get it elsewhere.
              </Text>
            </div>
          </div>
          <div className={css.bigPoint}>
            <img
              src={about2}
              className={css.bigPointImage}
              alt="We value diversity"
            />
            <div className={css.bigPointText}>
              <h3>We value diversity</h3>
              <Text size="large" color="black">
                We believe that diversity and inclusion are invaluable in the
                workplace. Greater diversity has proven to lead to stronger
                teams and more profitability. We have helped thousands of women,
                BIPOC, and men to get their best next job.
              </Text>
            </div>
          </div>
        </div>
        <Quotes quotes={quotes} />
      </>
    </MarketingLayout>
  );
};
