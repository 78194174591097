import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ReferralCompany {
  name: string;
  industry: number;
  placement_fee?: number;
}

export interface ReferralJob {
  name: string;
  reward: number;
  job_type: number;
  company: ReferralCompany;
  bill_rate?: number;
  pay_rate?: number;
  duration_months?: number;
  salary_high?: number;
}

export interface ReferralActivity {
  datetime_created: string;
  status_from: number;
  status_to: number;
}

export interface Referral {
  id: number;
  status: number;
  last_updated: string;
  job: ReferralJob;
  activity_set: ReferralActivity[];
}

export interface Candidate {
  id: number;
  is_active: boolean;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  last_updated: string;
  date_created: string;
  referral_set: Array<Referral>;
}

export interface FetchCandidatesForRecruiterPayload {
  recruiter?: string;
}

export interface ReferralsState {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<Candidate>;
}

export const initialReferralsState: ReferralsState = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

const referralsSlice = createSlice({
  name: "referrals",
  initialState: initialReferralsState,
  reducers: {
    fetchReferrals: (state) => state,
    fetchReferralsSuccess: (
      state,
      action: PayloadAction<Partial<ReferralsState>>
    ) => {
      const payload = action.payload || {};
      return {
        ...state,
        ...payload,
      };
    },
    fetchReferralsFailure: (state, action: PayloadAction<string>) => state,

    fetchCandidatesForRecruiter: (
      state,
      action: PayloadAction<FetchCandidatesForRecruiterPayload>
    ) => state,
    fetchCandidatesForRecruiterSuccess: (
      state,
      action: PayloadAction<Partial<ReferralsState>>
    ) => {
      const payload = action.payload || {};
      return {
        ...state,
        ...payload,
      };
    },
    fetchCandidatesForRecruiterFailure: (
      state,
      action: PayloadAction<string>
    ) => state,
  },
});

export const { actions: referralsActions, reducer } = referralsSlice;
