import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";

import { Text } from "components";

import css from "./AgilityLoader.module.scss";

export interface AgilityLoaderProps {
  text?: string;
}

export const AgilityLoader = ({ text = "" }: AgilityLoaderProps) => {
  const [reset, setReset] = useState(false);
  const isMountedRef = useRef(true);

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
    }
    return () => {
      isMountedRef.current = false;
    };
  });

  const { transform } = useSpring({
    from: { transform: "translate(-1)" },
    to: {
      transform: "translate(1)",
    },
    config: {
      tension: 100,
    },
    reset,
    onRest: () => {
      if (isMountedRef.current) {
        setReset(true);
      }
    },
    onStart: () => {
      if (isMountedRef.current) {
        setReset(false);
      }
    },
  });

  return (
    <div className={css.Wrapper} data-cy="AgilityLoader">
      <div className={css.ContentWrapper}>
        <svg
          viewBox="0 0 216 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={css.Svg}
        >
          <defs>
            <animated.linearGradient
              id="ac-logo-orange-no-text-gradient"
              gradientTransform={transform}
            >
              <stop offset="0.01" stopColor="#ED7F00" />
              <stop offset="0.5" stopColor="#e8a04d" />
              <stop offset="0.99" stopColor="#ED7F00" />
            </animated.linearGradient>
          </defs>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.208 27.4908C196.292 65.5978 204.889 90.3454 213.43 114.658C225.684 149.542 191.719 199.264 162.332 199.377C162.195 199.378 43.9187 199.299 43.1251 199.264C45.0983 196.574 118.154 100.477 119.9 97.9841C120.766 99.7401 132.191 125.829 132.62 127.374C133.168 129.361 131.91 129.753 130.193 129.739C124.39 129.69 118.584 129.828 112.783 129.676C109.801 129.597 107.963 130.736 106.205 133.132C103.456 136.875 90.1281 154.235 87.5977 157.527C86.2914 159.226 86.956 159.862 88.6756 160.145C89.419 160.268 130.915 160.3 150.895 160.217C167.93 160.145 176.512 141.556 172.471 130.121C168.43 118.686 149.287 72.8305 142.646 54.7148C136.004 36.5992 115.593 38.2158 103.961 54.7148C101.9 57.6382 12.9594 173.989 9.96252 177.483C6.96564 180.976 -1.77443 168.394 0.320818 140.896C2.41607 113.398 68.9639 36.0796 90.0802 16.1242C111.196 -3.83119 160.124 -10.6161 178.208 27.4908Z"
            fill="url(#ac-logo-orange-no-text-gradient)"
          />
        </svg>
        <Text tag="span" className={css.Text}>
          {text}
        </Text>
      </div>
    </div>
  );
};
