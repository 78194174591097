/**
 * Registering a saga:
 *
 * 1. Create a function generator for the saga and yield all the desired actions.
 * 2. Register the saga in the "sagas" object at the end of the file.
 */

import {
  StrictEffect,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import { isAfter, parse } from "date-fns";
import { batchActions } from "redux-batched-actions";
import jwtDecode from "jwt-decode";
import { push } from "connected-react-router";

import {
  ApiRequestProps,
  doAPIRequest,
  registerSagas,
  responseSuccess,
} from "reducks/sagas";

import { SENT_BY_USER_KEY, userActions } from "reducks/user";
import { events, track } from "utilities/analytics";
import { Action } from "types/redux";
import { LOGIN_PATH } from "routes";
import { routeAfterLoginLocalStorageKey } from "pages/forgotPassword";

import {
  LoginOauthPayload,
  LoginWithEmailPayload,
  ResetPasswordPayload,
  SignUpWithEmailPayload,
  authActions,
  forgotPasswordActions,
} from "./ducks";
import { selectAccessToken } from "./selectors";

export const REFRESH_TOKEN_KEY = "refreshToken";

export interface DecodedJWT {
  exp: number;
  jti: string;
  token_type: "access" | "refresh";
  user_id: number;
}

export const SERVICES = {
  github: "GitHub",
  facebook: "Facebook",
  linkedin: "LinkedIn",
};

function* onLoginOauthSaga(
  action: Action<LoginOauthPayload>
): Generator<
  StrictEffect,
  any,
  {
    body: any;
    status: number;
  }
> {
  const { payload } = action;
  const options: ApiRequestProps = {
    method: "POST",
    body: {
      code: payload.code,
      redirect_uri: payload.redirect_uri,
    },
    path: `/oauth/${payload.service}/`,
    includeAuth: false,
  };

  const { body, status } = yield call(doAPIRequest, options);

  if (!responseSuccess(status)) {
    const service = SERVICES[payload.service];
    return yield put(
      authActions.oauthFailure(
        `Sorry, there was an issue logging in with ${
          service || payload.service
        }`
      )
    );
  }

  const decodedJwt = jwtDecode<DecodedJWT>(body.access);

  yield put(authActions.updateAccessToken(body.access));
  yield put(userActions.fetchUser(decodedJwt.user_id.toString()));

  yield put(
    batchActions([
      authActions.oauthSuccess(),
      authActions.updateRefreshToken(body.refresh),
      authActions.updateAuthenticatedWith(payload.service),
    ])
  );

  const trackLogin = () => {
    track(events.LOGGED_IN, {
      oauth: true,
      service: payload.service,
    });
  };

  yield call(trackLogin);
}

function* onUpdateRefreshToken(action: Action<string>) {
  const { payload } = action;
  yield call(
    { context: localStorage, fn: localStorage.setItem },
    REFRESH_TOKEN_KEY,
    payload
  );
}

function* onUpdateAuthenticatedWith(action: Action<string>) {
  const { payload } = action;
  yield call(
    { context: localStorage, fn: localStorage.setItem },
    "authenticatedWith",
    payload
  );
}

function* loginWithEmailSaga(
  action: Action<LoginWithEmailPayload>
): Generator<StrictEffect, any, { body: any; status: number }> {
  const { payload } = action;

  // Make an API request to authenticate the user
  const options: ApiRequestProps = {
    method: "POST",
    body: {
      email: payload.email,
      password: payload.password,
    },
    path: `/auth/get-token/`,
    includeAuth: false,
  };
  const { body, status } = yield call(doAPIRequest, options);

  // If authentication failed on the server, return the error.
  if (!responseSuccess(status)) {
    return yield put(authActions.loginWithEmailFailure(body.detail));
  }

  // If we succeeded in authenticating, decode the payload of the JWT and
  // update the access and refresh tokens in Redux
  const decodedJwt = jwtDecode<DecodedJWT>(body.access);

  // Update the access token and refresh token
  yield put(authActions.updateAccessToken(body.access));
  yield put(authActions.updateAuthenticatedWith("email"));
  yield put(authActions.updateRefreshToken(body.refresh));

  // Get user details
  yield put(userActions.fetchUser(decodedJwt.user_id.toString()));
  yield put(authActions.loginWithEmailSuccess());

  yield call(track, events.LOGGED_IN);
}

function* logOutSaga() {
  try {
    // remove the token
    yield call(
      { context: localStorage, fn: localStorage.removeItem },
      REFRESH_TOKEN_KEY
    );
  } catch (error) {
    // log any error that happens
    console.error(error); // eslint-disable-line no-console
    yield put(authActions.logOutFailure());
  }

  // clear the access token from state and remove the user information on success
  yield put(userActions.logoutUser());
  yield put(authActions.logOutSuccess());
}

function* onSignUpWithEmailSaga(
  action: Action<SignUpWithEmailPayload>
): Generator<StrictEffect, any, { body: any; status: number }> {
  const { payload } = action;

  const options: ApiRequestProps = {
    method: "POST",
    body: {
      first_name: payload.firstName,
      last_name: payload.lastName,
      email: payload.email,
      newPassword: payload.password,
      confirmNewPassword: payload.password,
    },
    path: `/accounts/create/`,
    includeAuth: false,
  };

  const { body, status } = yield call(doAPIRequest, options);

  // If user creation failed on the server, return the error.
  if (!responseSuccess(status)) {
    if (body.email) {
      return yield put(
        authActions.signUpWithEmailFailure(
          "This email already exists. Please use a different one or sign in."
        )
      );
    }

    return yield put(
      authActions.signUpWithEmailFailure(
        "Something went wrong. Please try again."
      )
    );
  }

  // If we succeeded in authenticating, decode the payload of the JWT and
  // update the access and refresh tokens in Redux
  const decodedJwt = jwtDecode<DecodedJWT>(body.access);

  // Update the access token and refresh token
  yield put(authActions.updateAccessToken(body.access));
  yield put(authActions.updateAuthenticatedWith("email"));
  yield put(authActions.updateRefreshToken(body.refresh));

  // Get user details
  yield put(userActions.fetchUser(decodedJwt.user_id.toString()));
  yield put(authActions.signUpWithEmailSuccess());
}

function* getRefreshToken(): any {
  return yield call(
    { context: localStorage, fn: localStorage.getItem },
    REFRESH_TOKEN_KEY
  );
}

function* getAuthenticatedWith(): any {
  return yield call(
    { context: localStorage, fn: localStorage.getItem },
    "authenticatedWith"
  );
}

function* getSentByUserPublicId(): any {
  return yield call(
    { context: localStorage, fn: localStorage.getItem },
    SENT_BY_USER_KEY
  );
}

const getRefreshTokenRequestOptions = (
  refreshToken: string
): ApiRequestProps => {
  return {
    method: "POST",
    body: {
      refresh: refreshToken,
    },
    path: "/auth/refresh-token/",
    includeAuth: false,
  };
};

function* onCheckAuth(): any {
  const accessToken = yield select(selectAccessToken);

  if (accessToken) {
    const decodedJwt = jwtDecode<DecodedJWT>(accessToken);
    const expDate = parse(decodedJwt.exp.toString(), "t", new Date());
    const now = new Date();

    // If the access token isn't expired, end the saga. We don't need to
    // do anything else.
    if (!isAfter(now, expDate)) {
      // Access token is not expired
      return yield put(authActions.checkAuthSuccess());
    }
    // Access token is expired, remove it
    yield put(authActions.updateAccessToken(""));
  }

  const refreshToken = yield call(getRefreshToken);
  const authenticatedWith = yield call(getAuthenticatedWith);
  const sentByUserPublicId = yield call(getSentByUserPublicId);

  // Settings page breaks if we don't have authenticatedWith in localStorage
  // so log out the user.
  if (!authenticatedWith || authenticatedWith.length === 0) {
    return yield put(authActions.checkAuthFailure("Not authorized"));
  }

  // If we've stored another user's public ID because they sent this user
  // a shared job link at some point in the past, populate that.
  if (sentByUserPublicId) {
    yield put(userActions.setSentByUser(sentByUserPublicId));
  }

  // If there's a refresh token in local storage, try to get a new access token
  if (refreshToken) {
    const decodedJwt = jwtDecode<DecodedJWT>(refreshToken);
    const expDate = parse(decodedJwt.exp.toString(), "t", new Date());
    const now = new Date();

    // If the refresh token is not expired, we're good to try getting a new
    // access token
    if (!isAfter(now, expDate)) {
      const options = getRefreshTokenRequestOptions(refreshToken);
      yield put(authActions.fetchNewToken());
      const { body, status } = yield call(doAPIRequest, options);

      // If it failed, show an error
      if (!responseSuccess(status)) {
        return yield put(
          batchActions([
            authActions.checkAuthFailure("Not authorized"),
            authActions.fetchNewTokenComplete(),
          ])
        );
      }

      // Otherwise, continue authentication process
      yield put(
        batchActions([
          authActions.updateAccessToken(body.access),
          authActions.checkAuthSuccess(),
          authActions.fetchNewTokenComplete(),
          authActions.updateAuthenticatedWith(authenticatedWith),
        ])
      );

      // Get the user details if we've refreshed the token successfully.
      // This is necessary when someone comes to the site without going through
      // the login process, if they're already logged in and refresh the page
      // for instance
      return yield put(userActions.fetchUser(decodedJwt.user_id.toString()));
    }

    // Refresh token is expired, remove it
    yield call(
      { context: localStorage, fn: localStorage.removeItem },
      REFRESH_TOKEN_KEY
    );
  }
  // No Access or Refresh token, or they are both expired
  return yield put(authActions.checkAuthFailure("Not authorized"));
}

function* onCheckAuthFailure() {
  // make sure access and refresh tokens are cleared
  yield put(authActions.updateAccessToken(""));
  yield call(
    { context: localStorage, fn: localStorage.removeItem },
    REFRESH_TOKEN_KEY
  );
  return put(push(LOGIN_PATH));
}

function* onPollCheckAuth(): Generator<StrictEffect, void, any> {
  while (true) {
    yield delay(4 * 60 * 1000); // 4 minutes
    const refreshToken = yield call(getRefreshToken);
    if (refreshToken) {
      const options = getRefreshTokenRequestOptions(refreshToken);
      const { body, status } = yield call(doAPIRequest, options);
      // This is intentionally pretty passive (i.e. not taking action if there is an error).
      // If there is a problem with authentication, it will be caught when checkAuth is run
      // with the next authenticated api request.
      if (responseSuccess(status)) {
        yield put(authActions.updateAccessToken(body.access));
      }
    }
  }
}

/**
 * Saga for sending a user an email when the forgot their password.
 */
function* sendForgotPasswordEmailSaga(
  action: Action<string>
): Generator<StrictEffect, any, { status: number }> {
  const { payload } = action;

  // Make an API request to send the user a password reset email
  const options: ApiRequestProps = {
    method: "POST",
    body: {
      email: payload,
    },
    path: `/accounts/password/reset_email/send/`,
    includeAuth: false,
  };
  const { status } = yield call(doAPIRequest, options);

  // If the email doesn't exist in the system, we'll get back a 404.
  if (!responseSuccess(status)) {
    return yield put(forgotPasswordActions.sendForgotPasswordEmailFailure());
  }

  yield put(forgotPasswordActions.sendForgotPasswordEmailSuccess());
}

function* resetPasswordSaga(
  action: Action<ResetPasswordPayload>
): Generator<StrictEffect, any, any> {
  const {
    password,
    passwordDoubleTake: password_double_take,
    token: password_reset_token,
  } = action.payload;

  // Make an API request to set the user's new password
  const options: ApiRequestProps = {
    method: "PUT",
    body: {
      password,
      password_double_take,
      password_reset_token,
    },
    path: `/accounts/password/set/`,
    includeAuth: false,
  };
  const { body, status } = yield call(doAPIRequest, options);

  // If the token isn't associated with a user, we'll get back a 404.
  if (!responseSuccess(status)) {
    return yield put(forgotPasswordActions.resetPasswordError());
  }

  yield put(forgotPasswordActions.resetPasswordSuccess(body.email));
}

const sagas = {
  [`${authActions.loginOAuth}`]: onLoginOauthSaga,
  [`${authActions.loginWithEmail}`]: loginWithEmailSaga,
  [`${authActions.logOut}`]: logOutSaga,
  [`${authActions.signUpWithEmail}`]: onSignUpWithEmailSaga,
  [`${authActions.updateRefreshToken}`]: onUpdateRefreshToken,
  [`${authActions.updateAuthenticatedWith}`]: onUpdateAuthenticatedWith,
  [`${authActions.checkAuth}`]: onCheckAuth,
  [`${authActions.checkAuthFailure}`]: onCheckAuthFailure,
  [`${forgotPasswordActions.sendForgotPasswordEmail}`]: sendForgotPasswordEmailSaga,
  [`${forgotPasswordActions.resetPassword}`]: resetPasswordSaga,
};

function* watchPollCheckAuth() {
  while (true) {
    yield take(authActions.pollCheckAuth);
    yield race([call(onPollCheckAuth), take(authActions.stopPollCheckAuth)]);
  }
}

function* clearResetPasswordLocalStorageOnLogin() {
  yield call(
    [localStorage, localStorage.removeItem],
    routeAfterLoginLocalStorageKey
  );
}
function* watchLoginSuccess() {
  yield takeEvery(
    [authActions.loginWithEmailSuccess, authActions.oauthSuccess],
    clearResetPasswordLocalStorageOnLogin
  );
}

export const authSagas = [
  registerSagas(sagas),
  watchPollCheckAuth(),
  watchLoginSuccess(),
];
