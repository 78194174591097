import React, { useEffect, useState } from "react";

import { DialogContent, DialogOverlay, DialogProps } from "@reach/dialog";
import { animated, useSpring } from "react-spring";
import cn from "classnames";

import { Heading } from "components";

import { ReactComponent as CloseSvg } from "images/close.svg";
import css from "./Modal.module.scss";

export interface ModalProps extends DialogProps {
  includeCloseButton?: boolean;
  size?: "default" | "full";
  padded?: boolean;
  title?: string;
}

const ModalHeader = ({
  includeCloseButton,
  title,
  onDismiss,
}: Pick<ModalProps, "includeCloseButton" | "title"> & {
  onDismiss: () => void;
}) => {
  return (
    <div className={css.Header}>
      {title && (
        <Heading align="left" variant="h3" className={css.Title}>
          {title}
        </Heading>
      )}
      {includeCloseButton && (
        <button
          type="button"
          onClick={onDismiss}
          className={css.Close}
          data-cy="modal-close"
        >
          <CloseSvg className={css.CloseSvg} />
        </button>
      )}
    </div>
  );
};

const Modal = ({
  isOpen = false,
  onDismiss = () => {},
  children,
  className,
  size = "default",
  includeCloseButton = false,
  title,
  padded = false,
  allowPinchZoom,
  ...rest
}: ModalProps) => {
  const [modalMounted, setModalMounted] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setModalMounted(true);
    }
  }, [isOpen]);

  const handleSpringRest = () => {
    if (!isOpen) {
      setModalMounted(false);
      onDismiss();
    }
  };
  const springProps = useSpring({
    opacity: isOpen ? 1 : 0,
    onRest: handleSpringRest,
  });
  const AnimatedOverlay = animated(DialogOverlay);
  const AnimatedContent = animated(DialogContent);
  const ariaLabel = rest["aria-label"] || "dialog";

  return (
    <AnimatedOverlay
      isOpen={modalMounted}
      onDismiss={onDismiss}
      className={css.Overlay}
      style={springProps}
      allowPinchZoom={allowPinchZoom}
    >
      <AnimatedContent
        {...rest}
        aria-label={ariaLabel}
        className={cn(css.Modal, [css[`size-${size}`]], className)}
        style={springProps}
      >
        {(title || includeCloseButton) && (
          <ModalHeader
            title={title}
            includeCloseButton={includeCloseButton}
            onDismiss={onDismiss}
          />
        )}
        <div className={cn(css.Content, { [css.isPadded]: padded })}>
          {children}
        </div>
      </AnimatedContent>
    </AnimatedOverlay>
  );
};

export default Modal;
