import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { authActions } from "reducks/auth";
import { selectLoader } from "reducks/loaders";

// Components
import { AgilityLoader } from "components";

// ROUTES
import { JOBS_PATH } from "routes";

export const LogoutPage = () => {
  const dispatch = useDispatch();
  const logout = useSelector(selectLoader(authActions.logOut));

  // dispatch to logout
  useEffect(() => {
    dispatch(authActions.logOut());
  }, [dispatch]);

  // If we're done loading, let's go to the login.
  if (!logout.loading && logout.loaded) {
    return <Redirect to={JOBS_PATH} />;
  }

  return <AgilityLoader text="Logging out..." />;
};
