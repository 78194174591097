import React from "react";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { AboutIndex } from "pages/about";
import { FAQIndex } from "pages/faq";
import { ForgotPasswordPage } from "pages/forgotPassword";
import { JobsDetailPage } from "pages/jobsDetail";
import { JobsPage } from "pages/jobs";
import { LoginPage } from "pages/login";
import { LogoutPage } from "pages/logout";
import { MarketingIndex } from "pages/marketing";
import { OauthCompletePage } from "pages/login/oauth-complete";
import PageNotFoundPage from "pages/pageNotFound";
import { ProactiveReferralPage } from "pages/proactiveReferral";
import { RecruiterCandidatesPage } from "pages/recruiterCandidates";
import { ReferralDetailPage } from "pages/referralDetail";
import { ReferralsPage } from "pages/referrals";
import { ResetPasswordPage } from "pages/forgotPassword/resetPassword";
import SettingsPage from "pages/settings";
import { SignUpPage } from "pages/signUp";
import { TextOnlyPage } from "pages/marketing/textOnlyPage";

import { PageWithAuth } from "./PageWithAuth";

// Unauthenticated routes
export const MARKETING_INDEX = "/";
export const ABOUT = "/about";
export const FAQ = "/faq";
export const FORGOT_PASSWORD_PATH = "/forgetful";
export const RESET_PASSWORD_PATH = "/reset_password/:token";
export const LOGIN_PATH = "/login";
export const LOGOUT_PATH = "/logout";
export const SIGN_UP_PATH = "/sign-up";
export const OAUTH_COMPLETE_PATH = "/oauth/complete/:service";
export const RECRUITING_AGREEMENT_POLICY = "/recruiting-agreement-policy";
export const PRIVACY_POLICY = "/privacy-policy";

// Marketing routes
export const COPYRIGHT_POLICY_PATH = "/copyright-policy";

// Authenticated routes (any page that includes authenticated features)
export const JOBS_PATH = "/jobs";
export const REFERRALS_PATH = "/referrals";
export const ACTIVE_REFERRALS_PATH = `${REFERRALS_PATH}/active`;
export const COMPLETE_REFERRALS_PATH = `${REFERRALS_PATH}/complete`;
export const REFERRAL_DETAIL_PATH = "/referral/:id";
export const PROACTIVE_REFERRAL_PATH = "/proactive-referral";
export const JOBS_DETAIL_PATH = "/jobs/:id";
export const SHARED_JOB_PATH = "/shared/:sharedUserId/:id";
export const SETTINGS_PATH = "/settings";
export const PAGE_NOT_FOUND_PATH = "/404";
export const BLOG_PATH = "/blog";

// Internal routes
export const CANDIDATES_PATH = "/candidates";

// Route helpers
export const routeAfterLoginKey = "routeAfterLogin";
export const getLoginPath = (routeAfterLogin?: string) =>
  routeAfterLogin !== undefined
    ? `${LOGIN_PATH}?${routeAfterLoginKey}=${routeAfterLogin}`
    : LOGIN_PATH;
export const getForgotPasswordPath = (routeAfterLogin?: string) =>
  routeAfterLogin !== undefined
    ? `${FORGOT_PASSWORD_PATH}?${routeAfterLoginKey}=${routeAfterLogin}`
    : FORGOT_PASSWORD_PATH;

// POSSIBLE TO DO: https://stackoverflow.com/questions/42929472/react-router-v4-default-pagenot-found-page/52261444#52261444

const AgilityRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={LOGIN_PATH}>
          <LoginPage />
        </Route>
        <Route exact path={LOGOUT_PATH}>
          <LogoutPage />
        </Route>
        <Route exact path={SIGN_UP_PATH}>
          <SignUpPage />
        </Route>
        <Route exact path={FORGOT_PASSWORD_PATH}>
          <ForgotPasswordPage />
        </Route>
        <Route exact path={RESET_PASSWORD_PATH}>
          <ResetPasswordPage />
        </Route>
        <Route path={OAUTH_COMPLETE_PATH}>
          <OauthCompletePage />
        </Route>

        {/* Marketing pages */}
        <Route exact path={PRIVACY_POLICY}>
          <TextOnlyPage heading="Privacy Policy" story="privacy-policy" />
        </Route>
        <Route exact path={COPYRIGHT_POLICY_PATH}>
          <TextOnlyPage heading="Copyright Policy" story="copyright-policy" />
        </Route>
        <Route exact path={RECRUITING_AGREEMENT_POLICY}>
          <TextOnlyPage
            heading="Recruiting Agreement Policy"
            story="recruiting-agreement-policy"
          />
        </Route>
        <Route
          path={BLOG_PATH}
          component={() => {
            window.location.href = "https://blog.agilityconnect.io/";
            return null;
          }}
        />

        <Route path={ABOUT}>
          <AboutIndex />
        </Route>
        <Route path={FAQ}>
          <FAQIndex />
        </Route>

        {/* Any page that includes authenticated features should use PageWithAuth */}
        <Route exact path={MARKETING_INDEX}>
          <PageWithAuth>
            <MarketingIndex />
            {/* This page only uses auth to redirect to jobs if logged in */}
          </PageWithAuth>
        </Route>
        <Route exact path={JOBS_PATH}>
          <PageWithAuth>
            <JobsPage />
          </PageWithAuth>
        </Route>
        <Route path={PROACTIVE_REFERRAL_PATH}>
          <PageWithAuth>
            <ProactiveReferralPage />
          </PageWithAuth>
        </Route>
        <Route path={SHARED_JOB_PATH}>
          <PageWithAuth>
            <JobsDetailPage />
          </PageWithAuth>
        </Route>
        <Route path={JOBS_DETAIL_PATH}>
          <PageWithAuth>
            <JobsDetailPage />
          </PageWithAuth>
        </Route>
        <Route path={REFERRALS_PATH}>
          <PageWithAuth>
            <ReferralsPage />
          </PageWithAuth>
        </Route>
        <Route path={CANDIDATES_PATH}>
          <PageWithAuth>
            <RecruiterCandidatesPage />
          </PageWithAuth>
        </Route>
        <Route path={REFERRAL_DETAIL_PATH}>
          <PageWithAuth authRequired>
            <ReferralDetailPage />
          </PageWithAuth>
        </Route>
        <Route exact path={SETTINGS_PATH}>
          <PageWithAuth authRequired>
            <SettingsPage />
          </PageWithAuth>
        </Route>

        {/* 404 */}
        <Route component={PageNotFoundPage} />
      </Switch>
    </Router>
  );
};

export default AgilityRouter;
