import React from "react";

import css from "./BlurredText.module.scss";

interface BlurredTextProps {
  children: string;
}

export const BlurredText = ({ children }: BlurredTextProps) => {
  return (
    <span data-cy="BlurredText" className={css.BlurredText} aria-hidden="true">
      {children}
    </span>
  );
};
