import { call, put, takeLatest } from "redux-saga/effects";
import { doAPIRequest, responseSuccess } from "reducks/sagas";
import { Action } from "types/redux";

import { AppMetaState, metaActions } from "./ducks";

function* fetchMetadata(action: Action) {
  const options = {
    method: "GET",
    path: `/meta/`,
    includeAuth: false,
    useCache: true,
  };

  const { body, status }: { body: AppMetaState; status: number } = yield call(
    doAPIRequest,
    options
  );

  if (!responseSuccess(status)) {
    yield put(metaActions.fetchMetadataFailure("Error fetching metadata"));
    return;
  }

  yield put(metaActions.fetchMetadataSuccess(body));
}

function* watchFetchMetadata() {
  yield takeLatest(`${metaActions.fetchMetadata}`, fetchMetadata);
}

export const metaDataSagas = [watchFetchMetadata()];
