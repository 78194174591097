import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { authActions, forgotPasswordActions } from "reducks/auth";
import { RootState } from "reducks/rootReducer";
import { selectLoader } from "reducks/loaders";
import { validatePassword } from "utilities/forms";

// ROUTES
import { JOBS_PATH, getLoginPath } from "routes";

// Components
import { AuthLayout, Button, Input } from "components";
import css from "./ForgotPassword.module.scss";

import {
  routeAfterLoginLocalStorageKey,
  routeAfterLoginLocalStorageKeySplitChar,
} from ".";

export const ResetPasswordPage = () => {
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();
  const loader = useSelector(selectLoader(forgotPasswordActions.resetPassword));
  const loginLoader = useSelector(selectLoader(authActions.loginWithEmail));

  const [password, setPassword] = useState("");
  const [passwordDoubleTake, setPasswordDoubleTake] = useState("");
  const email = useSelector((state: RootState) => {
    return state.forgotPassword.email;
  });
  const [formErrors, setFormErrors] = useState({
    password: "",
    passwordDoubleTake: "",
  });

  const routeAfterLoginLocalStorageVal = localStorage.getItem(
    routeAfterLoginLocalStorageKey
  );
  const routeAfterLogin = useMemo(() => {
    if (routeAfterLoginLocalStorageVal) {
      const decodedString = atob(routeAfterLoginLocalStorageVal);
      const [emailLocalStorage, ...pathArray] = decodedString.split(
        routeAfterLoginLocalStorageKeySplitChar
      );
      if (email === emailLocalStorage) {
        return pathArray.join();
      }
    }
    return JOBS_PATH;
  }, [email, routeAfterLoginLocalStorageVal]);

  const validate = () => {
    const errors = {
      password: validatePassword(password),
      passwordDoubleTake: validatePassword(passwordDoubleTake),
    };
    if (password !== passwordDoubleTake) {
      errors.passwordDoubleTake = "Password fields must match.";
    }
    setFormErrors(errors);

    // Make sure the length of all the errors equals 0.
    if (Object.values(errors).join("").length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      dispatch(
        forgotPasswordActions.resetPassword({
          password,
          passwordDoubleTake,
          token,
        })
      );
    }
  };

  // If have an email and password, commence logging in.
  useEffect(() => {
    if (email.length > 0 && loader.loaded && !loader.error) {
      dispatch(
        authActions.loginWithEmail({
          email,
          password,
        })
      );
    }
  }, [dispatch, email, password, loader]);

  return (
    <AuthLayout
      formHeading="Reset your password"
      navButtonPath={getLoginPath(routeAfterLogin)}
      navButtonText="Log In"
      navText="Remembered your password?"
      sidebarHeadings={[
        "Make a referral",
        "Impact a life",
        "Boost your income",
      ]}
      sidebarVariant="primary"
    >
      <form onSubmit={handleSubmit}>
        {loader.loaded &&
        !loader.error &&
        loginLoader.loaded &&
        !loginLoader.error ? (
          <Redirect to={routeAfterLogin} />
        ) : (
          <>
            <Input
              data-cy="NewPassword"
              label="New Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={formErrors.password}
              autoCapitalize="off"
              type="password"
              autoComplete="new-password"
              autoCorrect="off"
            />
            <Input
              data-cy="PasswordDoubleTake"
              label="Confirm Password"
              name="password_double_take"
              value={passwordDoubleTake}
              onChange={(e) => setPasswordDoubleTake(e.target.value)}
              error={formErrors.passwordDoubleTake}
              autoCapitalize="off"
              type="password"
              autoComplete="new-password"
              autoCorrect="off"
            />
            <Button
              className={css.ResetPasswordButton}
              data-cy="ResetPasswordButton"
              id="ResetPasswordButton"
              isLoading={loader.loading || loginLoader.loading}
              type="submit"
            >
              Set New Password
            </Button>
          </>
        )}
      </form>
    </AuthLayout>
  );
};
