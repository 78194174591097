import React from "react";
import { Text } from "components";

import css from "./ReferralsListItem.module.scss";

export const RewardInfo = ({
  isPaidOut,
  potentialReward,
  payoutAmount,
  hideLabel,
}: {
  isPaidOut: boolean;
  potentialReward: string;
  payoutAmount: string | null;
  hideLabel?: boolean;
}) => {
  return (
    <div className={css.RewardInfo}>
      {!hideLabel && (
        <Text tag="span" color="light" className={css.RewardInfoLabel}>
          {isPaidOut ? "Reward Paid Out" : "Potential Reward"}
        </Text>
      )}
      <Text
        tag="span"
        color={potentialReward === "0" || hideLabel ? "white" : "green"}
        background={potentialReward === "0" || hideLabel ? "gray" : "green"}
        className={css.RewardAmount}
        data-cy="ReferralListItem-RewardAmount"
      >
        ${isPaidOut ? payoutAmount : potentialReward}
      </Text>
    </div>
  );
};
