import React from "react";
import cn from "classnames";

import { Label } from "components";
import css from "./HookInput.module.scss";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: React.ReactNode; // Error text to display if there's a validation error
  inputClassName?: string; // Additional CSS class for input element
  label?: string;
  name: string;
  register: any;
  required?: any;
}

const HookInput = ({
  className = "",
  error = "",
  inputClassName = "",
  label = "",
  register,
  required,
  name,
  type = "text",
  ...rest
}: InputProps) => (
  <div
    className={cn({
      [css.HookInput]: true,
      [css.Error]: !!error,
      [className]: !!className,
    })}
  >
    {label && <Label htmlFor={name} text={label} />}
    <input
      className={cn({
        [inputClassName]: !!inputClassName,
      })}
      ref={register({ required })}
      id={name}
      name={name}
      type={type}
      {...rest}
    />
    {!!error && <span className={css.ErrorText}>{error}</span>}
  </div>
);

export default HookInput;
