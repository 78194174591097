import React from "react";
import cn from "classnames";

import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import { ACTIVE_REFERRALS_PATH, COMPLETE_REFERRALS_PATH } from "routes";
import {
  selectTotalPaidOut,
  selectTotalPotentialReward,
} from "reducks/referrals";
import { Text } from "components";
import { numberWithCommas } from "utilities/formatting";

import css from "./ReferralsMoney.module.scss";

export interface ReferralsMoneyProps {
  isLoading: boolean;
  className?: string;
}

const ReferralsMoney = ({ isLoading, className }: ReferralsMoneyProps) => {
  const totalPotentialReward = useSelector(selectTotalPotentialReward);
  const totalPaidOut = useSelector(selectTotalPaidOut);
  const wrapperClasses = cn(css.ReferralsMoney, className, {
    [css.isLoading]: isLoading,
  });
  return (
    <div className={wrapperClasses} data-cy="ReferralsMoney">
      <Switch>
        <Route exact path={ACTIVE_REFERRALS_PATH}>
          <Text tag="span" className={css.StandToMakeHeading}>
            You stand to make
          </Text>
          <Text
            tag="span"
            className={css.Amount}
            data-cy="ReferralsMoney-StandToMake"
          >
            ${totalPotentialReward}
          </Text>
        </Route>
        <Route exact path={COMPLETE_REFERRALS_PATH}>
          {totalPaidOut > 0 && (
            <>
              <Text tag="span" className={css.MadeHeading}>
                You&apos;ve made
              </Text>
              <Text
                tag="span"
                className={css.Amount}
                data-cy="ReferralsMoney-Made"
              >
                ${numberWithCommas(totalPaidOut)}
              </Text>
            </>
          )}
        </Route>
      </Switch>
    </div>
  );
};

export default ReferralsMoney;
