import React from "react";
import cn from "classnames";

import { PRIVACY_POLICY, RECRUITING_AGREEMENT_POLICY } from "routes";

import css from "./FormSubmissionPolicy.module.scss";

const FormSubmissionPolicy = ({ className }: { className?: string }) => {
  return (
    <div className={cn(css.FormSubmissionPolicy, className)}>
      By previously submitting a candidate for this position, you agreed to our{" "}
      <a
        href={RECRUITING_AGREEMENT_POLICY}
        target="_blank"
        rel="noopener noreferrer"
      >
        Recruiting Agreement Policy
      </a>
      , and{" "}
      <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      . You have agreed to connect Connect with the above candidate who is
      interested and aware of this specific position. Upon hire of your
      candidate, you will be paid the specified reward (Success Fee) according
      to our Recruiting Agreement policy.
    </div>
  );
};

export default FormSubmissionPolicy;
