import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

import { Options, Placement } from "@popperjs/core";
import { animated, useSpring } from "react-spring";
import Portal from "@reach/portal";
import cn from "classnames";
import { usePopper } from "react-popper";

import { Button } from "components";

import { Link, LinkProps } from "react-router-dom";
import { RegularButton } from "components/Button/Button";
import { useClickOutside } from "utilities/hooks";

import css from "./ButtonDropdown.module.scss";

const ButtonDropdownContext = createContext({ close: () => {} });

interface ButtonDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  placement?: Placement;
  buttonProps?: Partial<RegularButton>;
}

export const DropdownLink = ({ onClick, className, ...rest }: LinkProps) => {
  const { close } = useContext(ButtonDropdownContext);
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }
    close();
  };
  return (
    <Link
      onClick={handleClick}
      className={cn(css.DropdownItem, className)}
      {...rest}
    />
  );
};

const PopperMenu = ({
  referenceRef,
  placement,
  children,
  isClosing,
  onClose,
}: {
  referenceRef: React.MutableRefObject<null>;
  placement: Placement;
  children: React.ReactNode;
  isClosing: boolean;
  onClose: () => void;
}) => {
  const [popperRef, setPopperRef] = useState(null);

  const popperCallbackRef = useCallback((node) => {
    if (node !== null) {
      setPopperRef(node);
    }
  }, []);

  const popperOptions: Partial<Options> = {
    placement,
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 5],
        },
      },
    ],
  };

  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef,
    popperOptions
  );

  const handleSpringRest = () => {
    if (isClosing) {
      onClose();
    }
  };

  const springprops = useSpring({
    from: { opacity: 0 },
    opacity: isClosing ? 0 : 1,
    config: {
      duration: 100,
    },
    onRest: handleSpringRest,
  });

  return (
    <Portal>
      <animated.div
        ref={popperCallbackRef}
        className={css.DropdownMenu}
        style={{ ...styles.popper, opacity: springprops.opacity }}
        {...attributes.popper}
      >
        {children}
      </animated.div>
    </Portal>
  );
};

const ButtonDropdown = ({
  placement = "bottom-start",
  buttonProps,
  children,
  className,
  ...rest
}: ButtonDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const wrapperRef = useRef(null);
  const referenceRef = useRef(null);

  const handleClickOutside = () => {
    if (isOpen) {
      setIsClosing(true);
    }
  };
  useClickOutside(wrapperRef, handleClickOutside);

  const extraBtnClasses = buttonProps?.className || "";

  const handleClose = () => {
    setIsOpen(false);
    setIsClosing(false);
  };

  return (
    <ButtonDropdownContext.Provider value={{ close: () => setIsClosing(true) }}>
      <div className={cn(css.Wrapper, className)} ref={wrapperRef} {...rest}>
        <Button
          variant="secondary"
          size="small"
          {...buttonProps}
          className={cn(css.DropdownBtn, extraBtnClasses)}
          onClick={() => {
            if (isOpen) {
              setIsClosing(true);
            } else {
              setIsOpen(true);
            }
          }}
          ref={referenceRef}
        />
        {isOpen && (
          <PopperMenu
            placement={placement}
            referenceRef={referenceRef}
            isClosing={isClosing}
            onClose={handleClose}
          >
            {children}
          </PopperMenu>
        )}
      </div>
    </ButtonDropdownContext.Provider>
  );
};

ButtonDropdown.Link = DropdownLink;

export default ButtonDropdown;
