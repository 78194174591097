import React from "react";

import { Heading, ReferralsListItem, Text } from "components";

import { Link, useLocation } from "react-router-dom";
import { SIGN_UP_PATH, getLoginPath } from "routes";
import css from "./ReferralsUnauthenticated.module.scss";

const data = {
  id: 75,
  is_active: false,
  first_name: "Jane",
  last_name: "Doe",
  email: "jane.doe@agilityconnect.io",
  phone: "5551234567",
  date_created: "2020-07-13T00:37:40.000013Z",
  last_updated: "2020-08-23T00:10:21.099217Z",
  referral_set: [
    {
      id: 75,
      status: 20,
      last_updated: "2018-03-20T12:57:02.683499Z",
      job: {
        name: "Front End Developer",
        reward: 2100,
        job_type: 3,
        company: {
          name: "Nationwide Insurance",
          industry: 1,
        },
      },
      activity_set: [
        {
          status_from: 0,
          status_to: 5,
          datetime_created: "2018-03-07T16:41:29.379875Z",
        },

        {
          status_from: 5,
          status_to: 7,
          datetime_created: "2018-03-20T12:57:02.032591Z",
        },
      ],
    },
    {
      id: 76,
      status: 10,
      last_updated: "2018-03-20T12:57:02.683499Z",
      job: {
        name: "Front End Developer",
        reward: 2800,
        job_type: 3,
        company: {
          name: "Express",
          industry: 2,
        },
      },
      activity_set: [
        {
          status_from: 0,
          status_to: 5,
          datetime_created: "2018-03-07T16:41:29.379875Z",
        },

        {
          status_from: 5,
          status_to: 7,
          datetime_created: "2018-03-20T12:57:02.032591Z",
        },
      ],
    },
  ],
  skillset: null,
  is_applicant: false,
};

const ReferralsUnauthenticated = () => {
  const { pathname } = useLocation();
  return (
    <div
      className={css.ReferralsUnauthenticated}
      data-cy="ReferralsUnauthenticated"
    >
      <Heading variant="h1" className={css.heading}>
        Refer a pro and get paid.
      </Heading>
      <Text className={css.text}>
        Ever been asked to provide a referral? AgilityConnect rewards you as if
        you recruited them yourself! With a single click, make a referral, track
        their progress and cash in on your reward.
      </Text>
      <div className={css.links}>
        <Link to={SIGN_UP_PATH} className={css.signUp}>
          Sign Up
        </Link>
        <Link to={getLoginPath(pathname)} className={css.logIn}>
          Log In
        </Link>
      </div>
      <div className={css.demoReferral}>
        <ReferralsListItem demoMode {...data} />
      </div>
    </div>
  );
};

export default ReferralsUnauthenticated;
