import React, { Fragment, forwardRef, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";

import { DEFAULT_LIMIT, jobsActions, selectJobs } from "reducks/jobs";
import {
  JobsListHeader,
  JobsListItem,
  JobsListItemLoading,
  JobsListMessage,
  ProactiveReferralLink,
} from "components";
import {
  selectAppliedCityFilters,
  selectAppliedIndustryFilters,
  selectAppliedJobTypeFilters,
  selectAppliedPositionTypeFilters,
  selectJobsFiltersInitialized,
  selectQuery,
} from "reducks/jobsFilters";
import { ReactComponent as ErrorWarningIcon } from "images/error-warning.svg";
import { ReactComponent as NoResultsIcon } from "images/no-results.svg";
import { selectLoader } from "reducks/loaders";
// eslint-disable-next-line sort-imports
import { selectAppSettings, selectJobTypes } from "reducks/meta";
import { useClearAllFilters } from "components/JobsFilterPanel/actions";

import { events, track } from "utilities/analytics";
import { scrollToTop } from "utilities/scroll";
import { selectIsUserLoggedIn } from "reducks/user";

import css from "./JobsList.module.scss";

const JobsList = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const appSettings = useSelector(selectAppSettings);
  const isLoggedIn = useSelector(selectIsUserLoggedIn);

  // Get the jobs from redux
  const { results: jobs, limit, offset, count } = useSelector(selectJobs);
  const totalPages = useMemo(() => Math.ceil(count / limit), [count, limit]);
  const currentPage = useMemo(() => Math.ceil((offset + limit) / limit), [
    offset,
    limit,
  ]);

  // Get the jobs loading state from redux
  const jobsLoader = useSelector(selectLoader(jobsActions.fetchJobs));
  const isLoading = useMemo(() => {
    return jobsLoader.loading || (!jobsLoader.loaded && !jobsLoader.loading);
  }, [jobsLoader]);

  const error = useMemo(() => {
    return !jobsLoader.loading && jobsLoader.error;
  }, [jobsLoader]);

  // Get and cache the "Job Types" meta property for the app
  const jobTypes = useSelector(selectJobTypes);
  const jobTypesResults = useMemo(() => {
    return jobTypes.options;
  }, [jobTypes]);

  // Get the number of elements we should be displaying. We use this to
  // populate the loading state.
  const numberOfListItems = Array.from("x".repeat(limit + 1));

  const appliedJobsFilters = useSelector(selectAppliedJobTypeFilters);
  const appliedIndustryFilters = useSelector(selectAppliedIndustryFilters);
  const appliedPositionTypeFilters = useSelector(
    selectAppliedPositionTypeFilters
  );
  const appliedCityFilters = useSelector(selectAppliedCityFilters);
  const query = useSelector(selectQuery);
  const filtersInitialized = useSelector(selectJobsFiltersInitialized);

  // scroll to top on page load
  useEffect(() => {
    scrollToTop();
  }, []);

  // Analytics
  useEffect(() => {
    if (!isLoading && !error) {
      track(events.VIEWED_JOBS, {
        job_type: JSON.stringify(appliedJobsFilters),
        industry: JSON.stringify(appliedIndustryFilters),
        position_type: JSON.stringify(appliedPositionTypeFilters),
        city: JSON.stringify(appliedCityFilters),
        query,
      });
    }
  }, [isLoading, error]);

  // Load the jobs
  useEffect(() => {
    if (filtersInitialized) {
      dispatch(
        jobsActions.fetchJobs({
          includeAuth: isLoggedIn,
          limit: DEFAULT_LIMIT,
          offset: 0,
          job_type: appliedJobsFilters,
          industry: appliedIndustryFilters,
          position_type: appliedPositionTypeFilters,
          city: appliedCityFilters,
          query,
          append: false,
        })
      );
    }
  }, [
    dispatch,
    isLoggedIn,
    appliedJobsFilters,
    appliedIndustryFilters,
    appliedPositionTypeFilters,
    appliedCityFilters,
    filtersInitialized,
    query,
  ]);

  const handleClearFilters = useClearAllFilters();

  const handleInfiniteScroll = () => {
    dispatch(
      jobsActions.fetchMoreJobs({
        includeAuth: isLoggedIn,
        limit,
        offset: offset + limit,
        job_type: appliedJobsFilters,
        industry: appliedIndustryFilters,
        position_type: appliedPositionTypeFilters,
        city: appliedCityFilters,
        query,
        append: true,
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>Agility Connect</title>
      </Helmet>
      <JobsListHeader scrollRef={ref} count={count} />
      <div className={css.JobsListContainer} data-cy="JobsList">
        {(isLoading || jobs.length) && !error ? (
          <>
            {isLoading ? (
              numberOfListItems.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <JobsListItemLoading key={`jobs-list-item${i}`} />
              ))
            ) : (
              <InfiniteScroll
                dataLength={jobs.length}
                next={handleInfiniteScroll}
                hasMore={currentPage < totalPages}
                loader={<JobsListItemLoading />}
              >
                {jobs.map((job, i) => {
                  return (
                    <Fragment key={`jobs-list-job-${job.id}`}>
                      {jobs.length >= limit &&
                        i === Math.ceil(limit / 2) &&
                        appSettings.isVendorSite === false && (
                          <ProactiveReferralLink />
                        )}
                      <JobsListItem
                        {...job}
                        jobType={
                          jobTypesResults.filter(
                            (j) => j.id === job.job_type
                          )[0].name
                        }
                        numPositionsOpen={job.num_positions_open}
                        positionType={job.position_type}
                        postedDate={job.posted_date}
                      />
                      {jobs.length < limit &&
                        i === jobs.length - 1 &&
                        appSettings.isVendorSite === false && (
                          <ProactiveReferralLink />
                        )}
                    </Fragment>
                  );
                })}
              </InfiniteScroll>
            )}
          </>
        ) : null}
        {error && (
          <JobsListMessage
            heading="Uh oh... something went wrong"
            subheading={error || ""}
            icon={<ErrorWarningIcon />}
            data-cy="jobs-list-error-message"
          />
        )}
        {!isLoading && !error && !jobs.length && (
          <JobsListMessage
            onButtonClick={handleClearFilters}
            buttonText="Clear Filters"
            heading="Your search returned no results."
            subheading="Try clearing your filters for more results."
            icon={<NoResultsIcon />}
            data-cy="jobs-list-empty-message"
          />
        )}
      </div>
    </>
  );
});

export default JobsList;
