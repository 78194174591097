import React from "react";
import cn from "classnames";

import { ReactComponent as Squiggly } from "images/timeline_connector.svg";
import css from "./HowItWorksTimelineItem.module.scss";

export interface HowItWorksTimelineItemProps {
  alignment?: string;
  description: string;
  image?: string;
}

const HowItWorksTimelineItem = ({
  alignment = "left",
  description,
  image,
}: HowItWorksTimelineItemProps) => {
  return (
    <div
      className={`${css.HowItWorksTimelineItem} ${css[`align-${alignment}`]}`}
    >
      <div className={css.words}>
        <p className={css.description}>{description}</p>
      </div>
      <div className={css.timelinePoint}>
        <span className={css.pointName} />
        <Squiggly />
      </div>
      <div className={css.image}>
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    </div>
  );
};

export default HowItWorksTimelineItem;
