import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { forgotPasswordActions } from "reducks/auth";
import { selectLoader } from "reducks/loaders";

// Components
import { AuthLayout, Button, Input } from "components";

// ROUTES
import { getLoginPath, routeAfterLoginKey } from "routes";
import { useQuery } from "utilities/hooks";
import { validateEmail } from "utilities/forms";

import css from "./ForgotPassword.module.scss";

export const routeAfterLoginLocalStorageKey = "resetPasswordRouteAfterLogin";
export const routeAfterLoginLocalStorageKeySplitChar = "|";

export const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const loader = useSelector(
    selectLoader(forgotPasswordActions.sendForgotPasswordEmail)
  );
  const query = useQuery();
  const routeAfterLogin = query.get(routeAfterLoginKey) || undefined;

  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({
    email: "",
  });

  const validate = () => {
    const errors = {
      email: validateEmail(email),
    };
    setFormErrors(errors);

    // Make sure the length of all the errors equals 0.
    if (Object.values(errors).join("").length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      dispatch(forgotPasswordActions.sendForgotPasswordEmail(email));
    }
  };

  const loaderError: string = loader.error
    ? "No user with that email exists."
    : "";

  useEffect(() => {
    if (routeAfterLogin && loader.loaded && !loader.error) {
      localStorage.setItem(
        routeAfterLoginLocalStorageKey,
        btoa(
          `${email}${routeAfterLoginLocalStorageKeySplitChar}${routeAfterLogin}`
        )
      );
    }
  }, [email, routeAfterLogin, loader.loaded, loader.error]);

  return (
    <AuthLayout
      formHeading="Forgot your password?"
      navButtonPath={getLoginPath(routeAfterLogin)}
      navButtonText="Log In"
      navText="Remembered your password?"
      sidebarHeadings={[
        "Make a referral",
        "Impact a life",
        "Boost your income",
      ]}
      sidebarVariant="primary"
    >
      <form onSubmit={handleSubmit}>
        {loader.loaded && !loader.error ? (
          <p data-cy="ForgotPasswordSuccess">
            We&apos;ve sent an email to <b>{email}</b> containing a link to
            reset your password.
          </p>
        ) : (
          <>
            <p>
              Enter your email below, and we&apos;ll send you link to reset your
              password.
            </p>
            <Input
              data-cy="EmailInput"
              label="Email Address"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={formErrors.email || loaderError}
              autoCapitalize="off"
              autoComplete="email"
              autoCorrect="off"
            />
            <Button
              className={css.ForgotPasswordButton}
              data-cy="ForgotPasswordButton"
              id="ForgotPasswordButton"
              isLoading={loader.loading}
              type="submit"
            >
              Reset Password
            </Button>
          </>
        )}
      </form>
    </AuthLayout>
  );
};
