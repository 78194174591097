import { StrictEffect, call, put, takeEvery } from "redux-saga/effects";
import { doAPIRequest, responseSuccess } from "reducks/sagas";
import { Action } from "types/redux";
import { PayloadAction } from "@reduxjs/toolkit";

import { getUid, identify, setProperties } from "utilities/analytics";

import {
  SENT_BY_USER_KEY,
  UpdateUserDetailsPayload,
  UpdateUserOnboardingPayload,
  UserResponse,
  UserState,
  userActions,
} from "./ducks";

interface UpdateUserResponse extends UserResponse {
  currentPassword: string;
}

const deserializeUser = (response: UserResponse): UserState => {
  return {
    id: response.id || null,
    email: response.email || "",
    phoneNumber: response.phone_number || "",
    firstName: response.first_name || "",
    lastName: response.last_name || "",
    isEmailOn: response.is_email_on,
    isOnboardingDone: response.is_onboarding_done,
    publicId: response.public_id,
  };
};

function* onFetchUser(
  action: Action<string>
): Generator<
  StrictEffect,
  { body: UserResponse; status: number } | void,
  { body: UserResponse; status: number }
> {
  const { payload } = action;

  const options = {
    method: "GET",
    path: `/accounts/${payload}/`,
  };

  const { body, status }: { body: UserResponse; status: number } = yield call(
    doAPIRequest,
    options
  );

  if (!responseSuccess(status)) {
    yield put(userActions.fetchUserFailure("Error fetching user"));
  }

  const deserializedUser = deserializeUser(body);
  yield put(userActions.fetchUserSuccess(deserializedUser));

  if (deserializedUser) {
    // Track in posthog
    const uid = getUid({
      email: deserializedUser.email,
    });
    yield call(identify, uid);
    yield call(setProperties, {
      email: deserializedUser.email,
      firstName: deserializedUser.firstName,
      lastName: deserializedUser.lastName,
      phoneNumber: deserializedUser.phoneNumber,
    });
  }
}

function* onUpdateUserDetails(
  action: Action<UpdateUserDetailsPayload | UpdateUserOnboardingPayload>
) {
  const { payload } = action;

  const requestBody =
    "email" in payload
      ? { ...payload, email: payload.email.toLowerCase() }
      : payload;

  const options = {
    method: "PUT",
    path: `/accounts/${payload.id}/`,
    body: requestBody,
  };

  const {
    body,
    status,
  }: { body: UpdateUserResponse; status: number } = yield call(
    doAPIRequest,
    options
  );

  if (!responseSuccess(status)) {
    if (body.currentPassword) {
      yield put(
        userActions.updateUserFailure("Your current password is incorrect")
      );
      return;
    }

    yield put(userActions.updateUserFailure("Error updating user"));
    return;
  }

  const deserializedUser = deserializeUser(body);
  yield put(userActions.updateUserSuccess(deserializedUser));
}

function* onSetSentByUser(action: PayloadAction<string>): any {
  const { payload } = action;

  yield call(
    { context: localStorage, fn: localStorage.setItem },
    SENT_BY_USER_KEY,
    payload
  );
}

function* watchFetchUser() {
  yield takeEvery(`${userActions.fetchUser}`, onFetchUser);
}

function* watchUpdateUserDetails() {
  yield takeEvery(`${userActions.updateUserDetails}`, onUpdateUserDetails);
}

function* watchOnSetSentByUser() {
  yield takeEvery(`${userActions.setSentByUser}`, onSetSentByUser);
}

export const userSagas = [
  watchFetchUser(),
  watchUpdateUserDetails(),
  watchOnSetSentByUser(),
];
