import React, { useMemo } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import {
  Nav,
  ReferralsFilter,
  ReferralsList,
  ReferralsMoney,
  ReferralsProcessOverview,
  ReferralsUnauthenticated,
} from "components";
import { referralsActions } from "reducks/referrals";
import { selectIsUserLoggedIn } from "reducks/user";
import { selectLoader } from "reducks/loaders";

import css from "./ReferralsPage.module.scss";

export const ReferralsPage = () => {
  // Get the referrals from redux
  const referralsLoader = useSelector(
    selectLoader(referralsActions.fetchReferrals)
  );
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  // setup some loader variables
  const isLoading = useMemo(() => {
    return (
      referralsLoader.loading ||
      (isUserLoggedIn && !referralsLoader.loaded && !referralsLoader.loading)
    );
  }, [referralsLoader, isUserLoggedIn]);

  const error = useMemo(() => {
    if (!referralsLoader.loading) {
      return referralsLoader.error;
    }
  }, [referralsLoader]);

  return (
    <div>
      <Nav subNavSlot={isUserLoggedIn ? <ReferralsFilter /> : null} />
      <div className={css.Content}>
        {isUserLoggedIn ? (
          <>
            <ReferralsList
              isLoading={isLoading}
              error={error}
              className={css.ReferralList}
            />
            <ReferralsMoney isLoading={isLoading} className={css.Money} />
            <ReferralsProcessOverview
              className={cn(
                css.ProcessOverview,
                !isUserLoggedIn && css.ProcessOverviewLoggedOut
              )}
            />
          </>
        ) : (
          <ReferralsUnauthenticated />
        )}
      </div>
    </div>
  );
};
