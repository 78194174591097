import React, { useMemo } from "react";
import cn from "classnames";
import { format } from "date-fns";

import { Heading, Text } from "components";
import {
  Referral,
  completedNotHiredStatuses,
  dateFormat,
  didntWorkOutStatus,
  hiredStatuses,
  referralStateMap,
} from "reducks/referrals";
import { ReactComponent as BriefcaseSvg } from "images/briefcase.svg";
import { numberWithCommas } from "utilities/formatting";

import { RewardInfo } from "./RewardInfo";

import css from "./ReferralsListItem.module.scss";

const WorkflowStep = ({
  status,
  referral,
  otherReferralHired,
}: {
  status: number;
  referral: Referral;
  otherReferralHired: boolean;
}) => {
  const title = referralStateMap[status];

  // Copy and reverse activity set to make index 0 the most recent
  const reversedActivitySet = referral.activity_set.slice().reverse();

  const latestActivity =
    reversedActivitySet.length > 0 && reversedActivitySet[0];

  // Find the most recent activity item with this step's status (if one exists)
  const matchingActivityItem = useMemo(() => {
    return reversedActivitySet.find((a) => a.status_to === status);
  }, [reversedActivitySet, status]);

  // Find the furthest status reached that isn't "Didn't Work Out" -- that one gets treated differently
  const furthestNonDWOStatusReached = useMemo(() => {
    if (!!latestActivity && latestActivity.status_to !== didntWorkOutStatus) {
      return latestActivity.status_to;
    }
    let furthestStatus = latestActivity ? latestActivity.status_to : 5;
    reversedActivitySet.forEach((a) => {
      if (a.status_to !== didntWorkOutStatus && a.status_to > furthestStatus) {
        furthestStatus = a.status_to;
      }
    });
    return furthestStatus;
  }, [reversedActivitySet, latestActivity]);

  // We won't have a status if the `status` prop isn't an index of `referralStateMap`.
  // This is just here as a failsafe -- this shouldn't ever happen
  if (!title) {
    return null;
  }

  // Set `isCompleted` if this status is before the furthest-along status that isn't "Didn't Work Out"
  const isCompleted = status <= furthestNonDWOStatusReached;

  // Set `isLatest` if this is the most recent activity
  const isLatest = !!latestActivity && status === latestActivity.status_to;

  // Set `isStruckThrough` if:
  // - the most recent activity is "Didn't Work Out" OR this candidate was hired for a different job
  // - AND the status of this step is after the furthest-along status that isn't "Didn't Work Out"
  let isStruckThrough = false;
  if (
    !!latestActivity &&
    (latestActivity.status_to === didntWorkOutStatus || otherReferralHired)
  ) {
    isStruckThrough = status > furthestNonDWOStatusReached;
  }

  // Construct the data-cy based on the state of the step
  const cy = `ReferralDetail_StepName${isCompleted ? "-isCompleted" : ""}${
    isLatest ? "-isLatest" : ""
  }${isStruckThrough ? "-isStruckThrough" : ""}`;

  return (
    <div
      className={cn(css.ReferralWorkflowStep, {
        [css.isCompletedStep]: isCompleted,
        [css.isLatestStep]: isLatest,
        [css.isStruckThrough]: isStruckThrough,
      })}
    >
      <div className={css.ReferralWorkflowStepNode} />
      <Text
        tag="span"
        size="small"
        color={isLatest ? "default" : "light"}
        weight={isLatest ? 600 : undefined}
        className={css.ReferralWorkflowStepName}
        data-cy={cy}
      >
        {title}
      </Text>
      {isCompleted && !!matchingActivityItem && (
        <Text
          tag="span"
          size="small"
          color="light"
          className={css.ReferralWorkflowStepDate}
        >
          {format(new Date(matchingActivityItem.datetime_created), dateFormat)}
        </Text>
      )}
    </div>
  );
};

export const ReferralsDetail = ({
  referral_set,
  hiredReferral,
}: {
  referral_set: Referral[];
  hiredReferral?: Referral;
}) => {
  return (
    <div className={css.ReferralsDetailWrapper}>
      <Heading variant="h2" align="left">
        Referrals
      </Heading>
      {referral_set.map((r) => {
        const potentialReward = numberWithCommas(r.job.reward);

        const otherReferralHired =
          !!hiredReferral && !hiredStatuses.includes(r.status);
        const isCompletedNotHired =
          completedNotHiredStatuses.includes(r.status) || otherReferralHired;
        const isPaidOut = r.status === 35;

        const latestActivity =
          r.activity_set.length > 0 &&
          r.activity_set[r.activity_set.length - 1];

        return (
          <div
            className={css.ReferralsDetailItem}
            key={r.id}
            data-cy="ReferralDetail-Referral"
          >
            <div className={css.ReferralsDetailItemTopSection}>
              <div className={css.ReferralsDetailItemText}>
                <div className={css.ReferralDetailItemHeadingWrapper}>
                  <BriefcaseSvg className={css.BriefcaseSvg} />
                  <Heading
                    variant="h3"
                    align="left"
                    className={css.ReferralsDetailHeading}
                  >
                    <Text tag="span" className={css.JobName} size="large">
                      {r.job.name}
                    </Text>
                    <Text tag="span" size="large" color="light">
                      &nbsp;at&nbsp;
                    </Text>
                    <Text tag="span" className={css.CompanyName} size="large">
                      {r.job.company.name}
                    </Text>
                  </Heading>
                </div>
                <div className={css.ReferralsDetailItemReferredOn}>
                  <Text tag="span" color="light">
                    Last updated
                  </Text>
                  <Text tag="span" size="large" className={css.Date}>
                    {format(new Date(r.last_updated), dateFormat)}
                  </Text>
                </div>
              </div>
              <RewardInfo
                isPaidOut={isPaidOut}
                potentialReward={potentialReward}
                payoutAmount={isPaidOut ? potentialReward : null}
                hideLabel={isCompletedNotHired}
              />
            </div>
            <div className={css.ReferralWorkflowWrapper}>
              <WorkflowStep
                status={5}
                referral={r}
                otherReferralHired={otherReferralHired}
              />
              <WorkflowStep
                status={10}
                referral={r}
                otherReferralHired={otherReferralHired}
              />
              <WorkflowStep
                status={15}
                referral={r}
                otherReferralHired={otherReferralHired}
              />
              <WorkflowStep
                status={20}
                referral={r}
                otherReferralHired={otherReferralHired}
              />
              <WorkflowStep
                status={28}
                referral={r}
                otherReferralHired={otherReferralHired}
              />
              <WorkflowStep
                status={30}
                referral={r}
                otherReferralHired={otherReferralHired}
              />
              {latestActivity &&
              latestActivity.status_to === didntWorkOutStatus ? (
                <WorkflowStep
                  status={didntWorkOutStatus}
                  referral={r}
                  otherReferralHired={otherReferralHired}
                />
              ) : (
                <WorkflowStep
                  status={35}
                  referral={r}
                  otherReferralHired={otherReferralHired}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
