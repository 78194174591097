import React from "react";
import cn from "classnames";

import css from "./Label.module.scss";

interface LabelProps {
  htmlFor: string;
  text: string;
  className?: string;
}

const Label = ({ text, htmlFor, className = "" }: LabelProps) => (
  <label
    htmlFor={htmlFor}
    className={cn({
      [css.Label]: true,
      [className]: !!className,
    })}
  >
    {text}
  </label>
);

export default Label;
