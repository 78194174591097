import React from "react";

import {
  ABOUT,
  CANDIDATES_PATH,
  FAQ,
  JOBS_PATH,
  LOGOUT_PATH,
  REFERRALS_PATH,
  SETTINGS_PATH,
  SIGN_UP_PATH,
  getLoginPath,
} from "routes";
import {
  Link,
  NavLink as ReactRouterNavLink,
  useLocation,
} from "react-router-dom";
import cn from "classnames";
import { useSelector } from "react-redux";

// Assets
import { ReactComponent as APLogo } from "images/ap-logo.svg";
import { ReactComponent as Logo } from "images/ac-logo-color.svg";
import { ReactComponent as NavJobsSvg } from "images/nav-jobs.svg";
import { ReactComponent as NavReferralsSvg } from "images/nav-referrals.svg";
import { ReactComponent as UserSvg } from "images/user.svg";

// Components
import { ButtonDropdown } from "components";

// Reducks
import { selectAccessToken } from "reducks/auth";
import { selectAppSettings } from "reducks/meta";
import { selectUser } from "reducks/user";
import { useWindowSize } from "utilities/hooks";

// Styles
import css from "./Nav.module.scss";

interface NavLinkProps {
  label: string;
  path: string;
  exact?: boolean;
}

const NavLink = ({ label, path, exact = false }: NavLinkProps) => (
  <ReactRouterNavLink
    to={path}
    activeClassName={css.isActive}
    className={css.NavLink}
    exact={exact}
  >
    {label}
  </ReactRouterNavLink>
);

const AuthenticatedButtonChildren = () => (
  <div className={css.AuthAccountBtnChildren}>
    <UserSvg className={css.AuthAccountIcon} />
    <span className={css.AuthAccountBtnChildrenText}>Account</span>
  </div>
);

const UnauthenticatedButtonChildren = () => (
  <div className={css.UnauthAccountBtnChildren}>
    <UserSvg className={css.UnauthAccountIcon} />
    <span className={css.UnauthAccountBtnChildrenText}>Menu</span>
  </div>
);

const Nav = ({
  subNavSlot,
  is404,
}: {
  subNavSlot?: React.ReactNode;
  is404?: boolean;
}) => {
  const appSettings = useSelector(selectAppSettings);
  const accessToken = useSelector(selectAccessToken);
  const user = useSelector(selectUser);
  const { pathname } = useLocation();
  const { isBigMobile, isTablet } = useWindowSize();

  // If the page doesn't exist, we don't want to reroute them
  // there again after logging in
  const loginLink = getLoginPath(is404 ? undefined : pathname);
  const internalEmails = ["@agilitypartners.io"];
  const isInternalUser =
    user.email && internalEmails.some((email) => user.email.endsWith(email));

  return (
    <>
      <div className={css.Wrapper}>
        <div className={css.Inner}>
          <Link to="/" className={css.AgilityLogoLink}>
            {appSettings.isVendorSite ? (
              <APLogo className={css.AgilityLogo} />
            ) : (
              <Logo className={css.AgilityLogo} />
            )}
          </Link>
          <div
            className={cn(
              css.NavLinks,
              is404 && css.NavLinks404,
              accessToken && css.NavLinksCenter
            )}
            data-cy="nav-links"
          >
            <NavLink label="Jobs" path={JOBS_PATH} exact />
            {!appSettings.isVendorSite && (
              <>
                <NavLink
                  label={isInternalUser ? "Candidates" : "Referrals"}
                  path={isInternalUser ? CANDIDATES_PATH : REFERRALS_PATH}
                />
                {isTablet && (
                  <>
                    <NavLink path="/about" label="About" />
                    <NavLink path="/faq" label="FAQ" />
                  </>
                )}
              </>
            )}
          </div>
          {accessToken ? (
            <div data-cy="nav-account-button" className={css.AccountBtnWrapper}>
              <ButtonDropdown
                placement="bottom-end"
                buttonProps={{
                  children: <AuthenticatedButtonChildren />,
                  className: css.AccountBtn,
                }}
              >
                <ButtonDropdown.Link to={SETTINGS_PATH}>
                  Settings
                </ButtonDropdown.Link>
                <ButtonDropdown.Link to={LOGOUT_PATH}>
                  Sign Out
                </ButtonDropdown.Link>
              </ButtonDropdown>
            </div>
          ) : (
            <>
              {isBigMobile ? (
                <div
                  className={css.LoginSignupWrapper}
                  data-cy="Nav-LoginSignupWrapper"
                >
                  <Link to={SIGN_UP_PATH} className={css.SignUpLink}>
                    Join Now
                  </Link>
                  <Link to={loginLink} className={css.LogInLink}>
                    Sign In
                  </Link>
                </div>
              ) : (
                <ButtonDropdown
                  data-cy="Nav-LoginSignupButtonDropdown"
                  placement="bottom-end"
                  className={css.LoginSignupButtonDropdown}
                  buttonProps={{
                    className: css.LoginSignupButton,
                    children: <UnauthenticatedButtonChildren />,
                  }}
                >
                  <div data-cy="Nav-LoginSignupDropdownMenu">
                    <ButtonDropdown.Link to={ABOUT}>
                      About Us
                    </ButtonDropdown.Link>
                    <ButtonDropdown.Link to={FAQ}>FAQ</ButtonDropdown.Link>
                    <ButtonDropdown.Link to={SIGN_UP_PATH}>
                      Join Now
                    </ButtonDropdown.Link>
                    <ButtonDropdown.Link to={loginLink}>
                      Sign In
                    </ButtonDropdown.Link>
                  </div>
                </ButtonDropdown>
              )}
            </>
          )}
        </div>
      </div>
      {subNavSlot && (
        <div className={css.SubNavWrapper}>
          <div className={css.SubNavInner}>{subNavSlot}</div>
        </div>
      )}
    </>
  );
};

export default Nav;
