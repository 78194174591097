import { useHistory } from "react-router-dom";

import { useQuery } from "utilities/hooks";

export const useClearAllFilters = () => {
  const history = useHistory();
  const urlParams = useQuery();

  return () => {
    const searchTerm = urlParams.get("query");
    history.push({
      search: searchTerm
        ? `query=${encodeURIComponent(searchTerm)}`
        : undefined,
    });
  };
};
