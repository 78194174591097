import React from "react";
import StoryblokClient from "storyblok-js-client";

export class StoryblokService {
  devMode: boolean;

  token?: string;

  client: StoryblokClient;

  query: {
    [key: string]: any;
  };

  constructor() {
    this.devMode = false; // Always loads draft
    this.token = process.env.REACT_APP_STORYBLOK_TOKEN;
    this.client = new StoryblokClient({
      accessToken: this.token,
      cache: {
        clear: "auto",
        type: "memory",
      },
    });

    this.query = {};
  }

  getCacheVersion() {
    return this.client.cacheVersion;
  }

  async get(slug: string, params?: { version?: any; cv?: any }) {
    const newParams = params || {};

    if (
      this.getQuery("_storyblok") ||
      this.devMode ||
      (typeof window !== "undefined" && window.storyblok)
    ) {
      newParams.version = "draft";
    }

    if (
      typeof window !== "undefined" &&
      typeof window.StoryblokCacheVersion !== "undefined"
    ) {
      newParams.cv = window.StoryblokCacheVersion;
    }

    const data = await this.client.get(`cdn/stories/${slug}`, newParams);

    return data.data.story.content.body;
  }

  static getStory(componentName: string, stories: Array<any>) {
    return stories
      .filter((story: any) => story.component === componentName)
      .map((story: any) => story.content)[0];
  }

  static getStories(componentName: string, stories: Array<any>) {
    return stories.filter((story: any) => story.component === componentName);
  }

  // initEditor(reactComponent) {
  //   if (window.storyblok) {
  //     window.storyblok.init();
  //     window.storyblok.on(["change", "published"], () => location.reload(true));

  //     // this will alter the state and replaces the current story with a current raw story object (no resolved relations or links)
  //     window.storyblok.on("input", (event) => {
  //       if (
  //         event.story.content._uid === reactComponent.state.pageContent._uid
  //       ) {
  //         reactComponent.setState({
  //           pageContent: window.storyblok.addComments(
  //             event.story.content,
  //             event.story.id
  //           ),
  //         });
  //       }
  //     });
  //   }
  // }

  setQuery(query: { [key: string]: any }) {
    this.query = query;
  }

  getQuery(param: string) {
    return this.query[param];
  }

  bridge() {
    if (!this.getQuery("_storyblok") && !this.devMode) {
      return "";
    }
    return (
      <script
        src={`//app.storyblok.com/f/storyblok-latest.js?t=${this.token}`}
      />
    );
  }

  renderRichText(data: any) {
    return this.client.richTextResolver.render(data);
  }
}

const storyblokInstance = new StoryblokService();

export default storyblokInstance;
