import posthog from "posthog-js";

let isInitialized = false;
const apiKey = process.env.REACT_APP_POSTHOG_KEY;

export const events = {
  CREATED_ACCOUNT: "CREATED_ACCOUNT",

  // Jobs
  VISITED_JOB: "VISITED_JOB",
  VIEWED_JOBS: "VIEWED_JOBS",
  VIEWED_MORE_JOBS: "VIEWED_MORE_JOBS",

  // Referrals
  SUBMITTED_REFERRAL: "SUBMITTED_REFERRAL",
  APPLIED: "APPLIED",

  // Auth
  LOGGED_IN: "LOGGED_IN",
  LOGGED_OUT: "LOGGED_OUT",

  // Experiments
  VIEWED_EXPERIMENT: "VIEWED_EXPERIMENT",
};

export type TrackingOptions = {
  email?: string;
  [key: string]: any;
};

export function normalizeTrackingOptions(
  options?: TrackingOptions
): { [key: string]: any } | null {
  if (!options) {
    return null;
  }

  const { email, ...rest } = options;

  return rest;
}

const shouldSendEvents =
  process.env.NODE_ENV === "production" ||
  process.env.NODE_ENV === "development";

const canUsePosthog =
  apiKey !== undefined &&
  process.env.REACT_APP_IS_VENDOR_SITE !== "1" &&
  shouldSendEvents;

export function initialize(): void {
  if (isInitialized || !canUsePosthog) {
    return;
  }

  posthog.init("phc_QWZDUKDda8UqX0zfr4aU1Fgi4AhCYn0ef9Vb9UVhwqc", {
    api_host: "https://app.posthog.com",
    autocapture: false,
    capture_pageview: false,
  });

  // Initialize after 7 seconds
  // If the user leaves before 7 seconds,
  // then let's assume they were a bot.
  setTimeout(() => {
    posthog.capture("INITIALIZED");
  }, 7000);

  isInitialized = true;
}

export async function identify(
  id: string | null,
  options?: TrackingOptions
): Promise<void> {
  initialize();
  const properties = normalizeTrackingOptions(options);

  if (!canUsePosthog) return;

  if (id) {
    posthog.identify(id);

    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log("Analytics: Identified user", id);
    }

    if (properties) {
      posthog.capture("Set Properties", {
        $set: { location: "London" },
      });
    }
  } else {
    posthog.reset();
    // Amplitude.getInstance().setUserProperties(null);
  }
}

export async function track(
  event: string,
  options?: TrackingOptions
): Promise<void> {
  initialize();
  const properties = normalizeTrackingOptions(options);

  if (!canUsePosthog) return;

  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log("Analytics:", event, properties);
  }

  if (properties) {
    posthog.capture(event, properties);
  } else {
    posthog.capture(event);
  }
}

export async function setProperties(properties: object) {
  if (!canUsePosthog) return;

  posthog.capture("Set Properties", {
    $set: properties,
  });

  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log("Analytics: Set Properties", properties);
  }
}

export interface GetUidParams {
  email: string;
}

export const getUid = ({ email = "" }: GetUidParams): string => {
  return email.toLowerCase();
};

export default {
  events,
  initialize,
  identify,
  track,
};
