import { call, put, takeLatest } from "redux-saga/effects";

import { doAPIRequest, responseSuccess } from "reducks/sagas";
import { REFERRALS_CACHE_KEY } from "reducks/cache";

import {
  Candidate,
  FetchCandidatesForRecruiterPayload,
  referralsActions,
} from "./ducks";

interface ReferralsResponse {
  count: number;
  next: string;
  previous: string;
  results: Array<Candidate>;
}

function* fetchReferrals(): any {
  const options = {
    method: "GET",
    path: `/referrals/candidates/`,
    includeAuth: true,
    useCache: true,
    customCacheKey: REFERRALS_CACHE_KEY,
  };

  const {
    body,
    status,
  }: { body: ReferralsResponse; status: number } = yield call(
    doAPIRequest,
    options
  );

  if (!responseSuccess(status)) {
    return yield put(
      referralsActions.fetchReferralsFailure("Error fetching referrals...")
    );
  }

  yield put(referralsActions.fetchReferralsSuccess(body));
}

function* fetchCandidatesForRecruiter({
  payload,
}: {
  payload: FetchCandidatesForRecruiterPayload;
}): any {
  const options = {
    method: "GET",
    path: `/referrals/candidates/by_recruiter/`,
    includeAuth: true,
    useCache: true,
    customCacheKey: REFERRALS_CACHE_KEY,
  };

  if (payload.recruiter) {
    options.path += `?recruiter=${payload.recruiter}`;
  }

  const {
    body,
    status,
  }: { body: ReferralsResponse; status: number } = yield call(
    doAPIRequest,
    options
  );

  if (!responseSuccess(status)) {
    return yield put(
      referralsActions.fetchCandidatesForRecruiterFailure(
        "Error fetching referrals..."
      )
    );
  }

  yield put(referralsActions.fetchCandidatesForRecruiterSuccess(body));
}

function* watchFetchReferrals() {
  yield takeLatest(`${referralsActions.fetchReferrals}`, fetchReferrals);
}

function* watchFetchCandidatesForRecruiter() {
  yield takeLatest(
    `${referralsActions.fetchCandidatesForRecruiter}` as any,
    fetchCandidatesForRecruiter
  );
}

export const referralsSagas = [
  watchFetchReferrals(),
  watchFetchCandidatesForRecruiter(),
];
