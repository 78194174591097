import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Button,
  Heading,
  Nav,
  Panel,
  ProactiveReferralForm,
  ProactiveReferralFormPolicy,
  Text,
} from "components";
import { JOBS_PATH } from "routes";
import { scrollToTop } from "utilities/scroll";
import { selectFilterUrl } from "reducks/jobsFilters";

import css from "./ProactiveReferralPage.module.scss";

export const ProactiveReferralPage = () => {
  const filterParams = useSelector(selectFilterUrl);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div data-cy="ProactiveReferralPage">
      <Nav />
      <div className={css.Container}>
        <div className={css.BackButtonContainer}>
          <Link
            to={{
              pathname: JOBS_PATH,
              search: filterParams ? `?${filterParams}` : undefined,
            }}
            className={css.BackButtonLink}
          >
            <Button
              variant="text-link"
              className={css.BackButton}
              tabIndex={-1}
              hasBackArrow
            >
              Back to all Jobs
            </Button>
          </Link>
        </div>
        <div className={css.Content}>
          <div className={css.Header}>
            <Heading variant="h1" align="center" className={css.Title}>
              Recommend A Friend
            </Heading>
            <Text tag="p" className={css.Description}>
              Let your network know you&apos;re thinking about them. Add a
              friend&apos;s details and resume here and if we&apos;re able to
              get them a job in the next 6 months, we will reward you anywhere
              from $1,000-3,000!
            </Text>
          </div>
          <Panel className={css.ReferralFormContainer}>
            <ProactiveReferralForm />
          </Panel>
          <ProactiveReferralFormPolicy className={css.Legal} />
        </div>
      </div>
    </div>
  );
};
