import {
  StrictEffect,
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { hashCode } from "utilities/hashing";

import { authActions, authSagas, selectAccessToken } from "./auth";
import { cacheActions, selectCacheItem } from "./cache";
import { jobsSagas } from "./jobs";
import { metaDataSagas } from "./meta";
import { referralsSagas } from "./referrals";
import { store } from "./store";
import { userSagas } from "./user";

export interface ApiRequestProps {
  method: string;
  path: string;
  body?: Object;
  includeAuth?: boolean;
  useCache?: boolean;
  ttl?: number;
  customCacheKey?: string;
}

interface ApiRequestOptions {
  method: string;
  headers: Headers;
  body?: string;
}

interface FetchApiProps {
  path: string;
  options: ApiRequestOptions;
  useCache: boolean;
  ttl: number;
  customCacheKey: string;
  includeAuth?: boolean;
}

const DEFAULT_CACHE_TTL = 5; // 5 minutes for client side cache

const fetchApi = async ({
  path,
  options,
  useCache,
  ttl,
  customCacheKey,
  includeAuth = false,
}: FetchApiProps) => {
  let response;
  // Declare our variables for cache outside of the if statements
  let hash = customCacheKey;

  // If the item we're asking for exists in the cache and hasn't expired
  // yet, return it
  if (useCache) {
    // If we do not have a custom cache key then create a dynamic hash key.
    if (!hash || hash.length === 0) {
      hash = hashCode(
        `${path}::${JSON.stringify({ ...options, includeAuth })}`
      ).toString();
    }
    const cachedItem = selectCacheItem(store.getState(), hash);
    const now = new Date();
    if (cachedItem && now < cachedItem.expires) {
      return {
        body: cachedItem.value,
        status: cachedItem.status,
      };
    }
  }

  // This try catch is for if the API ever goes down
  try {
    response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}${path}`,
      options
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("fetchApi error: ", error);
    return {
      body: {},
      status: 500, // We're going to mock a server error if the request fails at the network level
    };
  }

  let body = "";
  // response.json() fails on requests that return "204 No Content"
  if (response.status !== 204) {
    body = await response.json();
  }
  // This handles a super busted API
  if (response.status >= 500) {
    return {
      body: {},
      status: response.status,
    };
  }

  // If we want to use the cache for this API request, set the response in
  // our cache
  if (useCache) {
    store.dispatch(
      cacheActions.setItem({
        name: hash,
        value: body,
        status: response.status,
        expires: ttl,
      })
    );
  }

  return {
    body,
    status: response.status,
  };
};

export function* doAPIRequest({
  method,
  path,
  body,
  includeAuth = true,
  useCache = false,
  ttl = DEFAULT_CACHE_TTL,
  customCacheKey = "",
}: ApiRequestProps): Generator<
  StrictEffect,
  | {
      body: any;
      status: number;
    }
  | void
  | { failure?: PayloadAction<string> },
  { failure?: PayloadAction<string> }
> {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (includeAuth) {
    yield put(authActions.checkAuth());
    const { failure }: { failure?: PayloadAction<string> } = yield race({
      success: take(`${authActions.checkAuthSuccess}`),
      failure: take(`${authActions.checkAuthFailure}`),
    });

    if (failure) {
      return;
    }

    const authToken = yield select(selectAccessToken);
    headers.append("Authorization", `Bearer ${authToken}`);
  }

  const options: ApiRequestOptions = {
    method,
    headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  return yield call(fetchApi, {
    path,
    options,
    useCache,
    ttl,
    customCacheKey,
    includeAuth,
  });
}

export const responseSuccess = (status: number): boolean =>
  status >= 200 && status < 300;

/**
 * Register each saga to to run on a given action.
 *
 * Right now we're assuming we want to run the sagas on "takeEvery". We can
 * add an option later if we decide we want more flexibility.
 */
export function* registerSagas(sagas: any) {
  // eslint-disable-next-line no-restricted-syntax
  for (const action in sagas) {
    if (Object.prototype.hasOwnProperty.call(sagas, action)) {
      yield takeEvery(action, sagas[action]);
    }
  }
}

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...jobsSagas,
    ...metaDataSagas,
    ...referralsSagas,
    ...userSagas,
  ]);
}
