import { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";

/**
 *  Inspired by https://usehooks.com/useDebounce/
 *
 * @param value value to be debounced
 * @param delay debounce delay in ms
 * @returns the debounced value
 */
function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  const debouncedHandler = useCallback(debounce(setDebouncedValue, delay), [
    delay,
  ]);

  useEffect(() => {
    debouncedHandler(value);
  }, [debouncedHandler, value]);

  return debouncedValue;
}

export { useDebounce };
