import { createSelector } from "reselect";
import numeral from "numeral";

import { RootState } from "reducks/rootReducer";
import { numberWithCommas } from "utilities/formatting";

import { completedNotHiredStatuses, hiredStatuses } from "./constants";
import { Candidate } from "./ducks";

export const selectReferrals = (state: RootState) => state.referrals;

export const defaultCandidate: Candidate = {
  id: 0,
  is_active: false,
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  last_updated: new Date().toISOString(),
  date_created: new Date().toISOString(),
  referral_set: [],
};

export const selectReferral = (id: string) => {
  return createSelector(selectReferrals, (referrals) => {
    return (
      referrals.results.find((r) => r.id.toString() === id) || defaultCandidate
    );
  });
};

export const selectActiveCandidates = createSelector(
  selectReferrals,
  (referralsState) => referralsState.results.filter((c) => c.is_active)
);

export const selectCompleteCandidates = createSelector(
  selectReferrals,
  (referralsState) => referralsState.results.filter((c) => !c.is_active)
);

export const selectTotalPotentialReward = createSelector(
  selectActiveCandidates,
  (activeCandidates) => {
    const rewardValues: Array<{ min: number; max: number }> = [];
    activeCandidates.forEach((c) => {
      const hiredReferral = c.referral_set.find((r) =>
        hiredStatuses.includes(r.status)
      );
      if (hiredReferral) {
        const hiredReward = hiredReferral.job.reward;
        rewardValues.push({
          min: hiredReward,
          max: hiredReward,
        });
      } else {
        const candidateRewardValues = c.referral_set
          .filter((rs) => !completedNotHiredStatuses.includes(rs.status))
          .map((rs) => {
            return rs.job.reward;
          })
          .sort((a, b) => a - b);
        rewardValues.push({
          min: candidateRewardValues[0],
          max: candidateRewardValues[candidateRewardValues.length - 1],
        });
      }
    });
    const totalRewardValues = rewardValues.reduce(
      (acc, cur) => {
        return {
          min: acc.min + cur.min,
          max: acc.max + cur.max,
        };
      },
      {
        min: 0,
        max: 0,
      }
    );
    if (totalRewardValues.min === totalRewardValues.max) {
      return numberWithCommas(totalRewardValues.max);
    }
    const numeralFormat = "0[.]0a";
    return `${numeral(totalRewardValues.min).format(numeralFormat)} - ${numeral(
      totalRewardValues.max
    ).format(numeralFormat)}`;
  }
);

export const selectTotalPaidOut = createSelector(
  selectReferrals,
  (referralsState) =>
    referralsState.results.reduce(
      (acc, cur) =>
        acc +
        cur.referral_set.reduce(
          (accRs, curRs) =>
            curRs.status === 35 ? accRs + curRs.job.reward : accRs,
          0
        ),
      0
    )
);
