import React from "react";
import cn from "classnames";

import { Text } from "components";

import css from "./FormMessage.module.scss";

interface FormMessageProps {
  error?: boolean | undefined;
  text: string;
}

const FormMessage = ({ text, error, ...rest }: FormMessageProps) => (
  <div
    className={cn({
      [css.FormMessage]: true,
      [css.FormMessageError]: !!error,
    })}
    {...rest}
  >
    <Text color="white">{text}</Text>
  </div>
);

export default FormMessage;
