import React from "react";
import cn from "classnames";

import { Text } from "components";

import css from "./Spinner.module.scss";

export interface SpinnerProps {
  size?: number;
  text?: string;
  className?: string;
}

const Spinner = ({ className = "", size = 50, text }: SpinnerProps) => {
  const styles = {
    height: `${size}px`,
    width: `${size}px`,
  };
  const classes = cn(css.SpinnerWrapper, { [className]: !!className });

  return (
    <div className={classes}>
      <div className={css.Spinner} style={styles}>
        <div className={css.DoubleBounce1} />
        <div className={css.DoubleBounce2} />
      </div>
      {text && (
        <Text tag="span" color="light" className={css.Text}>
          {text}
        </Text>
      )}
    </div>
  );
};

export default Spinner;
