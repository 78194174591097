/* eslint-disable sort-imports */
import React from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import TimeAgo from "timeago-react";
import cn from "classnames";
import { format } from "date-fns";

import { JobDetails, Text } from "components";
import { Job } from "reducks/jobs";
import { numberWithCommas } from "utilities/formatting";

import { selectAppSettings } from "reducks/meta";
import css from "./JobsListItem.module.scss";

interface JobsListItemProps {
  company: Job["company"];
  id: number;
  jobType: string;
  name: string;
  numPositionsOpen: number;
  positionType: {
    id: number;
    name: string;
  };
  postedDate: string;
  reward: number;
  className?: string;
  demoMode?: boolean;
  c2c_rate?: string;
}

const JobsListItem = ({
  company,
  id,
  jobType,
  name,
  numPositionsOpen,
  positionType,
  postedDate,
  reward,
  className,
  demoMode,
  c2c_rate,
}: JobsListItemProps) => {
  const appSettings = useSelector(selectAppSettings);
  const { isVendorSite } = appSettings;

  const today = new Date();
  const isPostedToday = format(today, "yyyy-MM-dd") === postedDate;
  const innerContents = (
    <>
      <div className={css.BasicInfo}>
        <span className={css.Link} data-cy="JobsListItemName">
          <Text className={css.LinkText} size="large">
            {name}
          </Text>
        </span>
        <JobDetails company={company} demoMode={demoMode} />
        <div className={css.Tags}>
          <span className={css.Tag} data-cy="JobListItem-JobType">
            {jobType}
          </span>
          <span className={css.Tag} data-title="Position Type">
            {positionType.name}
          </span>
          <span className={css.Tag} data-title="Openings">
            {numPositionsOpen} {numPositionsOpen === 1 ? "Opening" : "Openings"}
          </span>
        </div>
      </div>
      <div className={css.PostedDate}>
        {isPostedToday ? (
          "Posted today"
        ) : (
          <>
            Posted <TimeAgo datetime={postedDate} />
          </>
        )}
      </div>
      {isVendorSite ? (
        <>
          {c2c_rate && (
            <div className={css.Reward} data-title="Reward">
              ${c2c_rate}/hr
              <span className={css.RewardText}>max C2C rate</span>
            </div>
          )}
        </>
      ) : (
        <div className={css.Reward} data-title="Reward">
          {`$${numberWithCommas(reward)}`}
          <span className={css.RewardText}>reward per hire</span>
        </div>
      )}
    </>
  );

  const outerElementProps = {
    className: cn(css.JobsListItem, demoMode && css.DemoMode, className),
    "data-cy": "JobsListItem",
  };

  if (demoMode) {
    return <div {...outerElementProps}>{innerContents}</div>;
  }

  return (
    <Link {...outerElementProps} to={`/jobs/${id}/`}>
      {innerContents}
    </Link>
  );
};

export default JobsListItem;
