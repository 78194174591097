import React from "react";

import { Link } from "react-router-dom";

// Components
import { Button } from "components";

// Styles
import css from "./AuthNav.module.scss";

interface AuthNavProps {
  navButtonPath: string;
  navText: string;
  navButtonText: string;
}

const AuthNav = ({ navText, navButtonPath, navButtonText }: AuthNavProps) => {
  return (
    <nav className={css.AuthNav}>
      <div className={css.AuthNavRow}>
        <div className={css.AuthNavRowContent}>
          <p className={css.AuthNavRowContentText}>{navText}</p>
          <Link to={navButtonPath}>
            <Button variant="secondary">{navButtonText}</Button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default AuthNav;
