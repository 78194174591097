import React from "react";
import cn from "classnames";

import { animated, useSpring } from "react-spring";

import { useMeasure, usePrevious } from "utilities/hooks";
import { ReactComponent as ChevronSvg } from "images/chevron.svg";

import { Heading } from "components";

import css from "./ReferralsProcessOverview.module.scss";

export interface ProcessItemProps {
  id: string;
  children: React.ReactNode;
  heading: string;
  onClick: (id: string) => void;
  openStep: string;
}

export const ProcessItem = ({
  id,
  children,
  heading,
  onClick,
  openStep,
}: ProcessItemProps) => {
  const isOpen = id === openStep;
  const [bind, bounds] = useMeasure();
  const previous = usePrevious(isOpen);
  const viewHeight =
    "height" in bounds && typeof bounds.height === "number" ? bounds.height : 0;
  const springStyles = useSpring({
    to: { height: isOpen ? viewHeight : 0, opacity: isOpen ? 1 : 0 },
  });
  const animationStyles =
    isOpen && previous === isOpen ? { height: "auto" } : springStyles;

  const handleClick = () => onClick(id);

  return (
    <div className={css.ProcessItem}>
      <button
        type="button"
        className={cn(css.ProcessItemHeader, { [css.isOpen]: isOpen })}
        onClick={handleClick}
      >
        <Heading variant="h3" align="left" className={css.ProcessItemHeading}>
          {heading}
        </Heading>
        <ChevronSvg
          className={cn(css.HeaderChevron, { [css.isOpen]: isOpen })}
        />
      </button>
      <animated.div style={animationStyles}>
        <div {...bind} className={css.ProcessItemContent}>
          {children}
        </div>
      </animated.div>
    </div>
  );
};
