import { Action, ActionCreator } from "redux";
import { createSelector } from "reselect";

import { RootState } from "reducks/rootReducer";

import { Loader } from "./ducks";

const defaultLoader = (): Loader => {
  return { loading: false, loaded: false };
};

export const selectLoaders = (state: RootState) => state.loaders;

export const selectLoader = (loader: string | ActionCreator<Action>) => {
  const loaderName = typeof loader === "string" ? loader : `${loader}`;
  return createSelector(
    selectLoaders,
    (loaders) => loaders[loaderName] || defaultLoader()
  );
};
