import React from "react";
import cn from "classnames";

import { Label } from "components";
import css from "./Input.module.scss";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string; // Error text to display if there's a validation error
  inputClassName?: string; // Additional CSS class for input element
  label?: string;
  name: string;
  value: string; // Controlled component, so we'll manage state outside of it
}

const Input = React.forwardRef(
  (
    {
      className = "",
      error = "",
      inputClassName = "",
      label = "",
      name,
      onChange,
      type = "text",
      value,
      ...rest
    }: InputProps,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <div
      className={cn(
        {
          [css.Input]: true,
          [css.Error]: error.length > 0,
        },
        className
      )}
    >
      {label && <Label htmlFor={name} text={label} />}
      <input
        className={cn(css.InputElement, inputClassName)}
        id={name}
        name={name}
        onChange={onChange}
        type={type}
        value={value}
        {...rest}
        ref={ref}
      />
      {error.length > 0 && <span className={css.ErrorText}>{error}</span>}
    </div>
  )
);

export default Input;
