import React from "react";
import { useHistory } from "react-router-dom";

import { Button, Heading, Text } from "components";
import { JOBS_PATH } from "routes";

import noReferralsImg from "images/no-referrals.png";

import css from "./ReferralsListEmpty.module.scss";

const ReferralsListEmpty = ({ type }: { type: "active" | "complete" }) => {
  const history = useHistory();

  const activeOrCompletedText = type === "active" ? "active" : "past";

  const handleCtaClick = () => {
    history.push(JOBS_PATH);
  };
  return (
    <div className={css.ReferralsListEmpty} data-cy="ReferralsListEmpty">
      <img
        src={noReferralsImg}
        alt="No Referrals"
        className={css.NoReferralsImg}
      />
      <Heading variant="h1" align="center">
        You don&apos;t have any {activeOrCompletedText} referrals
      </Heading>
      <Text className={css.NoReferralsText} size="large">
        You can refer individuals to active listings.
      </Text>
      <Button className={css.NoReferralsCta} onClick={handleCtaClick}>
        Browse Available Jobs
      </Button>
    </div>
  );
};

export default ReferralsListEmpty;
