import React, { useState } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useQuery } from "utilities/hooks";

import { CityOption, JobFilterOption, JobFilterType } from "reducks/meta";

import { Checkbox } from "components";
import css from "./JobsFilterPanel.module.scss";

export const JobsFilterPanelOption = ({
  option,
  section,
}: {
  option: JobFilterOption | CityOption;
  section: JobFilterType | "company__city";
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const q = useQuery();
  const sectionQueries = q.getAll(section);
  const checked = sectionQueries.includes(option.name);

  const handleChange = () => {
    if (!checked) {
      q.append(section, option.name);
    } else {
      q.delete(section);
      const optionIndex = sectionQueries.indexOf(option.name);
      const newSectionQueries = sectionQueries.slice();
      newSectionQueries.splice(optionIndex, 1);
      newSectionQueries.forEach((sq) => {
        q.append(section, sq);
      });
    }
    history.push({ search: q.toString() });
  };

  return (
    <label
      className={cn(css.CheckboxLabel, checked && css.isChecked)}
      data-cy={`filter-${option.name}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Checkbox
        checked={checked}
        onChange={handleChange}
        className={css.Checkbox}
        isHovered={isHovered}
      />
      <span>{option.name}</span>
    </label>
  );
};
