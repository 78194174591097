import {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";

import { authActions, forgotPasswordActions } from "reducks/auth";
import { jobsActions } from "reducks/jobs";
import { metaActions } from "reducks/meta";
import { referralsActions } from "reducks/referrals";
import { userActions } from "reducks/user";

export interface Loader {
  loading: boolean;
  loaded: boolean;
  error?: string;
}
export interface LoadersState {
  [key: string]: Loader;
}

const DEFAULT_STATE = { loading: false, loaded: false };
const LOADING_STATE = { loading: true, loaded: false };
const LOADED_STATE = { loading: false, loaded: true };
const generateErrorState = (errorMessage: string) => {
  return {
    loading: false,
    loaded: true,
    error: errorMessage,
  };
};
const RESET_STATE = {
  loading: false,
  loaded: false,
  error: "",
};

export const initialLoadersState: LoadersState = {
  [`${authActions.checkAuth}`]: DEFAULT_STATE,
  [`${authActions.loginOAuth}`]: DEFAULT_STATE,
  [`${authActions.loginWithEmail}`]: DEFAULT_STATE,
  [`${authActions.signUpWithEmail}`]: DEFAULT_STATE,
  [`${forgotPasswordActions.sendForgotPasswordEmail}`]: DEFAULT_STATE,
  [`${jobsActions.fetchJobs}`]: DEFAULT_STATE,
  [`${jobsActions.referCandidate}`]: DEFAULT_STATE,
  [`${metaActions.fetchMetadata}`]: DEFAULT_STATE,
  [`${userActions.fetchUser}`]: DEFAULT_STATE,
  [`${userActions.updateUserDetails}`]: DEFAULT_STATE,
};

// Use this helper function if you just have a simple loader with sucess/failure
const generateLoaderReducers = <F = string>({
  initialAction,
  successAction,
  failureAction,
  resetAction,
  getErrorMessage = (fp: F) => {
    return typeof fp === "string" ? fp : "Error";
  },
}: {
  initialAction:
    | ActionCreatorWithoutPayload<string>
    | ActionCreatorWithPayload<any, string>;
  successAction:
    | ActionCreatorWithoutPayload<string>
    | ActionCreatorWithPayload<any, string>;
  failureAction?: ActionCreatorWithPayload<F, string>;
  resetAction?: ActionCreatorWithoutPayload<string>;
  getErrorMessage?: (failurePayload: F) => string;
}) => {
  const loaderReducers: {
    [a: string]: (state: LoadersState, action?: PayloadAction<F>) => void;
  } = {
    [`${initialAction}`]: (state: LoadersState) => {
      state[`${initialAction}`] = LOADING_STATE;
    },
    [`${successAction}`]: (state: LoadersState) => {
      state[`${initialAction}`] = LOADED_STATE;
    },
  };

  const failureReducerObject = failureAction
    ? {
        [`${failureAction}`]: (
          state: LoadersState,
          action: PayloadAction<F>
        ) => {
          const errorMessage = getErrorMessage(action.payload);
          state[`${initialAction}`] = generateErrorState(errorMessage);
        },
      }
    : {};

  if (resetAction) {
    loaderReducers[`${resetAction}`] = (state: LoadersState) => {
      state[`${initialAction}`] = RESET_STATE;
    };
  }

  return { ...loaderReducers, ...failureReducerObject };
};

const loadersSlice = createSlice({
  name: "loaders",
  initialState: initialLoadersState,
  reducers: {},
  extraReducers: {
    ...generateLoaderReducers({
      initialAction: authActions.checkAuth,
      successAction: authActions.checkAuthSuccess,
      failureAction: authActions.checkAuthFailure,
    }),
    ...generateLoaderReducers({
      initialAction: authActions.fetchNewToken,
      successAction: authActions.fetchNewTokenComplete,
    }),
    ...generateLoaderReducers({
      initialAction: jobsActions.fetchJobs,
      successAction: jobsActions.fetchJobsSuccess,
      failureAction: jobsActions.fetchJobsFailure,
    }),
    ...generateLoaderReducers({
      initialAction: jobsActions.fetchJob,
      successAction: jobsActions.fetchJobSuccess,
      failureAction: jobsActions.fetchJobFailure,
      getErrorMessage: (fp) => fp.message,
    }),
    ...generateLoaderReducers({
      initialAction: jobsActions.referCandidate,
      successAction: jobsActions.referCandidateSuccess,
      failureAction: jobsActions.referCandidateFailure,
      resetAction: jobsActions.referCandidateReset,
      getErrorMessage: (fp) => fp,
    }),
    ...generateLoaderReducers({
      initialAction: metaActions.fetchMetadata,
      successAction: metaActions.fetchMetadataSuccess,
      failureAction: metaActions.fetchMetadataFailure,
    }),
    ...generateLoaderReducers({
      initialAction: referralsActions.fetchReferrals,
      successAction: referralsActions.fetchReferralsSuccess,
      failureAction: referralsActions.fetchReferralsFailure,
    }),
    ...generateLoaderReducers({
      initialAction: referralsActions.fetchCandidatesForRecruiter,
      successAction: referralsActions.fetchCandidatesForRecruiterSuccess,
      failureAction: referralsActions.fetchCandidatesForRecruiterFailure,
    }),
    ...generateLoaderReducers({
      initialAction: userActions.fetchUser,
      successAction: userActions.fetchUserSuccess,
      failureAction: userActions.fetchUserFailure,
    }),
    ...generateLoaderReducers({
      initialAction: authActions.loginOAuth,
      successAction: authActions.oauthSuccess,
      failureAction: authActions.oauthFailure,
    }),
    ...generateLoaderReducers({
      initialAction: authActions.loginWithEmail,
      successAction: authActions.loginWithEmailSuccess,
      failureAction: authActions.loginWithEmailFailure,
      resetAction: authActions.loginWithEmailReset,
    }),
    ...generateLoaderReducers({
      initialAction: authActions.logOut,
      successAction: authActions.logOutSuccess,
      failureAction: authActions.logOutFailure,
    }),
    ...generateLoaderReducers({
      initialAction: authActions.signUpWithEmail,
      successAction: authActions.signUpWithEmailSuccess,
      failureAction: authActions.signUpWithEmailFailure,
      resetAction: authActions.signUpWithEmailReset,
    }),
    ...generateLoaderReducers({
      initialAction: userActions.updateUserDetails,
      successAction: userActions.updateUserSuccess,
      failureAction: userActions.updateUserFailure,
    }),
    ...generateLoaderReducers({
      initialAction: forgotPasswordActions.sendForgotPasswordEmail,
      successAction: forgotPasswordActions.sendForgotPasswordEmailSuccess,
      failureAction: forgotPasswordActions.sendForgotPasswordEmailFailure,
    }),
    ...generateLoaderReducers({
      initialAction: forgotPasswordActions.resetPassword,
      successAction: forgotPasswordActions.resetPasswordSuccess,
      failureAction: forgotPasswordActions.resetPasswordError,
    }),
  },
});

export const { reducer } = loadersSlice;
