import React from "react";
import cn from "classnames";

import { PRIVACY_POLICY, RECRUITING_AGREEMENT_POLICY } from "routes";

import css from "../FormSubmissionPolicy/FormSubmissionPolicy.module.scss";

const ProactiveReferralFormPolicy = ({ className }: { className?: string }) => {
  return (
    <div className={cn(css.FormSubmissionPolicy, className)}>
      By submitting this candidate’s contact information to us, you agree to our{" "}
      <a
        href={RECRUITING_AGREEMENT_POLICY}
        target="_blank"
        rel="noopener noreferrer"
      >
        Recruiting Agreement Policy
      </a>
      , and{" "}
      <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      . You have agreed to connect Agility Partners with the above candidate who
      is aware of this introduction. Upon hire of this candidate, you will be
      paid the specified reward (Success Fee) according to our Recruiting
      Agreement policy.
    </div>
  );
};

export default ProactiveReferralFormPolicy;
