import { Referral } from "reducks/referrals";

export function getSpread(referral: Referral) {
  const {
    pay_rate,
    bill_rate,
    duration_months,
    job_type,
    salary_high,
  } = referral.job;
  const { company } = referral.job;
  const placement_fee = company.placement_fee || 0;
  const { status } = referral;

  // Bad statuses for job
  const badStatuses = [25];

  if (badStatuses.includes(status)) {
    return 0;
  }

  // TODO: Handle job offered

  // Contract jobs
  if (
    pay_rate &&
    bill_rate &&
    duration_months &&
    (job_type === 1 || job_type === 3)
  ) {
    return (bill_rate - pay_rate * 1.25) * 167 * duration_months * 0.1;
  }

  // Permanent jobs
  if (job_type === 2 && placement_fee && salary_high) {
    return placement_fee * salary_high * 0.15;
  }

  return 0;
}
