import React, { useMemo, useState } from "react";
import { useWindowSize } from "utilities/hooks";

import { ReactComponent as Arrow } from "images/page-arrow.svg";

import Quote, { QuoteProps } from "./Quote";
import css from "./Quotes.module.scss";

export interface QuotesProps {
  quotes: Array<QuoteProps>;
}

const MAX_QUOTE_WIDTH = 520;

const Quotes = ({ quotes }: QuotesProps) => {
  const windowSize = useWindowSize();
  const [activeQuote, setActiveQuote] = useState(1);

  const handleNextClick = () => {
    setActiveQuote(Math.min(activeQuote + 1, quotes.length - 1));
  };

  const handlePreviousClick = () => {
    setActiveQuote(Math.max(activeQuote - 1, 0));
  };

  const showMultipleQuotes = useMemo(() => {
    const maxQuoteWidth = MAX_QUOTE_WIDTH;
    const windowWidth = windowSize.width as number;

    return windowWidth > maxQuoteWidth * 2;
  }, [windowSize.width]);

  const quoteWidth = useMemo(() => {
    const maxQuoteWidth = MAX_QUOTE_WIDTH;
    const windowWidth = windowSize.width as number;

    // If we can fit at least 2 quotes on the screen,
    // then we're in desktop mode. Otherwise, let's only show
    // one quote at a time.
    return showMultipleQuotes ? maxQuoteWidth : windowWidth;
  }, [windowSize.width, showMultipleQuotes]);

  const multiQuoteOffset = useMemo(() => {
    const initialOffset = 1;
    const currentOffset = activeQuote - initialOffset;

    return quoteWidth * currentOffset;
  }, [activeQuote, quoteWidth]);

  const singleQuoteOffset = useMemo(() => {
    const initialOffset = 0;
    const currentOffset = activeQuote - initialOffset;

    return quoteWidth * currentOffset;
  }, [activeQuote, quoteWidth]);

  return (
    <div className={css.quotes}>
      <div className={css.container}>
        <div
          className={css.window}
          style={{
            width: quoteWidth * quotes.length,
            transform: `translateX(${
              -1 * (showMultipleQuotes ? multiQuoteOffset : singleQuoteOffset)
            }px)`,
          }}
        >
          {quotes.map((quote, index) => (
            <Quote
              key={quote.speaker}
              {...quote}
              isActive={index === activeQuote}
              width={quoteWidth}
            />
          ))}
        </div>
      </div>
      <div className={css.controls}>
        <button
          className={css.button}
          onClick={handlePreviousClick}
          type="button"
          disabled={activeQuote === 0}
        >
          <Arrow />
        </button>
        <button
          className={css.button}
          onClick={handleNextClick}
          type="button"
          disabled={activeQuote === quotes.length - 1}
        >
          <Arrow style={{ transform: "rotate(180deg)" }} />
        </button>
      </div>
    </div>
  );
};

export default Quotes;
