import React from "react";

import { Button } from "components";
import { ReactComponent as FacebookIcon } from "images/facebook.svg";
import { ReactComponent as GithubIcon } from "images/github.svg";
import { JOBS_PATH } from "routes";
import { ReactComponent as LinkedinIcon } from "images/linkedin.svg";
import css from "./OAuthButtons.module.scss";

const linkedinHref = (routeAfterLoginBase64?: string) =>
  [
    "https://www.linkedin.com/oauth/v2/authorization",
    "?response_type=code",
    `&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}`,
    `&redirect_uri=${process.env.REACT_APP_HOST}/oauth/complete/linkedin/`,
    "&scope=r_emailaddress%20r_liteprofile",
    `&state=${routeAfterLoginBase64}`,
  ].join("");

const fbHref = (routeAfterLoginBase64?: string) =>
  [
    "https://www.facebook.com/v7.0/dialog/oauth",
    `?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}`,
    `&redirect_uri=${process.env.REACT_APP_HOST}/oauth/complete/facebook/`,
    "&scope=email",
    `&state=${routeAfterLoginBase64}`,
  ].join("");

const githubHref = (routeAfterLoginBase64?: string) =>
  [
    "https://github.com/login/oauth/authorize",
    `?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}`,
    `&redirect_uri=${process.env.REACT_APP_HOST}/oauth/complete/github/`,
    "&scope=user:email",
    `&state=${routeAfterLoginBase64}`,
  ].join("");

interface OAuthButtonsProps {
  isLogin?: boolean;
  routeAfterLogin?: string;
}

const OAuthButtons = ({
  isLogin,
  routeAfterLogin = JOBS_PATH,
}: OAuthButtonsProps) => {
  const routeAfterLoginBase64 = btoa(routeAfterLogin);
  return (
    <div>
      <a href={linkedinHref(routeAfterLoginBase64)}>
        <Button
          className={css.LinkedinButton}
          tabIndex={-1}
          data-cy="linkedin-button"
        >
          <span className={css.SocialButtonIcon}>
            <LinkedinIcon className={css.LinkedinIcon} />
          </span>
          <span className={css.SocialButtonText}>
            {isLogin ? "Log in with LinkedIn" : "Sign up with LinkedIn"}
          </span>
        </Button>
      </a>
      <a href={githubHref(routeAfterLoginBase64)}>
        <Button
          className={css.GithubButton}
          tabIndex={-1}
          data-cy="github-button"
        >
          <span className={css.SocialButtonIcon}>
            <GithubIcon className={css.GithubIcon} />
          </span>
          <span className={css.SocialButtonText}>
            {isLogin ? "Log in with GitHub" : "Sign up with GitHub"}
          </span>
        </Button>
      </a>
      <a href={fbHref(routeAfterLoginBase64)}>
        <Button
          className={css.FacebookButton}
          tabIndex={-1}
          data-cy="facebook-button"
        >
          <span className={css.SocialButtonIcon}>
            <FacebookIcon className={css.FacebookIcon} />
          </span>
          <span className={css.SocialButtonText}>
            {isLogin ? "Log in with Facebook" : "Sign up with Facebook"}
          </span>
        </Button>
      </a>
    </div>
  );
};

export default OAuthButtons;
