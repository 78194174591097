import React from "react";
import cn from "classnames";
import css from "./JobsListItem.module.scss";

const JobListLoading = () => (
  <div
    className={cn(css.JobsListItem, css.Loading)}
    data-cy="JobsListItemLoading"
  >
    <div className={css.BasicInfo}>
      <div className={cn(css.Link, css.LoadingItem)}>
        <div className={css.LinkText}>Loading</div>
      </div>
      <div className={cn(css.Details, css.LoadingItem)}>
        <div className={css.Detail}>Loading</div>
        <div className={css.Detail}>Loading</div>
      </div>
      <div className={cn(css.Tags, css.LoadingItem)}>
        <div className={css.Tag}>Loading</div>
        <div className={css.Tag}>Loading</div>
        <div className={css.Tag}>Loading</div>
      </div>
      <span className={cn(css.PostedDate, css.LoadingItem)}>Loading</span>
    </div>
    <div className={cn(css.Reward, css.LoadingItem)}>Loading</div>
  </div>
);

export default JobListLoading;
