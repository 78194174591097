import React, { useEffect } from "react";

import { NavLink, useHistory } from "react-router-dom";

import {
  ACTIVE_REFERRALS_PATH,
  COMPLETE_REFERRALS_PATH,
  REFERRALS_PATH,
} from "routes";

import css from "./ReferralsFilter.module.scss";

const ReferralsFilter = () => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === REFERRALS_PATH) {
      history.replace(ACTIVE_REFERRALS_PATH);
    }
  });

  return (
    <div className={css.ReferralsFilter} data-cy="ReferralsFilter">
      <div className={css.InnerWrapper}>
        <NavLink
          to={ACTIVE_REFERRALS_PATH}
          activeClassName={css.isActive}
          className={css.NavLink}
          exact
        >
          Active
        </NavLink>
        <NavLink
          to={COMPLETE_REFERRALS_PATH}
          activeClassName={css.isActive}
          className={css.NavLink}
          exact
        >
          Complete
        </NavLink>
      </div>
    </div>
  );
};

export default ReferralsFilter;
