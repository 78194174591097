// Libraries
import React from "react";

// Dependencies
import { Text } from "components";

// Component
import css from "./JobsListHeader.module.scss";

interface JobsListHeaderProps {
  count: number;
  scrollRef: any;
}

const JobsListHeader = ({ count = 0, scrollRef }: JobsListHeaderProps) => {
  return (
    <div className={css.JobsListHeader}>
      <div ref={scrollRef} className={css.JobsListHeaderScrollAnchor} />
      <Text
        className={css.JobsListHeaderHeadingText}
        tag="span"
        weight={700}
        color="black"
        data-cy="JobsListHeaderText"
      >{`${count} ${count === 1 ? "Result" : "Results"}`}</Text>
    </div>
  );
};

export default JobsListHeader;
