import { RootState } from "reducks/rootReducer";
import { createSelector } from "reselect";

export const selectUser = (state: RootState) => state.user;

export const selectIsUserLoggedIn = createSelector(
  selectUser,
  (user) => !!user.id
);

export const selectSentByUser = (state: RootState) =>
  state.user?.sentByUserPublicId;
