import React from "react";

import { Link } from "react-router-dom";

import { Heading, Text } from "components";
import { ReactComponent as HeroArc } from "images/marketing/hero-arc.svg";
import { SIGN_UP_PATH } from "routes";
import resumesSrc from "images/marketing/resumes.png";
import { useWindowSize } from "utilities/hooks";

import css from "./MarketingLayout.module.scss";

interface MarketingLayoutProps {
  hero: React.ReactElement;
  children: React.ReactElement;
}

const MarketingLayout = ({ hero, children }: MarketingLayoutProps) => {
  const { isDesktop } = useWindowSize();
  return (
    <div className={css.main}>
      {hero}
      {children}
      <section className={css.Footer}>
        <HeroArc className={css.FooterArc} />
        <div className={css.FooterContent}>
          <div>
            <Heading
              variant="h2"
              align={isDesktop ? "left" : "center"}
              className={css.FooterHeading}
            >
              Ready to refer a friend?
            </Heading>
            <Text color="white" size="large">
              Ever been asked to provide a referral?
            </Text>
            <Text color="white" size="large">
              AgilityConnect rewards you as if you recruited.
            </Text>
            <Link to={SIGN_UP_PATH} className={css.PillButton}>
              Sign Up Today
            </Link>
          </div>
          <div>
            <img
              alt="Agility Connect"
              src={resumesSrc}
              className={css.ResumesImg}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default MarketingLayout;
