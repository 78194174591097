import React from "react";
import cn from "classnames";

// Components
import { Label } from "components";

// Styles
import css from "./RadioGroup.module.scss";

interface RadioGroupProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: React.ReactNode; // Error text to display if there's a validation error
  inputClassName?: string; // Additional CSS class for input element
  label?: string;
  name: string;
  register: any;
  required?: any;
  note?: string;
  defaultSelection?: string;
  radioOptions: Array<string>;
}

const RadioGroup = ({
  className = "",
  error = "",
  inputClassName = "",
  label = "",
  register,
  required,
  type = "text",
  defaultSelection,
  name,
  radioOptions,
  ...rest
}: RadioGroupProps) => (
  <fieldset
    className={cn({
      [css.RadioGroup]: true,
      [css.Error]: !!error,
      [className]: !!className,
    })}
  >
    {label && <legend className={css.radioGroupLabel}>{label}</legend>}
    <div className={css.radioOptions}>
      {radioOptions.map((radioOption) => (
        <div className={css.radioOption} key={`radio-option-${radioOption}`}>
          <input
            ref={register({ required })}
            type="radio"
            name={name}
            value={radioOption}
            id={`${name}-${radioOption}`}
            {...rest}
          />
          <Label
            className={css.radioOptionLabel}
            htmlFor={`${name}-${radioOption}`}
            text={radioOption}
          />
        </div>
      ))}
    </div>
    {!!error && <span className={css.ErrorText}>{error}</span>}
  </fieldset>
);

export default RadioGroup;
