import React, { ButtonHTMLAttributes, Ref } from "react";

import { Link, LinkProps } from "react-router-dom";
import cn from "classnames";

import { ReactComponent as ArrowLeftSvg } from "images/arrow-left.svg";
import { ReactComponent as LoadingIcon } from "images/loading.svg";
import css from "./Button.module.scss";

interface ButtonBase {
  isLoading?: boolean;
  selected?: boolean;
  variant?: "primary" | "secondary" | "green" | "text-link";
  size?: "default" | "small";
  disabled?: boolean;
  hasBackArrow?: boolean;
}

interface LinkButton extends LinkProps, ButtonBase {}

export interface RegularButton
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonBase {}

export type ButtonProps = RegularButton | LinkButton;

export const Button = React.forwardRef(
  (
    {
      children,
      className = "",
      isLoading = false,
      selected = false,
      variant = "primary",
      size = "default",
      disabled = false,
      hasBackArrow,
      ...rest
    }: ButtonProps,
    ref: Ref<any>
  ) => {
    const classes = cn(
      css.Button,
      css[`size-${size}`],
      css[`variant-${variant}`],
      {
        [css["is-loading"]]: isLoading,
        [css.selected]: selected,
        [css.disabled]: disabled,
        [className]: className,
      }
    );

    const computedChildren = isLoading ? (
      <LoadingIcon className={css.LoadingIcon} />
    ) : (
      <>
        {hasBackArrow && <ArrowLeftSvg className={css.ArrowLeft} />}
        {children}
      </>
    );

    if ("to" in rest) {
      return (
        <Link to={rest.to} className={classes} ref={ref}>
          {computedChildren}
        </Link>
      );
    }

    const type = rest.type ? rest.type : "button";

    return (
      <button
        className={classes}
        onClick={rest.onClick}
        type={type} // eslint-disable-line react/button-has-type
        {...rest}
        ref={ref}
      >
        {computedChildren}
      </button>
    );
  }
);

export default Button;
