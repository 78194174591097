import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import { JOBS_PATH } from "routes";
import { Link } from "react-router-dom";

import { selectAppSettings } from "reducks/meta";

// Assets
import acSvgColor from "images/ac-logo-color.svg";
import apLogo from "images/ap-logo.svg";

// Styles
import css from "./AuthLogoLinkMobile.module.scss";

const AuthLogoLinkMobile = () => {
  const appSettings = useSelector(selectAppSettings);

  return (
    <Link
      to={JOBS_PATH}
      className={cn(
        css.AuthLogoLinkMobile,
        appSettings.isVendorSite && css.ShowLogo
      )}
    >
      <img
        className={css.AgilityLogo}
        src={appSettings.isVendorSite ? apLogo : acSvgColor}
        alt="Agility Connect"
      />
    </Link>
  );
};

export default AuthLogoLinkMobile;
