import React from "react";
import cn from "classnames";

import { ReactComponent as QuoteSVG } from "images/quote.svg";
import css from "./Quotes.module.scss";

export interface QuoteProps {
  text: string;
  speaker: string;
  title: string;
  isActive?: boolean;
  width?: number | string;
}

const Quote = ({
  text,
  speaker,
  title,
  isActive = false,
  width = "100%",
}: QuoteProps) => {
  return (
    <div className={css.quoteContainer} style={{ width }}>
      <div className={cn([css.quote, isActive && css.activeQuote])}>
        <QuoteSVG />
        <div className={css.textContainer}>
          {/* eslint-disable-next-line react/no-danger */}
          <p className={css.text} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        <span className={css.speaker}>{speaker}</span>
        <span className={css.title}>{title}</span>
      </div>
    </div>
  );
};

export default Quote;
