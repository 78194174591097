/* eslint-disable sort-imports */
import React, { useCallback, useEffect, useState } from "react";

import "react-toastify/dist/ReactToastify.min.css";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { Button, Heading, Input, Text } from "components";
import { REFERRALS_PATH, SIGN_UP_PATH, getLoginPath } from "routes";
import HmmImage from "images/hmm.png";
import { jobsActions } from "reducks/jobs";
import { RootState } from "reducks/rootRefducer";
import { ReactComponent as SuccessCheckIcon } from "images/success-check.svg";
import { selectLoader } from "reducks/loaders";
import { selectUser } from "reducks/user";
import { validateEmail } from "utilities/forms";

// Assets
import { ReactComponent as CheckSvg } from "images/check.svg";
import skillsetAutomation from "images/skillset-automation.svg";
import skillsetData from "images/skillset-data.svg";
import skillsetDevelopment from "images/skillset-development.svg";
import skillsetInfrastructure from "images/skillset-infrastructure.svg";
import skillsetProduct from "images/skillset-product.svg";
import skillsetSupport from "images/skillset-end-user-support.svg";

// Styles
import css from "./ProactiveReferralForm.module.scss";

const SKILLS = [
  {
    id: "product",
    title: "Product",
    description: "Liaisons between the business and technology",
    examples:
      "Product Managers, Project Managers, UX/Visual Designers, Business Analysts",
    icon: skillsetProduct,
  },
  {
    id: "development",
    title: "Development",
    description: "Builders and testers of applications",
    examples: "Developers, Engineers, QAs",
    icon: skillsetDevelopment,
  },
  {
    id: "infrastructure",
    title: "Infrastructure",
    description: "Stewards of technology ecosystems",
    examples: "Sys Admins, Network Engineers, Security Engineers",
    icon: skillsetInfrastructure,
  },
  {
    id: "end_user_support",
    title: "End User Support",
    description:
      "First line of help when customers encounter technical problems",
    examples: "Help Desk, Desktop Support, Printer and Email Support",
    icon: skillsetSupport,
  },
  {
    id: "automation",
    title: "Automation",
    description: "Authors of code that streamlines human processes",
    examples: "Test Automation, RPA",
    icon: skillsetAutomation,
  },
  {
    id: "data",
    title: "Data",
    description: "Responsible for creating signal from noise",
    examples: "BI, ETL, Big Data, AI, Machine Learning",
    icon: skillsetData,
  },
];

const ReferralSuccessToast = () => (
  <div className={css.ReferralSuccessToast} data-cy="success-toast">
    <div className={css.ReferralSuccessContent}>
      <SuccessCheckIcon className={css.ReferralSuccessIcon} />
      <div className={css.ReferralSuccessContentText}>
        <p className={css.ReferralSuccessToastHeader}>
          Your referral has been submitted!
        </p>
        <Link className={css.ReferralSuccessToastLink} to={REFERRALS_PATH}>
          View in Your Referrals
        </Link>
      </div>
    </div>
  </div>
);

interface ReferralFailureToastProps {
  error: string | undefined;
  email: string;
}

const ReferralFailureToast = ({ error, email }: ReferralFailureToastProps) => (
  <div className={css.ReferralFailureToast} data-cy="failure-toast">
    {error?.indexOf("This candidate has already been referred to this job") !==
    -1 ? (
      <>
        <img src={HmmImage} alt="" />
        <div>
          <h3
            className={css.ReferralFailureToastHeader}
            data-cy="failure-toast-header"
          >
            Looks like you&apos;ve already sent us this candidate.
          </h3>
          <p className={css.ReferralFailureToastBody}>
            <Link className={css.ReferralFailureToastLink} to={REFERRALS_PATH}>
              View your past referrals here
            </Link>{" "}
            or try referring a different candidate for this position.
          </p>
        </div>
      </>
    ) : (
      <>{error}</>
    )}
  </div>
);

interface SkillTileProps {
  title: string;
  description: string;
  examples: string;
  icon: string;
  selected?: boolean;
  faded?: boolean;
  onClick: (e: { preventDefault: () => void }) => void;
}

const SkillTile = ({
  title,
  description,
  examples,
  icon,
  selected,
  onClick,
  faded,
}: SkillTileProps) => (
  <button
    type="button"
    className={cn(css.SkillTile, {
      [css.SkillTileSelected]: selected,
      [css.SkillTileFaded]: faded,
    })}
    data-cy="SkillTileOption"
    onClick={onClick}
  >
    <div className={css.SkillTileCheckContainer}>
      <CheckSvg className={css.SkillTileCheckIcon} />
    </div>
    <div className={css.SkillTileIconContainer}>
      <img className={css.SkillTileIcon} src={icon} alt={`${title} Icon`} />
    </div>
    <Heading variant="h3" align="center" className={css.SkillTileHeading}>
      {title}
    </Heading>
    <p className={css.SkillTileDescription}>{description}</p>
    <Heading
      variant="h4"
      align="center"
      className={css.SkillTileExampleHeading}
    >
      Examples Include
    </Heading>
    <p className={css.SkillTileExamples}>{examples}</p>
  </button>
);

const ProactiveReferralForm = () => {
  const dispatch = useDispatch();
  const referralLoader = useSelector(selectLoader(jobsActions.referCandidate));
  const user = useSelector((state: RootState) => {
    return selectUser(state);
  });
  const isSignedIn = user.id !== null;
  const { pathname } = useLocation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [skill, setSkill] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    skill: "",
  });

  const resetForm = useCallback(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setSkill("");
    setIsSubmitting(false);
  }, []);

  useEffect(() => {
    // assuming success based on loader state
    const isSuccess =
      isSubmitting &&
      !referralLoader.loading &&
      referralLoader.loaded &&
      (!referralLoader.error || referralLoader.error.length === 0);

    // assuming error based on loader state
    const isFailure =
      isSubmitting &&
      !referralLoader.loading &&
      referralLoader.loaded &&
      referralLoader.error &&
      referralLoader.error.length > 0;

    if (isSuccess) {
      toast.success(ReferralSuccessToast, {
        toastId: "referral-form-success-toast",
      });
      resetForm();
      dispatch(jobsActions.referCandidateReset());
    } else if (isFailure) {
      toast.error(
        <ReferralFailureToast error={referralLoader.error} email={email} />,
        {
          toastId: "referral-form-error-toast",
        }
      );
      setIsSubmitting(false);
      dispatch(jobsActions.referCandidateReset());
    }
  }, [dispatch, resetForm, referralLoader, email, isSubmitting]);

  function handleSkillSelection(id: string) {
    if (id === skill) {
      setSkill("");
    } else {
      setSkill(id);
    }
  }

  const validateFirstName = (): string => {
    if (firstName.length === 0) {
      return "First name can't be blank.";
    }
    return "";
  };

  const validateLastName = (): string => {
    if (lastName.length === 0) {
      return "Last name can't be blank.";
    }
    return "";
  };

  const handleEmailValidation = (): string => {
    if (email.length === 0 && phone.length === 0) {
      return "Email or phone must be completed.";
    }

    if (phone.length > 0) {
      return "";
    }

    return validateEmail(email, false);
  };

  const validatePhone = (): string => {
    if (email.length === 0 && phone.length === 0) {
      return "Email or phone must be completed.";
    }
    if (email.length > 0) {
      return "";
    }
    if (phone.replace(/\D/gi, "").length < 10) {
      return "Phone must be valid.";
    }
    return "";
  };

  const validateSkill = (): string => {
    if (skill === "") {
      return "A skillset must be selected.";
    }
    return "";
  };

  const validate = () => {
    const errors = {
      firstName: validateFirstName(),
      lastName: validateLastName(),
      email: handleEmailValidation(),
      phone: validatePhone(),
      skill: validateSkill(),
    };
    setFormErrors(errors);

    // Make sure the length of all the errors equals 0.
    if (Object.values(errors).join("").length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    dispatch(jobsActions.referCandidateReset());
    toast.dismiss();
    const isValid = validate();
    if (isValid) {
      setIsSubmitting(true);
      dispatch(
        jobsActions.referCandidate({
          jobId: 0,
          userId: user.id as number,
          firstName,
          lastName,
          email,
          phone,
          isApplicant: false,
        })
      );
    }
  };

  return (
    <>
      <ToastContainer
        toastClassName={css.ReferralToast}
        position="top-center"
        autoClose={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <Heading variant="h2" align="left" className={css.Header}>
        Refer a Candidate
      </Heading>
      {!isSignedIn && (
        <div className={css.NotLoggedInMessage}>
          <div className={css.NotLoggedInBox}>
            <Text weight={600} color="black">
              You must be signed in to submit a referral.
            </Text>
            <Button to={getLoginPath(pathname)}>Log In</Button>
          </div>
          <Text size="small" tag="span">
            Not a member? <Link to={SIGN_UP_PATH}>Sign up now</Link>
          </Text>
        </div>
      )}
      <form onSubmit={(e) => handleSubmit(e)} className={css.Form}>
        <div
          className={cn(css.FormRowNames, !isSignedIn && css.isElementDisabled)}
        >
          <Input
            label="First Name"
            name="firstName"
            className={css.FirstName}
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={formErrors.firstName}
            disabled={!isSignedIn}
            autoCapitalize="on"
            autoComplete="given-name"
            autoCorrect="off"
          />
          <Input
            label="Last Name"
            name="lastName"
            className={css.LastName}
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={formErrors.lastName}
            disabled={!isSignedIn}
            autoCapitalize="on"
            autoComplete="family-name"
            autoCorrect="off"
          />
        </div>
        <div className={css.EmailPhoneContainer}>
          <div
            className={cn(css.FormRow, !isSignedIn && css.isElementDisabled)}
          >
            <Input
              label="Email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={formErrors.email}
              className={css.EmailInput}
              disabled={!isSignedIn}
              autoCapitalize="off"
              autoComplete="email"
              autoCorrect="off"
            />
          </div>
          <div className={css.OrContainer}>
            <div className={css.OrText}>or</div>
            <hr
              className={cn(
                css.SmallHorizontalDivider,
                css.OrHorizontalDivider
              )}
            />
          </div>
          <div
            className={cn(css.FormRow, !isSignedIn && css.isElementDisabled)}
          >
            <Input
              label="Phone"
              name="phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              error={formErrors.phone}
              disabled={!isSignedIn}
              autoComplete="tel"
              autoCorrect="off"
            />
          </div>
        </div>
        {/* <div className={cn(css.FormRow, !isSignedIn && css.isElementDisabled)}>
          <FileUpload
            name="resume"
            label="Resume"
            file={resume}
            onDrop={onDrop}
            handleUploadCancel={handleUploadCancel}
          />
        </div> */}
        <hr className={css.SmallHorizontalDivider} />
        <div
          className={cn(
            css.SkillTileGridHeader,
            !isSignedIn && css.isElementDisabled
          )}
        >
          <Heading variant="h3" align="center" className={css.HeadingQuestion}>
            Which of the following skillsets best describes this candidate?
          </Heading>
          {formErrors.skill.length > 0 && (
            <div className={css.ErrorText}>{formErrors.skill}</div>
          )}
        </div>
        <div
          className={cn(
            css.SkillTileGrid,
            !isSignedIn && css.isElementDisabled
          )}
        >
          {SKILLS.map((skillOption) => (
            <SkillTile
              onClick={() => handleSkillSelection(skillOption.id)}
              icon={skillOption.icon}
              key={`skill-tile-${skillOption.id}`}
              title={skillOption.title}
              description={skillOption.description}
              examples={skillOption.examples}
              selected={skillOption.id === skill}
              faded={skill.length > 0 && skillOption.id !== skill}
            />
          ))}
        </div>
        <Button
          className={css.SubmitReferralButton}
          id="SignUpWithEmailButton"
          isLoading={referralLoader.loading}
          type="submit"
          disabled={!isSignedIn}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default ProactiveReferralForm;
