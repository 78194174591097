import React from "react";
import { useSelector } from "react-redux";

// Components
import {
  AuthLayout,
  FormMessage,
  OAuthButtons,
  SignUpWithEmail,
} from "components";

import { authActions } from "reducks/auth";
import { selectAppSettings } from "reducks/meta";
import { selectLoader } from "reducks/loaders";

// ROUTES
import { LOGIN_PATH } from "routes";

export const SignUpPage = () => {
  const appSettings = useSelector(selectAppSettings);
  const oauthLoader = useSelector(selectLoader(authActions.loginOAuth));

  // If we're on the vendor site, users shouldn't be able to sign up.
  if (appSettings.isVendorSite) return null;

  return (
    <AuthLayout
      formHeading="Sign up for Agility Connect"
      navButtonPath={LOGIN_PATH}
      navButtonText="Log In"
      navText="Already have an account?"
      sidebarHeadings={["Join our platform"]}
      sidebarVariant="secondary"
    >
      <>
        {oauthLoader.error && <FormMessage text={oauthLoader.error} error />}
        <OAuthButtons />
        <SignUpWithEmail />
      </>
    </AuthLayout>
  );
};
