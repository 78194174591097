import React, { ReactNode, useRef, useState } from "react";

import { animated, useSpring } from "react-spring";
import { Input } from "components";
import cn from "classnames";

import Button from "components/Button/Button";

import { ReactComponent as CheckSvg } from "images/check.svg";
import { ReactComponent as CopySvg } from "images/copy.svg";

import css from "./CopyLink.module.scss";

export interface CopyLinkProps {
  url: string;
  footerSlot?: ReactNode;
  className?: string;
}

const ButtonContent = ({ showCopied }: { showCopied: boolean }) => {
  const springStylesCopied = useSpring({
    opacity: showCopied ? 1 : 0,
    transform: showCopied ? "scale(1)" : "scale(2)",
    backgroundColor: showCopied ? "#13b57d" : "transparent",
    config: { duration: 200 },
  });
  const springStylesCopy = useSpring({
    opacity: showCopied ? 0 : 1,
    transform: showCopied ? "scale(0)" : "scale(1)",
    config: { duration: 150 },
  });
  return (
    <div>
      <animated.div style={springStylesCopy} className={css.CopyText}>
        <CopySvg className={css.CopyIcon} /> Copy Link
      </animated.div>
      <animated.div style={springStylesCopied} className={css.CopiedText}>
        <CheckSvg className={css.CopiedIcon} /> Copied
      </animated.div>
    </div>
  );
};

export const CopyLink = ({ url, footerSlot, className }: CopyLinkProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showCopied, setShowCopied] = useState(false);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowCopied(true);
    const { current: input } = inputRef;
    if (input) {
      input.select();
      input.setSelectionRange(0, 99999); // For mobile devices
      document.execCommand("copy");
      event.currentTarget.focus();
    }
    setTimeout(() => {
      setShowCopied(false);
    }, 3000);
  };

  return (
    <div className={className}>
      <div className={css.Wrapper}>
        <Input
          type="url"
          name={`copy-link-${url}`}
          value={url}
          className={css.Input}
          inputClassName={cn(
            css.InputElement,
            !!footerSlot && css.hasFooterSlot
          )}
          onChange={() => {}}
          ref={inputRef}
          readOnly
        />
        <Button
          variant="secondary"
          className={cn(css.Button, !!footerSlot && css.hasFooterSlot)}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleClick(e)
          }
          data-cy="copy-link-btn"
        >
          <ButtonContent showCopied={showCopied} />
        </Button>
      </div>
      {footerSlot && <div className={css.FooterSlotWrapper}>{footerSlot}</div>}
    </div>
  );
};
