import { Action, PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface JobFilterOption {
  id: number;
  name: string;
}

export interface CityOption {
  id: string;
  name: string;
}

export type JobFilterType = "industry" | "job_type" | "position_type";

export interface JobFilter {
  name: string;
  type: JobFilterType;
  options: Array<JobFilterOption>;
}

export interface CityFilter {
  name: string;
  type: "company__city";
  options: Array<CityOption>;
}

export interface AppMetaState {
  industry_types: JobFilter;
  job_types: JobFilter;
  position_types: JobFilter;
  cities: CityFilter;
  settings: {
    isVendorSite: boolean;
  };
}

export const initialAppMetaState: AppMetaState = {
  industry_types: {
    name: "",
    type: "industry",
    options: [],
  },
  job_types: {
    name: "",
    type: "job_type",
    options: [],
  },
  position_types: {
    name: "",
    type: "position_type",
    options: [],
  },
  cities: {
    name: "",
    type: "company__city",
    options: [],
  },
  settings: {
    isVendorSite: process.env.REACT_APP_IS_VENDOR_SITE === "1",
  },
};

const metaSlice = createSlice({
  name: "meta",
  initialState: initialAppMetaState,
  reducers: {
    fetchMetadata: (state, action: Action) => state,
    fetchMetadataFailure: (state, action: PayloadAction<string>) => state,
    fetchMetadataSuccess: (state, action: PayloadAction<AppMetaState>) => {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { actions: metaActions, reducer } = metaSlice;
