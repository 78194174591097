import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ACTIVE_REFERRALS_PATH,
  CANDIDATES_PATH,
  COMPLETE_REFERRALS_PATH,
} from "routes";
import { BackButton, Nav, ReferralsListItem } from "components";
import { referralsActions, selectReferral } from "reducks/referrals";
import { selectLoader } from "reducks/loaders";
import { selectUser } from "reducks/user";
import { useParams } from "react-router-dom";

import css from "./ReferralDetailPage.module.scss";

export const ReferralDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const referral = useSelector(selectReferral(id));
  const referralsLoader = useSelector(
    selectLoader(referralsActions.fetchReferrals)
  );
  const user = useSelector(selectUser);

  let backButtonTo = referral.is_active
    ? ACTIVE_REFERRALS_PATH
    : COMPLETE_REFERRALS_PATH;

  // If this is a recruiter, the back button should go back to the candidates
  // page
  const internalEmails = ["@agilitypartners.io"];
  const isInternalUser =
    user.email && internalEmails.some((email) => user.email.endsWith(email));
  if (isInternalUser) {
    backButtonTo = CANDIDATES_PATH;
  }

  // TODO: make a loading state
  const isLoading = useMemo(() => {
    return !referralsLoader.loaded && referral.id === 0;
  }, [referralsLoader.loaded, referral.id]);

  useEffect(() => {
    dispatch(referralsActions.fetchReferrals());
  }, [dispatch]);

  return (
    <div data-cy="ReferralDetailPage">
      <Nav subNavSlot={<BackButton to={backButtonTo} />} />
      <div className={css.ContentWrapper}>
        <ReferralsListItem {...referral} isDetailPage isLoading={isLoading} />
      </div>
    </div>
  );
};
