import React from "react";
import cn from "classnames";
import css from "./JobsDetailPage.module.scss";

export const LoadingJobDetail = () => {
  return (
    <>
      <div
        data-cy="LoadingJobDetail"
        className={cn(css.PageHeading, css.LoadingItem)}
      >
        Loading
      </div>
      <div className={cn(css.Details, css.LoadingItem)}>Loading</div>
      <div className={cn(css.Metrics, css.LoadingItem)}>Loading</div>

      <div className={cn(css.SectionHeading, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>

      <div className={cn(css.SectionHeading, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>

      <div className={cn(css.SectionHeading, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
      <div className={cn(css.TextSection, css.LoadingItem)}>Loading</div>
    </>
  );
};
