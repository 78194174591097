import React, { FunctionComponent, SVGProps } from "react";

import { ReactComponent as FinancialServices } from "images/industries/financial_services.svg";
import { ReactComponent as Healthcare } from "images/industries/healthcare.svg";
import { ReactComponent as Insurance } from "images/industries/insurance.svg";
import { ReactComponent as Logistics } from "images/industries/logistics.svg";
import { ReactComponent as Manufacturing } from "images/industries/manufacturing.svg";
import { ReactComponent as Marketing } from "images/industries/marketing.svg";
import { ReactComponent as Other } from "images/industries/other.svg";
import { ReactComponent as Restaurant } from "images/industries/restaurant.svg";
import { ReactComponent as Retail } from "images/industries/retail.svg";
import { ReactComponent as Technology } from "images/industries/technology.svg";
import { ReactComponent as Utilities } from "images/industries/utilities.svg";

import css from "./Industry.module.scss";

interface IndustryProps {
  industry: string;
}

type IconToIndustry = {
  [key: string]: FunctionComponent<SVGProps<SVGSVGElement>>;
};

const iconToIndustry: IconToIndustry = {
  Finance: FinancialServices,
  Healthcare,
  Insurance,
  Logistics,
  Marketing,
  Manufacturing,
  Other,
  Restaurant,
  Retail,
  "Technology Services": Technology,
  Utilities,
};

const Industry = ({ industry }: IndustryProps) => {
  const Icon = iconToIndustry[industry];
  return (
    <div className={css.Industry} data-icon={industry}>
      {Icon && <Icon className={css.Icon} />}
      {industry}
    </div>
  );
};

export default Industry;
