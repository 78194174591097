import React from "react";
import cn from "classnames";

import css from "./Text.module.scss";

interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  allCaps?: boolean;
  background?: "transparent" | "white" | "green" | "gray";
  color?: "light" | "default" | "white" | "green" | "blue" | "black";
  letterSpacing?: "default" | "large";
  size?: "small" | "medium" | "large" | "xlarge";
  tag?: "p" | "span";
  weight?: 700 | 600 | undefined;
}

const Text = ({
  background = "transparent",
  className,
  color = "default",
  letterSpacing = "default",
  size = "medium",
  tag: Tag = "p",
  weight = undefined,
  allCaps = false,
  children,
  ...rest
}: TextProps) => (
  <Tag
    className={cn(
      {
        [css[`bg-${background}`]]: true,
        [css[`color-${color}`]]: true,
        [css[`size-${size}`]]: true,
        [css[`weight-${weight}`]]: !!weight,
        [css[`letter-spacing-${letterSpacing}`]]: !!letterSpacing,
        [css.allCaps]: allCaps,
      },
      className
    )}
    {...rest}
  >
    {children}
  </Tag>
);

export default Text;
