import React, { ReactNode, useMemo } from "react";
import { compareDesc, format } from "date-fns";
import { Link } from "react-router-dom";
import cn from "classnames";
import numeral from "numeral";

import {
  Candidate,
  completedNotHiredStatuses,
  dateFormat,
  hiredStatuses,
  referralStateMap,
} from "reducks/referrals";
import { Heading, Panel, Text } from "components";
import { numberWithCommas } from "utilities/formatting";

import { ReferralListItemLoading } from "./loading";
import { ReferralsDetail } from "./ReferralsDetail";
import { ReferralsSummary } from "./ReferralsSummary";
import { RewardInfo } from "./RewardInfo";
import css from "./ReferralsListItem.module.scss";

interface ReferralsListItemProps extends Candidate {
  isDetailPage?: boolean;
  isLoading?: boolean;
  demoMode?: boolean;
}

const ReferralsListItem = ({
  first_name,
  last_name,
  email,
  phone,
  date_created,
  referral_set,
  id,
  isDetailPage = false,
  isLoading = false,
  demoMode = false,
}: ReferralsListItemProps) => {
  const hiredReferral = useMemo(
    () => referral_set.find((r) => hiredStatuses.includes(r.status)),
    [referral_set]
  );
  // Calculates the potential reward for all referrals on the candidate.
  // Filters out referrals that have a completed but not hired status.
  const potentialReward = useMemo(() => {
    if (hiredReferral) {
      return numberWithCommas(hiredReferral.job.reward);
    }
    const rewardNumbers = referral_set
      .filter((rs) => !completedNotHiredStatuses.includes(rs.status))
      .map((rs) => rs.job.reward)
      .sort((a, b) => a - b);
    if (rewardNumbers.length === 0) {
      return "0";
    }
    const lowReward = rewardNumbers[0];
    const highReward = rewardNumbers[rewardNumbers.length - 1];
    if (lowReward === highReward) {
      return numberWithCommas(rewardNumbers[0]);
    }
    const numeralFormat = "0[.]0a";
    return `${numeral(lowReward).format(numeralFormat)} - ${numeral(
      highReward
    ).format(numeralFormat)}`;
  }, [referral_set, hiredReferral]);

  const status = useMemo(() => {
    // Sorts statuses from highest to lowest
    const sortedStatuses = referral_set
      .map((r) => r.status)
      .sort((a, b) => b - a);
    let displayStatus = sortedStatuses[0];
    // Loop through statuses until we get one that isn't a completed but
    // not hired status or we reach the end of the array
    sortedStatuses.some((s, i) => {
      if (
        i !== sortedStatuses.length - 1 &&
        completedNotHiredStatuses.includes(s)
      ) {
        displayStatus = sortedStatuses[i + 1];
        return false;
      }
      return true;
    });
    return referralStateMap[displayStatus] || "Unknown";
  }, [referral_set]);

  const lastUpdatedDateString = useMemo(() => {
    const lastUpdatedDate =
      referral_set.map((r) => new Date(r.last_updated)).sort(compareDesc)[0] ||
      new Date();
    return format(lastUpdatedDate, dateFormat);
  }, [referral_set]);

  const payoutAmount = useMemo(() => {
    const paidReferral = referral_set.find((r) => r.status === 35);
    if (paidReferral) {
      return numberWithCommas(paidReferral.job.reward);
    }
    return null;
  }, [referral_set]);
  const isPaidOut = !!payoutAmount;

  // const InnerWrapper = isDetailPage ? "div" : Link;

  const InnerWrapper = ({
    to,
    ...rest
  }: {
    to: string;
    className: string;
    children: ReactNode;
  }) => {
    if (isDetailPage) {
      return <div {...rest} />;
    }
    return <Link to={to} {...rest} />;
  };

  if (isLoading) {
    return <ReferralListItemLoading />;
  }

  return (
    <Panel
      className={cn(css.ReferralsListItem, {
        [css.isDetailPage]: isDetailPage,
        [css.isDemo]: demoMode,
      })}
      data-cy="ReferralsListItem"
    >
      <InnerWrapper to={`/referral/${id}`} className={css.WrapperLink}>
        <div className={css.TopSection}>
          <div>
            <Heading variant="h1" align="left" className={css.Name}>
              {first_name} {last_name}
            </Heading>
            <div>
              <Text tag="span" color="light" className={css.Email}>
                {email}
              </Text>
              <Text tag="span" color="light" className={css.Phone}>
                {phone}
              </Text>
            </div>
            <div className={css.StatusAndDateWrapper}>
              <div className={css.StatusWrapper}>
                <Text tag="span" color="light">
                  Status
                </Text>
                <Text
                  tag="span"
                  size="large"
                  className={css.Status}
                  data-cy="ReferralListItem-Status"
                >
                  {status}
                </Text>
              </div>
              <div className={css.DateWrapper}>
                <Text tag="span" color="light">
                  Submitted On
                </Text>
                <Text
                  tag="span"
                  size="large"
                  className={css.Date}
                  data-cy="ReferralListItem-Date"
                >
                  {format(new Date(date_created), dateFormat)}
                </Text>
              </div>
            </div>
          </div>
          <RewardInfo
            isPaidOut={isPaidOut}
            potentialReward={potentialReward}
            payoutAmount={payoutAmount}
          />
        </div>
        {isDetailPage ? (
          <ReferralsDetail
            referral_set={referral_set}
            hiredReferral={hiredReferral}
          />
        ) : (
          <ReferralsSummary
            referral_set={referral_set}
            hiredReferral={hiredReferral}
          />
        )}
        {!isDetailPage && (
          <Text tag="span" color="light" className={css.LastUpdated}>
            Last updated {lastUpdatedDateString}
          </Text>
        )}
      </InnerWrapper>
    </Panel>
  );
};

export default ReferralsListItem;
