import React, { useState } from "react";
import cn from "classnames";

import { Heading, Text } from "components";
import { referralStateMap } from "reducks/referrals";

import { ProcessItem } from "./ProcessItem";
import css from "./ReferralsProcessOverview.module.scss";

const ReferralsProcessOverview = ({ className }: { className?: string }) => {
  const [openStep, setOpenStep] = useState("");
  const handleClick = (id: string) => {
    if (id === openStep) {
      setOpenStep("");
    } else {
      setOpenStep(id);
    }
  };
  return (
    <div
      className={cn(css.ReferralsProcessOverview, className)}
      data-cy="ReferralsProcessOverview"
    >
      <Heading variant="h1" align="left" className={css.OverviewHeading}>
        Process Overview
      </Heading>
      <ProcessItem
        id={referralStateMap[5]}
        heading={referralStateMap[5]}
        onClick={handleClick}
        openStep={openStep}
      >
        <Text>
          We’ve received your referral! We’ll reach out to your candidate, learn
          more about them and their goals, and match them to potential career
          opportunities.
        </Text>
      </ProcessItem>
      <ProcessItem
        id={referralStateMap[10]}
        heading={referralStateMap[10]}
        onClick={handleClick}
        openStep={openStep}
      >
        <Text>
          We&apos;re actively working with your referral to discuss career
          opportunities.
        </Text>
      </ProcessItem>
      <ProcessItem
        id={referralStateMap[15]}
        heading={referralStateMap[15]}
        onClick={handleClick}
        openStep={openStep}
      >
        <Text>
          We presented your referral to the client and we’re waiting on feedback
          on an interview.
        </Text>
      </ProcessItem>
      <ProcessItem
        id={referralStateMap[20]}
        heading={referralStateMap[20]}
        onClick={handleClick}
        openStep={openStep}
      >
        <Text>
          Your referral is interviewing with the client! There could be multiple
          interviews in this process so stay tuned.
        </Text>
      </ProcessItem>
      <ProcessItem
        id={referralStateMap[28]}
        heading={referralStateMap[28]}
        onClick={handleClick}
        openStep={openStep}
      >
        <Text>
          Your referral just received an offer! If your referral accepts the
          offer, we will contact you to discuss the reward.
        </Text>
      </ProcessItem>
      <ProcessItem
        id={referralStateMap[30]}
        heading={referralStateMap[30]}
        onClick={handleClick}
        openStep={openStep}
      >
        <Text>
          Your referral has accepted the offer! Once your referral has been with
          the company 60 days, you will receive your referral reward. Our team
          will be in touch about where to send your payment.
        </Text>
      </ProcessItem>
      <ProcessItem
        id={referralStateMap[35]}
        heading={referralStateMap[35]}
        onClick={handleClick}
        openStep={openStep}
      >
        <Text>
          Your referral has been with the company for 60 days and your reward
          has been paid.
        </Text>
      </ProcessItem>
      <ProcessItem
        id={referralStateMap[25]}
        heading={referralStateMap[25]}
        onClick={handleClick}
        openStep={openStep}
      >
        <Text>
          Unfortunately, the position wasn’t a fit for your referral, but our
          team will continue to stay in contact and we will update you if a
          better fit becomes available.
        </Text>
      </ProcessItem>
    </div>
  );
};

export default ReferralsProcessOverview;
