import React from "react";

import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button, CopyLink, Heading, Modal, Text } from "components";

import { UserState, selectIsUserLoggedIn } from "reducks/user";
import { Job } from "reducks/jobs";
import { numberWithCommas } from "utilities/formatting";

import { SIGN_UP_PATH, getLoginPath } from "routes";
import { ReactComponent as EmailSvg } from "images/email.svg";
import { ReactComponent as FacebookSvg } from "images/facebook.svg";
import { ReactComponent as LinkedInSvg } from "images/linkedin.svg";
import { ReactComponent as ShareIllustration } from "images/share-illustration.svg";
import css from "./JobsDetailPage.module.scss";

export const ShareJobModal = ({
  isOpen,
  handleDismiss,
  job,
  user,
}: {
  isOpen: boolean;
  handleDismiss: () => void;
  job: Job;
  user: UserState;
}) => {
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const { pathname } = useLocation();
  const referralLink = `https://agilityconnect.io/shared/${user.publicId}/${job.id}/`;
  const linkTitle = `Needed: ${job.name} at ${job.company.industry} in ${job.company.city}, ${job.company.state}`;
  const emailLink = `mailto:?subject=${linkTitle}&body=Check out this job I found for you: ${referralLink}`;
  const fbLink = `https://www.facebook.com/sharer/sharer.php?u=${referralLink}`;
  const linkedInLink = `https://www.linkedin.com/shareArticle?mini=true&url=${referralLink}&title=${linkTitle}`;

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={handleDismiss}
      allowPinchZoom
      includeCloseButton
      title="Share this Job"
      padded
      data-cy="share-job-modal"
    >
      <ShareIllustration className={css.ModalShareIllustration} />
      <Heading variant="h2" align="center" className={css.ModalJobName}>
        {job.name}
      </Heading>
      <div className={css.ModalRewardBox}>
        <Text color="green" size="large" weight={700}>
          Earn ${numberWithCommas(job.reward)} when your friend applies and is
          placed in this position.
        </Text>
      </div>

      {isLoggedIn ? (
        <>
          <CopyLink
            url={referralLink}
            className={css.CopyLink}
            footerSlot={
              <Text className={css.ModalShareMessage}>
                This is your custom job sharing link. If someone applies from
                your link and is hired, you&apos;ll earn{" "}
                <Text color="green" tag="span">{`$${numberWithCommas(
                  job.reward
                )}`}</Text>
                . Copy and paste it wherever you want, or click a button below
                to easily share it with your network.
              </Text>
            }
          />
          <a href={fbLink} target="_blank" rel="noopener noreferrer">
            <Button
              className={css.FacebookButton}
              variant="secondary"
              tabIndex={-1}
            >
              <FacebookSvg className={css.FacebookSvg} />
              Share via Facebook
            </Button>
          </a>
          <a href={linkedInLink} target="_blank" rel="noopener noreferrer">
            <Button
              className={css.LinkedInButton}
              variant="secondary"
              tabIndex={-1}
            >
              <LinkedInSvg className={css.LinkedInSvg} />
              Share via LinkedIn
            </Button>
          </a>
          <a href={emailLink} target="_blank" rel="noopener noreferrer">
            <Button
              className={css.EmailButton}
              variant="secondary"
              tabIndex={-1}
            >
              <EmailSvg className={css.EmailSvg} />
              Share via Email
            </Button>
          </a>
        </>
      ) : (
        <>
          <div className={css.ModalUnauthenticatedBox}>
            <Text weight={700} color="black">
              You must be signed in to share/refer this job.
            </Text>
            <Button to={getLoginPath(pathname)}>Log In</Button>
          </div>
          <Text color="black" size="small" className={css.ModalSignupText}>
            Not a member? <Link to={SIGN_UP_PATH}>Sign up now</Link>.
          </Text>
        </>
      )}
    </Modal>
  );
};
