import React, { ReactElement, useCallback, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { authActions, selectAccessToken } from "reducks/auth";
import { useDispatch, useSelector } from "react-redux";
import { batchActions } from "redux-batched-actions";

import { metaActions, selectAppSettings } from "reducks/meta";
import { selectLoader } from "reducks/loaders";
import { selectUser } from "reducks/user";

import { AgilityLoader } from "components";
import { getLoginPath } from "routes";

export const PageWithAuth = ({
  children,
  authRequired = false,
}: {
  children: ReactElement;
  authRequired?: boolean;
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const appSettings = useSelector(selectAppSettings);

  useEffect(() => {
    // This will run:
    // - the first time visiting a page within RequireAuth
    // - any time the user is coming from a page outside RequireAuth
    dispatch(
      batchActions([authActions.checkAuth(), authActions.pollCheckAuth()])
    );

    // Stop polling when the user logs out or goes to an unauthenticated page
    return () => {
      dispatch(authActions.stopPollCheckAuth());
    };
  }, [dispatch]);

  // Anything we need to run the first time a user hits an authenticated
  // route
  const initialize = useCallback(() => {
    // To do anything meaningful in the authenticated app, we'll typically
    // need some metadata about the app, so this seems to be the wisest place
    // to put this.
    dispatch(metaActions.fetchMetadata());
  }, [dispatch]);
  useEffect(() => {
    initialize();
  }, [initialize]);

  const checkAuthLoader = useSelector(selectLoader(authActions.checkAuth));
  const fetchNewTokenLoader = useSelector(
    selectLoader(authActions.fetchNewToken)
  );
  const metadataLoader = useSelector(selectLoader(metaActions.fetchMetadata));

  const accessToken = useSelector(selectAccessToken);
  const user = useSelector(selectUser);

  // The page is considered loading if:
  // - We're getting a new access token
  // - We're getting user details
  // - We're getting app meta info
  const isAuthLoading =
    fetchNewTokenLoader.loading ||
    (!checkAuthLoader.loaded && !checkAuthLoader.loading);
  const isMetadataLoading =
    metadataLoader.loading ||
    (!metadataLoader.loading && !metadataLoader.loaded);
  const isPageLoading =
    isAuthLoading === true ||
    (authRequired && user.id === null) ||
    isMetadataLoading === true;

  // If we don't have an access token, that means the user isn't logged in.
  // Send them to the log in page.
  if (authRequired && !isAuthLoading && !accessToken) {
    return <Redirect to={getLoginPath(pathname)} />;
  }

  return isPageLoading ? <AgilityLoader /> : children;
};
