export const validateEmail = (email: string, isOwnEmail = true): string => {
  if (email.length === 0) {
    return `${isOwnEmail ? "Your email" : "Email"} can't be blank.`;
    // the regular expression below is a simplified version of https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Validation
  }
  if (/^\w+([.\-+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(email) === false) {
    return "That's not a valid email address.";
  }
  return "";
};

export const validatePassword = (password: string): string => {
  if (password.length === 0) {
    return "Your password can't be blank.";
  }
  return "";
};
