import React from "react";
import cn from "classnames";

// Assets
import acSvgWhite from "images/ac-logo-white.svg";
import homeHero from "images/hero-home@2x.png";

// Styles
import css from "./AuthSidebar.module.scss";

interface AuthSidebarProps {
  headings: string[];
  variant?: "primary" | "secondary";
}

const AuthSidebar = ({ headings, variant = "primary" }: AuthSidebarProps) => {
  const url: string =
    process.env.REACT_APP_SHARE_URL || "https://agilityconnect.io";
  return (
    <div className={cn(css.AuthSidebar, css[`variant-${variant}`])}>
      <div>
        <a href={url}>
          <img
            className={css.AgilityLogo}
            src={acSvgWhite}
            alt="Agility Connect"
          />
        </a>
        <h3 className={css.AuthSidebarTagline}>
          {headings.map((h) => (
            <div key={h}>{h}</div>
          ))}
        </h3>
        <div className={css.AuthSidebarImage}>
          <img src={homeHero} alt="Agility Connect" />
        </div>
      </div>
    </div>
  );
};

export default AuthSidebar;
