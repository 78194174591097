import React from "react";

import { Link } from "react-router-dom";
import cn from "classnames";

import { BLOG_PATH, JOBS_PATH, SIGN_UP_PATH, getLoginPath } from "routes";
import { ButtonDropdown, Nav } from "components";
import { useWindowSize } from "utilities/hooks";

import { ReactComponent as HeroArc } from "images/marketing/hero-arc.svg";
import { ReactComponent as HeroArcWhite } from "images/marketing/hero-arc-white.svg";

import css from "./MarketingHero.module.scss";

interface MarketingHeroProps {
  children: React.ReactElement;
  bgImage?: string;
  useWhiteArc?: boolean;
  style?: React.CSSProperties;
}

const HamburgerIcon = () => {
  return (
    <div className={css.HamburgerIcon}>
      <span />
      <span />
      <span />
    </div>
  );
};

const MarketingHero = ({
  children,
  bgImage,
  useWhiteArc,
  style = {},
}: MarketingHeroProps) => {
  const { isBigMobile } = useWindowSize();

  return (
    <>
      <Nav />
      <section
        className={cn({
          [css.Hero]: true,
        })}
        data-cy="marketing-index-hero"
        style={{
          backgroundImage: `url(${bgImage})`,
          ...style,
        }}
      >
        {children}
        {useWhiteArc ? (
          <HeroArcWhite className={css.HeroArc} />
        ) : (
          <HeroArc className={css.HeroArc} />
        )}
      </section>
    </>
  );
};

export default MarketingHero;
