import { useCallback, useEffect, useState } from "react";
import throttle from "lodash/throttle";

const MOBILE_SIZE = 480;
const BIG_MOBILE_SIZE = 640;
const TABLET_SIZE = 768;
const DESKTOP_SIZE = 992;
const BIG_SCREEN_SIZE = 1360;

export const useWindowSize = () => {
  const hasWindow = typeof window !== "undefined";

  const getWindowSize = useCallback(() => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }, [hasWindow]);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    let handleResize = () => {};
    if (hasWindow) {
      handleResize = throttle(() => {
        setWindowSize(getWindowSize());
      }, 150);

      window.addEventListener("resize", handleResize);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [hasWindow, getWindowSize]);

  const { width } = windowSize;
  const isMobile = typeof width === "number" && width >= MOBILE_SIZE;
  const isBigMobile = typeof width === "number" && width >= BIG_MOBILE_SIZE;
  const isTablet = typeof width === "number" && width >= TABLET_SIZE;
  const isDesktop = typeof width === "number" && width >= DESKTOP_SIZE;
  const isBigScreen = typeof width === "number" && width >= BIG_SCREEN_SIZE;

  return {
    ...windowSize,
    isBigScreen,
    isDesktop,
    isTablet,
    isBigMobile,
    isMobile,
  };
};
