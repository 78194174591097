import * as Sentry from "@sentry/react";
import { batchDispatchMiddleware, enableBatching } from "redux-batched-actions";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { RootState, rootReducer } from "./rootReducer";
import { initialAuthState, initialForgotPasswordState } from "./auth";
import { initialAppMetaState } from "./meta";
import { initialCacheState } from "./cache";
import { initialJobsFiltersState } from "./jobsFilters";
import { initialJobsState } from "./jobs";
import { initialLoadersState } from "./loaders";
import { initialReferralsState } from "./referrals";
import { initialUserState } from "./user";

export const initialState: RootState = {
  auth: initialAuthState,
  cache: initialCacheState,
  forgotPassword: initialForgotPasswordState,
  jobs: initialJobsState,
  jobsFilters: initialJobsFiltersState,
  loaders: initialLoadersState,
  meta: initialAppMetaState,
  referrals: initialReferralsState,
  user: initialUserState,
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: enableBatching(rootReducer),
  preloadedState: initialState,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [batchDispatchMiddleware, sagaMiddleware],
  enhancers: [sentryReduxEnhancer],
});

// Cypress stuff
declare global {
  interface Window {
    Cypress: any;
    store: any;
  }
}
if (window.Cypress) {
  window.store = store;
}
