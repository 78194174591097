import React from "react";

import { Nav } from "components";
import { useHistory } from "react-router-dom";

// Components
import Button from "components/Button/Button";

// Assets
import { ReactComponent as PageNotFoundIllustration } from "images/page-not-found-illustration.svg";

// Styles
import css from "./PageNotFoundPage.module.scss";

const PageNotFoundPage = () => {
  const history = useHistory();

  return (
    <div data-cy="PageNotFoundPage">
      <Nav is404 />
      <div className={css.Content}>
        <div className={css.IllustrationContainer}>
          <PageNotFoundIllustration />
        </div>
        <h1 className={css.Heading}>Page Not Found</h1>
        <p className={css.Body}>
          The page you’re looking for is missing or doesn’t exist.
        </p>
        <Button
          data-cy="PageNotFoundPageBackButton"
          onClick={() => history.goBack()}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default PageNotFoundPage;
