import { RootState } from "reducks/rootReducer";
import { createSelector } from "reselect";

export const selectMeta = (state: RootState) => state.meta;

export const selectJobTypes = (state: RootState) => state.meta.job_types;

export const selectJobTypeNameById = (id: number) =>
  createSelector(selectJobTypes, (jobTypes) => {
    const matchingJobType = jobTypes.options.find((jt) => jt.id === id);
    return matchingJobType?.name || "";
  });

export const selectAppSettings = (state: RootState) => state.meta.settings;
