import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { JobFilterType } from "reducks/meta";

export type NumericalFilters = {
  [key in JobFilterType]: number[];
};

export interface JobsFilters extends NumericalFilters {
  city: string[];
  query: string;
  url: string;
}

export interface JobsFiltersState extends JobsFilters {
  initialized: boolean;
}

export interface ChangeJobsFilterPayload {
  filterType: JobFilterType;
  filterId: number;
}

export const initialJobsFiltersState: JobsFiltersState = {
  job_type: [],
  industry: [],
  position_type: [],
  city: [],
  query: "",
  url: "",
  initialized: false,
};

const jobsFiltersSlice = createSlice({
  name: "jobsFilters",
  initialState: initialJobsFiltersState,
  reducers: {
    setJobsFilters: (state, action: PayloadAction<JobsFilters>) => {
      return { ...action.payload, initialized: true };
    },
  },
});

export const { actions: jobsFiltersActions, reducer } = jobsFiltersSlice;
