import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const DEFAULT_LIMIT = 10;

export interface JobBase {
  id: number;
  name: string;
  company: {
    veiled_name?: string;
    name?: string;
    city: string;
    state: string;
    industry: string;
  };
  num_positions_open: number;
  reward: number;
  job_type: number;
  position_type: {
    id: number;
    name: string;
  };
  posted_date: string;
  is_priority: boolean;
  salary_low?: number;
  salary_high?: number;
  c2c_rate?: string;
}

export interface Job extends JobBase {
  description: string;
  qualifications: string;
  benefits: string;
  status: number;
}

export interface JobsState {
  limit: number;
  offset: number;
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<JobBase>;
  map: {
    [id: string]: Job;
  };
}

export interface FetchJobsPayload {
  includeAuth: boolean;
  limit?: number;
  offset?: number;
  job_type?: number[];
  position_type?: number[];
  industry?: number[];
  city: string[];
  company?: string;
  query?: string;
  append: boolean;
}

export interface FetchJobFailurePayload {
  id: string;
  message: string;
}

// Refer Candidate
export interface FileData {
  name: string;
  file: string | null;
}

export interface ReferCandidatePayload {
  jobId: number;
  userId?: number;
  publicUserId?: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  resume?: FileData | null;
  ics_form?: FileData | null;
  isApplicant: boolean;
  isFromVendorSite?: boolean;
  linkedIn?: string;
}

export const initialJobsState: JobsState = {
  limit: DEFAULT_LIMIT,
  offset: 0,
  count: 0,
  next: null,
  previous: null,
  results: [],
  map: {},
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState: initialJobsState,
  reducers: {
    fetchJobs: (state, action: PayloadAction<FetchJobsPayload>) => state,
    fetchJobsSuccess: (state, action: PayloadAction<Partial<JobsState>>) => {
      const payload = action.payload || {
        limit: DEFAULT_LIMIT,
        offset: 0,
      };
      return {
        ...state,
        ...payload,
      };
    },
    fetchJobsFailure: (state, action: PayloadAction<string>) => state,
    fetchMoreJobs: (state, action: PayloadAction<FetchJobsPayload>) => {
      return {
        ...state,
        offset: action.payload.offset as number,
      };
    },
    fetchMoreJobsSuccess: (
      state,
      action: PayloadAction<Partial<JobsState>>
    ) => {
      return {
        ...state,
        ...action.payload,
        results: [...state.results, ...(action.payload?.results || [])],
      };
    },
    fetchJob: (
      state,
      action: PayloadAction<{ jobId: string; includeAuth: boolean }>
    ) => state,
    fetchJobFailure: (state, action: PayloadAction<FetchJobFailurePayload>) =>
      state,
    fetchJobSuccess: (state, action: PayloadAction<Job>) => {
      const { payload } = action;
      state.map[payload.id] = payload;
    },
    referCandidate: (state, action: PayloadAction<ReferCandidatePayload>) =>
      state,
    referCandidateFailure: (state, action: PayloadAction<string>) => state,
    referCandidateSuccess: (state) => state,
    referCandidateReset: (state) => state,
  },
});

export const { actions: jobsActions, reducer } = jobsSlice;
