import { Job } from "reducks/jobs";
import { roundedSalary } from "./formatting";

export const getSalaryRange = (job: Job) => {
  const { salary_high: salaryHigh, salary_low: salaryLow } = job;
  if (salaryHigh && salaryLow) {
    return `$${roundedSalary(salaryLow)} - $${roundedSalary(salaryHigh)}`;
  }

  if (salaryLow) {
    return `$${roundedSalary(salaryLow)}`;
  }

  if (salaryHigh) {
    return `$${roundedSalary(salaryHigh)}`;
  }

  return "TBD";
};
