import React from "react";

import { Text } from "components";
import css from "./RuleWithText.module.scss";

interface RuleWithTextProps {
  text: string;
}

const RuleWithText = ({ text }: RuleWithTextProps) => (
  <div className={css.RuleWithText}>
    <Text background="white" color="light">
      {text}
    </Text>
  </div>
);

export default RuleWithText;
