import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cn from "classnames";
import { format } from "date-fns";

import { Nav, ReferralsUnauthenticated, Text } from "components";

import {
  Candidate,
  Referral,
  completedNotHiredStatuses,
  hiredStatuses,
  lastContactTimeframes,
  referralStateMap,
  referralsActions,
  selectReferrals,
} from "reducks/referrals";
import { selectIsUserLoggedIn, selectUser } from "reducks/user";
import { selectLoader } from "reducks/loaders";

import { ReactComponent as BriefcaseSvg } from "images/briefcase.svg";
import { getSpread } from "utilities/getSpread";
import { numberWithCommas } from "utilities/formatting";

import css from "./RecruiterCandidatesPage.module.scss";

export const RecruiterCandidatesPage = () => {
  // Get the referrals from redux
  const dispatch = useDispatch();
  const loader = useSelector(
    selectLoader(referralsActions.fetchCandidatesForRecruiter)
  );
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const user = useSelector(selectUser);
  const referralsState = useSelector(selectReferrals);

  // setup some loader variables
  const isLoading = useMemo(() => {
    return (
      loader.loading || (isUserLoggedIn && !loader.loaded && !loader.loading)
    );
  }, [loader, isUserLoggedIn]);

  const error = useMemo(() => {
    if (!loader.loading) {
      return loader.error;
    }
  }, [loader]);

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(
        referralsActions.fetchCandidatesForRecruiter({
          recruiter: user.email as string,
        })
      );
    }
  }, [isUserLoggedIn, user]);

  const [candidates, setCandidates] = useState({} as { [key: string]: any[] });

  const getLatestStatus = (candidate: any) => {
    // Sorts statuses from highest to lowest
    const sortedStatuses = candidate.referral_set
      .map((r: Referral) => r.status)
      .sort((a: number, b: number) => b - a);

    let displayStatus = sortedStatuses[0];

    // Loop through statuses until we get one that isn't a completed but
    // not hired status or we reach the end of the array
    sortedStatuses.some((s: number, i: number) => {
      if (
        i !== sortedStatuses.length - 1 &&
        completedNotHiredStatuses.includes(s)
      ) {
        displayStatus = sortedStatuses[i + 1];
        return false;
      }
      return true;
    });

    return referralStateMap[displayStatus] || "Unknown";
  };

  const [globalSpread, setGlobalSpread] = useState(0);

  // Update the candidates state when the referrals state changes
  useEffect(() => {
    setCandidates({});
    const { results } = referralsState;
    const finalCandidates = {} as { [key: string]: Candidate[] };
    let spread = 0;

    results.forEach((candidate) => {
      const latestStatus = getLatestStatus(candidate);

      if (!finalCandidates[latestStatus]) {
        finalCandidates[latestStatus] = [];
      }

      finalCandidates[latestStatus].push(candidate);

      const candidateSpread = candidate.referral_set.map((r) => getSpread(r));
      const maxSpread = Math.max(...candidateSpread, 0);
      spread += maxSpread;
    });

    setCandidates(finalCandidates);
    setGlobalSpread(spread);
  }, [referralsState.count, referralsState.results]);

  const renderBucket = (status: string) => {
    if (!candidates[status]) {
      return (
        <div className={css.column}>
          <div key={status} className={css.bucket}>
            <div className={css.bucketTop}>
              <div className={css.bucketCount}>0</div>
              <div className={css.bucketTitle}>{status}</div>
            </div>
          </div>
          <ul className={css.bucketCandidates} />
        </div>
      );
    }

    // Get spread for all of the candidates in this bucket
    const spreadForBucket = candidates[status].map((c) => {
      const referrals = c.referral_set;
      const spreads = referrals.map((r: Referral) => getSpread(r));
      const maxSpread = Math.max(...spreads, 0);
      return maxSpread;
    });
    const totalSpread = spreadForBucket.reduce(
      (a: number, b: number) => a + b,
      0
    );

    return (
      <div className={css.column}>
        <div key={status} className={css.bucket}>
          <div className={css.bucketTop}>
            <div className={css.bucketCount}>
              {candidates[status]?.length || 0}
            </div>
            <div className={css.bucketTitle}>{status}</div>
            <div className={css.bucketSpread}>
              <span className={css.bucketSpreadTitle}>
                Potential Commission
              </span>
              ${numberWithCommas(parseInt(totalSpread.toFixed(0), 10))}
            </div>
          </div>
          <ul className={css.bucketCandidates}>
            {candidates[status].map((candidate: Candidate) => {
              const lastUpdated = format(
                new Date(candidate.last_updated),
                "MMM d, yyyy"
              );
              const referredOn = format(
                new Date(candidate.date_created),
                "MMM d, yyyy"
              );

              const candidateWasHired = candidate.referral_set.find((r) =>
                hiredStatuses.includes(r.status)
              );
              const daysSinceLastUpdated = Math.floor(
                (new Date().getTime() -
                  new Date(candidate.last_updated).getTime()) /
                  (1000 * 60 * 60 * 24)
              );
              const candidateTimeFrame = lastContactTimeframes[status];
              let candidateTimeStatus = null;
              if (candidateTimeFrame) {
                const greenTime = candidateTimeFrame[0];
                const redTime = candidateTimeFrame[1];

                if (daysSinceLastUpdated <= greenTime) {
                  candidateTimeStatus = "green";
                } else if (daysSinceLastUpdated >= redTime) {
                  candidateTimeStatus = "red";
                } else {
                  candidateTimeStatus = "yellow";
                }
              }

              const spreads = candidate.referral_set.map((r) => getSpread(r));
              // Pick the biggest one
              const spread = Math.max(...spreads, 0);

              return (
                <li
                  key={candidate.id}
                  className={cn(
                    css.candidate,
                    candidateTimeStatus &&
                      css[`candidateTime-${candidateTimeStatus}`]
                  )}
                >
                  <div className={css.candidateHeader}>
                    <div className={css.candidateName}>
                      {candidate.first_name} {candidate.last_name}
                    </div>
                    {candidate.email && (
                      <div className={css.candidateContact}>
                        {candidate.email}
                      </div>
                    )}
                    {candidate.phone && (
                      <div className={css.candidateContact}>
                        {candidate.phone.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "($1) $2-$3"
                        )}
                      </div>
                    )}
                    {spread > 0 && (
                      <div className={css.candidateSpread}>
                        <span className={css.candidateSpreadBox}>
                          ${numberWithCommas(parseInt(spread.toFixed(0), 10))}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={css.candidateJobs}>
                    <Text
                      tag="span"
                      color="light"
                      className={css.referredToLabel}
                    >
                      Referred to
                    </Text>
                    {candidate.referral_set.map((r) => {
                      const otherReferralHired =
                        !!candidateWasHired &&
                        !hiredStatuses.includes(r.status);
                      const isCompletedNotHired =
                        completedNotHiredStatuses.includes(r.status) ||
                        otherReferralHired;
                      const isHired = hiredStatuses.includes(r.status);

                      return (
                        <div
                          key={`${r.job.name}-${r.job.company.name}`}
                          className={cn(css.JobWrapper, {
                            [css.CompletedNotHired]: isCompletedNotHired,
                          })}
                          data-cy="ReferralsList-JobItem"
                        >
                          <BriefcaseSvg className={css.BriefcaseSvg} />
                          {/* <Text
                                      tag="span"
                                      className={css.ReferralReward}
                                      color={
                                        isCompletedNotHired ? "white" : "green"
                                      }
                                      background={
                                        isCompletedNotHired ? "gray" : "green"
                                      }
                                      size="large"
                                    >
                                      ${numberWithCommas(r.job.reward)}
                                    </Text> */}
                          <Text tag="span" className={css.ReferralText}>
                            <Text tag="span" className={css.JobName}>
                              {r.job.name}
                            </Text>
                            <Text
                              tag="span"
                              size="medium"
                              color="light"
                              className={css.joinerAt}
                            >
                              {" "}
                              at{" "}
                            </Text>
                            <Text tag="span" className={css.CompanyName}>
                              {r.job.company.name}
                            </Text>
                          </Text>
                        </div>
                      );
                    })}
                  </div>
                  <div className={css.candidateFooter}>
                    <span className={css.candidateFooterDate}>
                      Referred {referredOn}
                    </span>
                    <span className={css.candidateFooterDate}>
                      Last Updated {lastUpdated}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className={css.bucketFooter}>
            {lastContactTimeframes[status] && (
              <div className={css.contactTimeframes}>
                <div className={css.contactTimeframesTitle}>Last Contact</div>
                <div className={css.times}>
                  <span className={css.green}>
                    &lt; {lastContactTimeframes[status][0]} days
                  </span>
                  <span className={css.yellow}>
                    {lastContactTimeframes[status][0]} -{" "}
                    {lastContactTimeframes[status][1]} days
                  </span>
                  <span className={css.red}>
                    &gt; {lastContactTimeframes[status][1]} days
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={css.page}>
      <Nav />
      <div className={css.Content}>
        {isUserLoggedIn ? (
          <>
            <div className={css.metrics}>
              <div className={css.metric}>
                <div className={css.metricTitle}>Total Candidates</div>
                <div className={css.metricCount}>{referralsState.count}</div>
              </div>
              <div className={css.metric}>
                <div className={css.metricTitle}>Potential Commission</div>
                <div className={css.metricCount}>
                  ${numberWithCommas(parseInt(globalSpread.toFixed(0), 10))}
                </div>
              </div>
            </div>
            <div className={css.candidates}>
              {renderBucket("Received")}
              {renderBucket("Reviewed Candidate")}
              {renderBucket("Presented to Employer")}
              {renderBucket("Interviewing")}
              {renderBucket("Offer Extended")}
              {renderBucket("Hired")}
              {renderBucket("Didn't Work Out")}
            </div>
          </>
        ) : (
          <ReferralsUnauthenticated />
        )}
      </div>
    </div>
  );
};
