import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const SENT_BY_USER_KEY = "sentBy";

export interface UpdateUserDetailsPayload {
  id: number | null;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  is_email_on: boolean;
  currentPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}

export interface UpdateUserOnboardingPayload
  extends Pick<UpdateUserDetailsPayload, "id" | "email"> {
  is_onboarding_done: boolean;
}

export interface UserResponse {
  id: number;
  email: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  is_email_on: boolean;
  is_onboarding_done: boolean;
  public_id: string;
}

export interface UserState {
  id: number | null;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  isEmailOn: boolean;
  isOnboardingDone: boolean;
  publicId: string;
  sentByUserPublicId?: string;
}

export const initialUserState: UserState = {
  id: null,
  email: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  isEmailOn: false,
  isOnboardingDone: false,
  publicId: "",
  sentByUserPublicId: (undefined as unknown) as string,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    fetchUser: (state, action: PayloadAction<string>) => state,
    fetchUserFailure: (state, action: PayloadAction<string>) => state,
    fetchUserSuccess: (state, action: PayloadAction<Partial<UserState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    logoutUser: (state) => initialUserState,
    updateUserDetails: (
      state,
      action: PayloadAction<
        UpdateUserDetailsPayload | UpdateUserOnboardingPayload
      >
    ) => state,
    updateUserSuccess: (state, action: PayloadAction<UserState>) =>
      action.payload,
    updateUserFailure: (state, action: PayloadAction<string>) => state,
    setSentByUser: (state, action: PayloadAction<string>) => {
      state.sentByUserPublicId = action.payload;
      return state;
    },
  },
});

export const { actions: userActions, reducer } = userSlice;
