import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

// Components
import { AuthLogoLinkMobile, AuthNav, AuthSidebar } from "components";
import { selectAppSettings } from "reducks/meta";

// Styles
import css from "./AuthLayout.module.scss";

interface AuthLayoutProps {
  children: React.ReactElement;
  formHeading: string;
  sidebarHeadings: string[];
  sidebarVariant?: "primary" | "secondary";
  navButtonPath: string;
  navText: string;
  navButtonText: string;
  includeAuthNav?: boolean;
}

const AuthLayout = ({
  children,
  formHeading,
  sidebarHeadings,
  sidebarVariant,
  navButtonPath,
  navText,
  navButtonText,
  includeAuthNav = true,
}: AuthLayoutProps) => {
  const appSettings = useSelector(selectAppSettings);

  return (
    <div
      className={cn(
        css.AuthPage,
        appSettings.isVendorSite && css.AuthPageVendor
      )}
    >
      {appSettings.isVendorSite === false && (
        <AuthSidebar variant={sidebarVariant} headings={sidebarHeadings} />
      )}
      <div className={css.AuthContent}>
        {includeAuthNav && (
          <AuthNav
            navButtonText={navButtonText}
            navButtonPath={navButtonPath}
            navText={navText}
          />
        )}
        <main className={css.AuthMain}>
          <div className={css.AuthFormContainer}>
            <AuthLogoLinkMobile />
            <h1 className={css.AuthHeading}>{formHeading}</h1>
            {children}
            <div className={css.MobileAuthAccountQuestion}>
              <p>
                {navText} <Link to={navButtonPath}>{navButtonText}</Link>
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AuthLayout;
