import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  authActions,
  selectAccessToken,
  selectAuthenticatedWith,
} from "reducks/auth";
import { selectAppSettings } from "reducks/meta";
import { selectLoader } from "reducks/loaders";
import { selectUser } from "reducks/user";

// Components
import {
  AuthLayout,
  FormMessage,
  LoginWithEmail,
  OAuthButtons,
} from "components";

// ROUTES
import { JOBS_PATH, SIGN_UP_PATH, routeAfterLoginKey } from "routes";
import { useQuery } from "utilities/hooks";

export const LoginPage = () => {
  const loginLoader = useSelector(selectLoader(authActions.loginWithEmail));
  const oauthLoader = useSelector(selectLoader(authActions.loginOAuth));
  const user = useSelector(selectUser);
  const accessToken = useSelector(selectAccessToken);
  const authenticatedWith = useSelector(selectAuthenticatedWith);
  const query = useQuery();
  const validAuthenticatedWith =
    authenticatedWith && authenticatedWith.length > 0;
  const appSettings = useSelector(selectAppSettings);

  const routeAfterLogin = query.get(routeAfterLoginKey) || undefined;

  // If we're done loading and we have the user, and we have a valid access
  // token, let's go to the app.
  if (
    !loginLoader.loading &&
    user.id &&
    accessToken &&
    validAuthenticatedWith
  ) {
    const redirect = routeAfterLogin || JOBS_PATH;
    return <Redirect to={redirect} />;
  }

  return (
    <AuthLayout
      formHeading={
        appSettings.isVendorSite ? "Log In" : "Log in to Agility Connect"
      }
      navButtonPath={SIGN_UP_PATH}
      navButtonText="Sign Up"
      navText="Don't have an account?"
      sidebarHeadings={[
        "Make a referral",
        "Impact a life",
        "Boost your income",
      ]}
      sidebarVariant="primary"
      includeAuthNav={appSettings.isVendorSite === false}
    >
      <>
        {oauthLoader.error && <FormMessage text={oauthLoader.error} error />}
        {appSettings.isVendorSite === false && (
          <OAuthButtons isLogin routeAfterLogin={routeAfterLogin} />
        )}
        <LoginWithEmail
          routeAfterLogin={routeAfterLogin}
          includeHeader={appSettings.isVendorSite === false}
        />
      </>
    </AuthLayout>
  );
};
