import React from "react";

import { BlurredText } from "components/BlurredText/BlurredText";
import { ReactComponent as CompanySvg } from "images/company.svg";
import Industry from "components/Industry/Industry";
import { Job } from "reducks/jobs";
import { ReactComponent as Pin } from "images/pin.svg";
import { Text } from "components";
import { selectIsUserLoggedIn } from "reducks/user";
import { useSelector } from "react-redux";

import css from "./JobDetails.module.scss";

interface JobDetailsProps {
  company: Job["company"];
  demoMode?: boolean;
}

export const JobDetails = ({ company, demoMode }: JobDetailsProps) => {
  const isLoggedIn = useSelector(selectIsUserLoggedIn);

  let locationText: string = company.state;
  if (company.city) {
    locationText = `${company.city}, ${company.state}`;
  }

  return (
    <div className={css.Details} data-cy="JobDetails">
      <Text className={css.Detail} tag="span">
        <CompanySvg />
        {isLoggedIn || demoMode ? (
          company.name
        ) : (
          <BlurredText>Company Hidden</BlurredText>
        )}
      </Text>
      <Text className={css.Detail} tag="span" data-cy="JobListItem-Industry">
        <Industry industry={company.industry} />
      </Text>
      <Text className={css.Detail} tag="span" data-cy="JobListItem-Location">
        <Pin />
        {locationText}
      </Text>
    </div>
  );
};
