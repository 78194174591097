import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { ACTIVE_REFERRALS_PATH, COMPLETE_REFERRALS_PATH } from "routes";
import {
  ReferralsListEmpty,
  ReferralsListItem,
  Spinner,
  Text,
} from "components";
import {
  referralsActions,
  selectActiveCandidates,
  selectCompleteCandidates,
} from "reducks/referrals";

import { selectIsUserLoggedIn } from "reducks/user";

import css from "./ReferralsList.module.scss";

export interface ReferralListProps {
  isLoading: boolean;
  error?: string;
  className?: string;
}

const ReferralsList = ({ isLoading, error, className }: ReferralListProps) => {
  const dispatch = useDispatch();
  const activeCandidates = useSelector(selectActiveCandidates);
  const completeCandidates = useSelector(selectCompleteCandidates);
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  // Load the referrals
  useEffect(() => {
    if (!isUserLoggedIn) return;
    dispatch(referralsActions.fetchReferrals());
  }, [dispatch, isUserLoggedIn]);

  if (isLoading) {
    return (
      <div className={cn(css.ReferralsList, className)}>
        <Spinner text="Loading Referrals" className={css.Spinner} />
      </div>
    );
  }

  if (error) {
    return <Text>Error: {error}</Text>;
  }

  return isUserLoggedIn ? (
    <Switch>
      <Route exact path={ACTIVE_REFERRALS_PATH}>
        <div
          className={cn(css.ReferralsList, className)}
          data-cy="ReferralsList"
        >
          {activeCandidates.length ? (
            activeCandidates.map((referral) => (
              <ReferralsListItem {...referral} key={referral.id} />
            ))
          ) : (
            <ReferralsListEmpty type="active" />
          )}
        </div>
      </Route>
      <Route exact path={COMPLETE_REFERRALS_PATH}>
        <div
          className={cn(css.ReferralsList, className)}
          data-cy="ReferralsList"
        >
          {completeCandidates.length ? (
            completeCandidates.map((referral) => (
              <ReferralsListItem {...referral} key={referral.id} />
            ))
          ) : (
            <ReferralsListEmpty type="complete" />
          )}
        </div>
      </Route>
    </Switch>
  ) : (
    <div>TODO: Not logged in state</div>
  );
};

export default ReferralsList;
