import React, { useEffect } from "react";

import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectLoader } from "reducks/loaders";
import { useForm } from "react-hook-form";

// Assets
import { ReactComponent as FacebookIcon } from "images/facebook-square.svg";
import { ReactComponent as GithubIcon } from "images/github.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin-square.svg";

// Components
import { Button, FormMessage, HookInput, Nav, RadioGroup } from "components";

// Reducks
import {
  UpdateUserDetailsPayload,
  UserState,
  selectUser,
  userActions,
} from "reducks/user";
import { selectAuthenticatedWith } from "reducks/auth";

// Styles
import css from "./SettingsPage.module.scss";

type FormPayload = Omit<UpdateUserDetailsPayload, "id" | "is_email_on"> & {
  is_email_on: string;
};

interface AuthenticatedWithItem {
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface AuthenticatedWithDictionary {
  [key: string]: AuthenticatedWithItem;
}

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const getSettingsSchema = (isEmail: boolean) => {
  return yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    phone_number: yup
      .string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Please enter a valid phone number"),
    is_email_on: yup.string().required(),
    currentPassword: yup.string(),
    newPassword: yup
      .string()
      .when("currentPassword", (currentPassword: string, schema: any) => {
        if (isEmail) {
          if (currentPassword.length > 0) {
            return schema.required("Please enter a new password");
          }
        }
        return schema;
      }),
    confirmNewPassword: yup
      .string()
      .when(
        ["currentPassword", "newPassword"],
        (currentPassword: string, newPassword: string, schema: any) => {
          if (isEmail) {
            if (newPassword.length > 0) {
              return schema.test({
                test: (confirmNewPassword: string) => {
                  return confirmNewPassword === newPassword;
                },
                message: "Passwords do not match",
              });
            }

            if (currentPassword.length > 0) {
              return schema.required("Please confirm a new password");
            }
          }

          return schema;
        }
      ),
  });
};

function SettingsPage() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const service = useSelector(selectAuthenticatedWith);

  function getDefaultFormValues(defaultUser: UserState) {
    return {
      first_name: defaultUser.firstName,
      last_name: defaultUser.lastName,
      email: defaultUser.email,
      phone_number: defaultUser.phoneNumber,
      is_email_on: defaultUser.isEmailOn ? "Yes" : "No",
    };
  }

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState,
  } = useForm<FormPayload>({
    defaultValues: getDefaultFormValues(user),
    validationSchema: getSettingsSchema(service === "email"),
  });

  const updateUserDetailsLoader = useSelector(
    selectLoader(userActions.updateUserDetails)
  );

  useEffect(() => {
    reset(getDefaultFormValues(user));
  }, [reset, user]);

  function onSubmit(data: FormPayload) {
    const { id } = user;
    const payload = {
      ...data,
      is_email_on: data.is_email_on === "Yes",
      id,
    };

    // removing password fields if the user has not entered anything in them.
    if (!payload.currentPassword) {
      delete payload.currentPassword;
      delete payload.newPassword;
      delete payload.confirmNewPassword;
    }

    if (typeof id === "number") {
      dispatch(userActions.updateUserDetails(payload));
    }
  }

  function renderOAuthServiceIndicator() {
    const data: AuthenticatedWithDictionary = {
      github: {
        title: "GitHub",
        icon: GithubIcon,
      },
      linkedin: {
        title: "LinkedIn",
        icon: LinkedInIcon,
      },
      facebook: {
        title: "Facebook",
        icon: FacebookIcon,
      },
    };

    const selectedService: AuthenticatedWithItem = data[service];
    const Icon = selectedService.icon;

    return (
      <div
        className={css.oauthContainer}
        data-cy={`OAuthServiceIndicator-${selectedService.title}`}
      >
        <Icon className={css.socialIcon} />
        <div>
          <p className={css.oauthTitle}>
            Authenticated with {selectedService.title}
          </p>
          <p className={css.oauthDescription}>
            No need for a password here! You logged in using{" "}
            {selectedService.title}.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div data-cy="SettingsPage">
      <Nav />
      <div className={css.Settings}>
        <div className={css.accountSettingsHeader}>
          <h1>Account Settings</h1>
        </div>
        {updateUserDetailsLoader.loaded && !updateUserDetailsLoader.error && (
          <FormMessage
            text="Changes saved successfully!"
            data-cy="FormMessageSuccess"
          />
        )}
        {updateUserDetailsLoader.error && (
          <FormMessage
            text={updateUserDetailsLoader.error}
            data-cy="FormMessageError"
            error
          />
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className={css.inputGroup}>
            <h3>Basic Info</h3>
            <div className={css.nameInputRow}>
              <HookInput
                label="First Name"
                register={register}
                name="first_name"
                className={css.halfWidthInput}
                type="text"
                error={errors.first_name && errors.first_name.message}
                data-cy="FirstNameInput"
                autoCapitalize="on"
                autoComplete="given-name"
                autoCorrect="off"
              />
              <HookInput
                label="Last Name"
                register={register}
                name="last_name"
                className={css.halfWidthInput}
                type="text"
                error={errors.last_name && errors.last_name.message}
                data-cy="LastNameInput"
                autoCapitalize="on"
                autoComplete="family-name"
                autoCorrect="off"
              />
            </div>
            <div className={css.mobilePhoneInputContainer}>
              <HookInput
                label="Mobile Phone"
                register={register}
                name="phone_number"
                className={css.phoneHookInput}
                type="tel"
                error={errors.phone_number && errors.phone_number.message}
                data-cy="MobilePhoneInput"
                autoComplete="tel"
                autoCorrect="off"
              />
              <div className={css.inputNote}>
                We only use this for urgent communications.
              </div>
            </div>
            <HookInput
              label="Email"
              register={register}
              name="email"
              type="email"
              error={errors.email && errors.email.message}
              data-cy="EmailInput"
              autoCapitalize="off"
              autoComplete="email"
              autoCorrect="off"
            />
          </div>
          <div className={css.inputGroup}>
            <h3>Email Updates</h3>
            <RadioGroup
              label="Do you want to receive email updates on your candidates?"
              register={register}
              radioOptions={["Yes", "No"]}
              name="is_email_on"
              className={css.emailRadioGroup}
              type="text"
              error={errors.is_email_on && errors.is_email_on.message}
              data-cy="IsEmailOnRadioGroup"
            />
          </div>
          <div className={css.inputGroup}>
            <h3>Password</h3>
            {service === "email" ? (
              <div
                className={css.updatePasswordContainer}
                data-cy="UpdatePasswordFields"
              >
                <HookInput
                  label="Current Password"
                  register={register}
                  name="currentPassword"
                  type="password"
                  error={
                    errors.currentPassword && errors.currentPassword.message
                  }
                  data-cy="CurrentPasswordInput"
                  autoCapitalize="off"
                  autoComplete="current-password"
                  autoCorrect="off"
                />
                <HookInput
                  label="New Password"
                  register={register}
                  name="newPassword"
                  type="password"
                  error={errors.newPassword && errors.newPassword.message}
                  data-cy="NewPasswordInput"
                  autoCapitalize="off"
                  autoComplete="new-password"
                  autoCorrect="off"
                />
                <HookInput
                  label="Confirm New Password"
                  register={register}
                  name="confirmNewPassword"
                  type="password"
                  error={
                    errors.confirmNewPassword &&
                    errors.confirmNewPassword.message
                  }
                  data-cy="ConfirmNewPasswordInput"
                  autoCapitalize="off"
                  autoComplete="new-password"
                  autoCorrect="off"
                />
              </div>
            ) : (
              renderOAuthServiceIndicator()
            )}
          </div>
          <Button
            type="submit"
            data-cy="SaveChangesButton"
            disabled={!formState.dirty}
            isLoading={updateUserDetailsLoader.loading}
          >
            Save Changes
          </Button>
        </form>
      </div>
    </div>
  );
}

export default SettingsPage;
