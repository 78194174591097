/* eslint-disable no-bitwise */
export const hashCode = (value: string) => {
  let hash = 0;

  for (let i = 0; i < value.length; i += 1) {
    const char = value.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32 bit integer
  }
  return hash;
};
/* eslint-enable no-bitwise */
