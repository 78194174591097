import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import add from "date-fns/add";

export interface SetItemPayload {
  name: string;
  status: number;
  value: any;
  expires: number; // Number of minutes the cache should live for
}

export interface CacheState {
  [key: string]: {
    value: any;
    status: number;
    expires: Date;
  };
}

export const REFERRALS_CACHE_KEY = "referrals_cache";

export const initialCacheState: CacheState = {};

const cacheSlice = createSlice({
  name: "cache",
  initialState: initialCacheState,
  reducers: {
    clearItem: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    },
    setItem: (state, action: PayloadAction<SetItemPayload>) => {
      state[action.payload.name] = {
        value: action.payload.value,
        status: action.payload.status,
        expires: add(new Date(), {
          minutes: action.payload.expires,
        }),
      };
    },
  },
});

export const { actions: cacheActions, reducer } = cacheSlice;
