import React from "react";

import { Button, Heading } from "components";

import css from "./JobsListMessage.module.scss";

interface JobsListMessageProps {
  onButtonClick?: any;
  buttonText?: string;
  subheading?: string;
  heading: string;
  icon?: React.ReactNode;
}

const JobsListMessage = ({
  heading,
  onButtonClick,
  subheading,
  buttonText,
  icon,
  ...rest
}: JobsListMessageProps) => (
  <div className={css.JobsListMessage} {...rest}>
    {icon && <div className={css.IconContainer}>{icon}</div>}
    <Heading variant="h1" className={css.JobsListMessageHeading}>
      {heading}
    </Heading>
    {subheading && (
      <p className={css.JobsListMessageSubheading}>{subheading}</p>
    )}
    {buttonText && (
      <Button
        variant="secondary"
        className={css.ClearFiltersButton}
        onClick={onButtonClick}
      >
        {buttonText}
      </Button>
    )}
  </div>
);

export default JobsListMessage;
