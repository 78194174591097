// https://codesandbox.io/embed/lp80n9z7v9
import { RefObject, useEffect, useRef, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";

export function useMeasure<T extends HTMLDivElement = HTMLDivElement>(): [
  {
    ref: RefObject<T>;
  },
  {
    left: number;
    top: number;
    width: number;
    height: number;
  }
] {
  const ref = useRef<T>(null);
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(
    () => new ResizeObserver(([entry]) => set(entry.contentRect))
  );
  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) ro.observe(currentRef);
    return () => ro.disconnect();
  }, [ro]);

  return [{ ref }, bounds];
}
