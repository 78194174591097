import { combineReducers } from "@reduxjs/toolkit";

import { authReducer, forgotPasswordReducer } from "./auth";
import cacheReducer from "./cache";
import jobsFiltersReducer from "./jobsFilters";
import jobsReducer from "./jobs";
import loadersReducer from "./loaders";
import metaDataReducer from "./meta";
import referralsReducer from "./referrals";
import userReducer from "./user";

export const rootReducer = combineReducers({
  auth: authReducer,
  cache: cacheReducer,
  forgotPassword: forgotPasswordReducer,
  jobs: jobsReducer,
  jobsFilters: jobsFiltersReducer,
  loaders: loadersReducer,
  meta: metaDataReducer,
  referrals: referralsReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
