import React, { useEffect, useReducer, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useHistory } from "react-router-dom";

import {
  AppMetaState,
  CityFilter,
  JobFilter,
  JobFilterType,
  selectAppSettings,
} from "reducks/meta";
import {
  JobsFilters,
  initialJobsFiltersState,
  jobsFiltersActions,
  selectAppliedCityFilters,
  selectAppliedFilterCount,
  selectAppliedIndustryFilters,
  selectAppliedJobTypeFilters,
  selectAppliedPositionTypeFilters,
} from "reducks/jobsFilters";

import { Button, Heading, Input, Text } from "components";

import {
  useDebounce,
  usePrevious,
  useQuery,
  useWindowSize,
} from "utilities/hooks";
import { scrollToTop } from "utilities/scroll";

import { ReactComponent as CloseSvg } from "images/close.svg";
import { ReactComponent as SearchSvg } from "images/search.svg";

import { useClearAllFilters } from "./actions";
// eslint-disable-next-line import/no-cycle
import { JobsFilterPanelMobile } from "./JobsFilterPanelMobile";
import { JobsFilterPanelOption } from "./Option";
import css from "./JobsFilterPanel.module.scss";

interface JobsFiltersState {
  open: string;
  query: string;
}

type JobsFilterAction =
  | "openFilter"
  | "closeFilter"
  | "closeAllFilters"
  | "setQuery";

const jobsFiltersState = {
  query: "",
  open: "",
};

function init(initialQuery: string) {
  return {
    ...jobsFiltersState,
    query: initialQuery,
  };
}

function jobsFilterReducer(
  state: JobsFiltersState,
  action: { type: JobsFilterAction; payload: any }
) {
  switch (action.type) {
    case "openFilter":
      return {
        ...state,
        open: action.payload,
      };
    case "closeFilter":
      return {
        ...state,
        open: state.open === action.payload ? "" : state.open,
      };
    case "closeAllFilters":
      return {
        ...state,
        open: "",
      };
    case "setQuery":
      return {
        ...state,
        query: action.payload,
      };
    default:
      throw new Error();
  }
}

export interface JobsFilterPanelProps {
  meta: AppMetaState;
  state: JobsFiltersState;
  localDispatch: React.Dispatch<{ type: JobsFilterAction; payload: any }>;
}

interface JobsFilterSectionProps {
  types: JobFilter | CityFilter;
  isMobile: boolean | undefined;
  appliedFilterTypeCount: number;
  isOpen: boolean;
  setOpen: () => void;
  setClose: () => void;
}

const JobsFilterSection = ({
  types,
  isMobile,
  appliedFilterTypeCount,
  isOpen,
  setOpen,
  setClose,
}: JobsFilterSectionProps) => {
  const history = useHistory();
  const q = useQuery();

  const areFiltersApplied = appliedFilterTypeCount > 0;

  // Sort the options
  const options = [...types.options];
  options.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const handleClear = () => {
    q.delete(types.type);
    history.push({ search: q.toString() });
  };

  return (
    <div className={css.Section} onMouseLeave={() => setClose()}>
      <button
        type="button"
        className={css.SectionHeadingButton}
        onMouseEnter={() => setOpen()}
        data-cy={`JobsFilterSection-Button-${types.name}`}
      >
        <Heading variant="h3" align="left" className={css.SectionHeading}>
          {/* Should this be renamed on the BE? */}
          {types.name === "City" ? "Location" : types.name}
          {areFiltersApplied && ` (${appliedFilterTypeCount})`}
        </Heading>
      </button>
      {(isOpen || isMobile) && (
        <div data-cy={`JobsFilterSection-FilterOptions-${types.name}`}>
          <div className={css.FilterSectionWrapper}>
            <div className={css.FilterSection}>
              <div className={css.FilterOptions}>
                {options.map((option) => {
                  return (
                    <JobsFilterPanelOption
                      option={option}
                      section={types.type}
                      key={option.name}
                    />
                  );
                })}
              </div>
              <div className={css.FilterSectionActions}>
                <Text
                  className={cn(css.ClearLink, {
                    [css.ClearLinkDisabled]: !areFiltersApplied,
                  })}
                  tag="span"
                  color="black"
                  size="medium"
                  weight={700}
                  onClick={handleClear}
                  tabIndex={0}
                  data-cy={`clear${types.name}`}
                >
                  Clear
                </Text>
                <Button
                  variant="primary"
                  size="small"
                  onClick={() => setClose()}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const JobsFilterPanelContent = ({
  meta,
  state,
  localDispatch,
  isMobile,
  onCloseModal,
}: JobsFilterPanelProps & {
  isMobile?: boolean;
  onCloseModal?: () => void;
}) => {
  const handleClearAll = useClearAllFilters();
  const appSettings = useSelector(selectAppSettings);
  const { job_types, industry_types, position_types, cities } = meta;
  const appliedJobTypeFilters = useSelector(selectAppliedJobTypeFilters);
  const appliedIndustryFilters = useSelector(selectAppliedIndustryFilters);
  const appliedPositionTypeFilters = useSelector(
    selectAppliedPositionTypeFilters
  );
  const appliedCityFilters = useSelector(selectAppliedCityFilters);
  const appliedFilterCount = useSelector(selectAppliedFilterCount);
  const disableClearLink = appliedFilterCount === 0;

  return (
    <div>
      {isMobile && (
        <div className={css.TopWrapper}>
          <Text
            className={cn(css.ClearAllLink, {
              [css.ClearAllLinkDisabled]: disableClearLink,
            })}
            tag="span"
            color="black"
            size="medium"
            weight={700}
            onClick={handleClearAll}
            tabIndex={0}
            data-cy="ClearAllButton"
          >
            Clear All
          </Text>
          <button
            type="button"
            onClick={onCloseModal}
            className={css.CloseModal}
            data-cy="JobsFilterMobile-CloseModalButton"
          >
            <CloseSvg className={css.CloseModalSvg} />
          </button>
        </div>
      )}
      <div className={css.SectionsWrapper}>
        {job_types.options.length > 0 && appSettings.isVendorSite === false && (
          <JobsFilterSection
            types={job_types}
            isMobile={isMobile}
            appliedFilterTypeCount={appliedJobTypeFilters.length}
            isOpen={state.open === "job_type"}
            setOpen={() => {
              localDispatch({
                type: "openFilter",
                payload: "job_type",
              });
            }}
            setClose={() => {
              localDispatch({
                type: "closeFilter",
                payload: "job_type",
              });
            }}
          />
        )}
        {industry_types.options.length > 0 && (
          <JobsFilterSection
            types={industry_types}
            isMobile={isMobile}
            appliedFilterTypeCount={appliedIndustryFilters.length}
            isOpen={state.open === "industry"}
            setOpen={() => {
              localDispatch({
                type: "openFilter",
                payload: "industry",
              });
            }}
            setClose={() => {
              localDispatch({
                type: "closeFilter",
                payload: "industry",
              });
            }}
          />
        )}
        {position_types.options.length > 0 && (
          <JobsFilterSection
            types={position_types}
            isMobile={isMobile}
            appliedFilterTypeCount={appliedPositionTypeFilters.length}
            isOpen={state.open === "position_type"}
            setOpen={() => {
              localDispatch({
                type: "openFilter",
                payload: "position_type",
              });
            }}
            setClose={() => {
              localDispatch({
                type: "closeFilter",
                payload: "position_type",
              });
            }}
          />
        )}
        {cities.options.length > 0 && (
          <JobsFilterSection
            types={cities}
            isMobile={isMobile}
            appliedFilterTypeCount={appliedCityFilters.length}
            isOpen={state.open === "city"}
            setOpen={() => {
              localDispatch({
                type: "openFilter",
                payload: "city",
              });
            }}
            setClose={() => {
              localDispatch({
                type: "closeFilter",
                payload: "city",
              });
            }}
          />
        )}
        {!isMobile && (
          <Text
            className={cn(css.ClearAllLink, {
              [css.ClearAllLinkDisabled]: disableClearLink,
            })}
            tag="span"
            color="black"
            size="medium"
            weight={700}
            onClick={handleClearAll}
            tabIndex={0}
            data-cy="ClearAllButton"
          >
            Clear All
          </Text>
        )}
      </div>
      {isMobile && (
        <div className={css.JobsFilterMobileBottom}>
          <Button
            className={css.SeeJobsButton}
            variant="primary"
            onClick={onCloseModal}
          >
            See Jobs
          </Button>
        </div>
      )}
    </div>
  );
};

const JobsFilterPanel = ({
  meta,
  onSearchInputFocus,
  onSearchQueryChange,
}: {
  meta: AppMetaState;
  onSearchInputFocus: () => void;
  onSearchQueryChange: () => void;
}) => {
  const { isDesktop, height, width } = useWindowSize();
  const prevWidth = usePrevious(width);
  const prevHeight = usePrevious(height);
  const dispatch = useDispatch();
  const urlParams = useQuery();
  const history = useHistory();
  const previousSearch = useRef<string>(urlParams.get("query") ?? "");
  const searchRef = useRef<HTMLInputElement>(null);
  const appSettings = useSelector(selectAppSettings);

  const [state, localDispatch] = useReducer(
    jobsFilterReducer,
    urlParams.get("query") ?? "",
    init
  );
  const debouncedQuery: string = useDebounce<string>(state.query, 100);

  // This will return false if the re-render is because of a window resize
  const shouldRecomputeFilters =
    (prevHeight === undefined || height === prevHeight) &&
    (prevWidth === undefined || width === prevWidth);

  /**
   * Effect for debounced search
   */
  useEffect(() => {
    const searchQuery = debouncedQuery;
    const currentSearchParam = urlParams.get("query") ?? "";

    if (
      searchQuery !== currentSearchParam &&
      searchQuery !== previousSearch.current
    ) {
      // if the value is new, coming from the search box, and out of sync with the url
      // then we need to act
      if (!searchQuery) {
        // if the term is blank then remove the query param
        urlParams.delete("query");
      } else {
        // if the term exists then update the query param
        urlParams.set("query", searchQuery);
      }
      // scrollToTop();
      onSearchQueryChange();
      history.push({ search: urlParams.toString() });
    } else {
      // likely browser history change like back button, so we need to sync up the field
      localDispatch({
        type: "setQuery",
        payload: currentSearchParam,
      });
    }
    // update the previous search value with whatever is in the field
    previousSearch.current = searchQuery;
  }, [debouncedQuery, dispatch, history, urlParams]);

  useEffect(() => {
    const isJobsFilterKey = (key: string): key is JobFilterType =>
      key in initialJobsFiltersState;

    if (shouldRecomputeFilters) {
      const newFilterState: JobsFilters = {
        job_type: [],
        industry: [],
        position_type: [],
        city: [],
        query: "",
        url: urlParams.toString(),
      };
      urlParams.forEach((val, key) => {
        let metaKey: keyof AppMetaState | null = null;
        switch (key) {
          case "industry":
            metaKey = "industry_types";
            break;
          case "job_type":
            metaKey = "job_types";
            break;
          case "position_type":
            metaKey = "position_types";
            break;
          default:
        }

        if (metaKey !== null && isJobsFilterKey(key)) {
          const matchingMetaFilter = meta[metaKey].options.find(
            (o) => o.name === val
          );
          if (matchingMetaFilter) {
            newFilterState[key].push(matchingMetaFilter.id);
          }
        }

        if (key === "company__city") {
          newFilterState.city.push(val);
        }

        if (key === "query") {
          newFilterState.query = val;
        }
      });

      dispatch(jobsFiltersActions.setJobsFilters(newFilterState));
    }
  }, [dispatch, meta, urlParams, shouldRecomputeFilters]);

  // const executeScroll = () =>
  //   searchRef?.current?.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <form
        role="search"
        className={css.SearchContainer}
        onSubmit={(e) => {
          e.preventDefault();
          if (searchRef.current) {
            searchRef.current.blur();
          }
        }}
      >
        <Input
          autoComplete="off"
          autoSave="jobs-search"
          data-cy="JobsFilter-Search"
          placeholder="Search jobs by keyword, title, or industry..."
          aria-label="Search jobs by keyword, title, or industry..."
          name="jobs-search"
          id="jobs-search"
          type="text"
          role="searchbox"
          value={state.query}
          className={css.searchInput}
          onFocus={onSearchInputFocus}
          onChange={(e) => {
            localDispatch({
              type: "setQuery",
              payload: e.currentTarget.value,
            });
          }}
          ref={searchRef}
        />
        <SearchSvg className={css.SearchIcon} />
        <Text
          className={cn(css.ClearSearchLink, {
            [css.ClearSearchLinkDisabled]: !debouncedQuery,
          })}
          tag="span"
          color="black"
          size="medium"
          weight={700}
          onClick={() => {
            localDispatch({
              type: "setQuery",
              payload: "",
            });
          }}
          tabIndex={0}
          data-cy="clearSearch"
        >
          Clear
        </Text>
      </form>
      {isDesktop ? (
        <JobsFilterPanelContent
          meta={meta}
          state={state}
          localDispatch={localDispatch}
        />
      ) : (
        <JobsFilterPanelMobile
          meta={meta}
          state={state}
          localDispatch={localDispatch}
        />
      )}
    </>
  );
};

export default JobsFilterPanel;
