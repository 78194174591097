import React from "react";
import cn from "classnames";

import { ReactComponent as CheckSvg } from "images/check.svg";
import css from "./Checkbox.module.scss";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isHovered?: boolean;
}

const Checkbox = ({
  className,
  checked,
  isHovered = false,
  ...rest
}: CheckboxProps) => {
  return (
    <div className={cn(css.Wrapper, className)}>
      <input
        {...rest}
        checked={checked}
        className={css.HiddenCheckbox}
        type="checkbox"
      />
      <div
        className={cn(css.VisibleCheckbox, {
          [css.isHovered]: isHovered,
          [css.isChecked]: checked,
        })}
      >
        <CheckSvg className={css.Check} />
      </div>
    </div>
  );
};

export default Checkbox;
