import { RootState } from "reducks/rootReducer";
import { createSelector } from "reselect";

import { Job } from "./ducks";

const defaultJob = (): Job => {
  return {
    id: -1,
    name: "",
    description: "",
    qualifications: "",
    benefits: "",
    company: {
      city: "",
      state: "",
      industry: "",
    },
    num_positions_open: 0,
    reward: 0,
    job_type: 0,
    position_type: {
      id: 0,
      name: "",
    },
    posted_date: "",
    is_priority: false,
  };
};

export const selectJobs = (state: RootState) => state.jobs;

export const selectJob = (id: string) => {
  return createSelector(selectJobs, (jobs) => jobs.map[id] || defaultJob());
};

export const selectFirstJobResult = createSelector(selectJobs, ({ results }) =>
  results.length > 0 ? results[0] : undefined
);
