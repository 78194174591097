import React from "react";

import { Link } from "react-router-dom";

import { ReactComponent as ArrowLeftSvg } from "images/arrow-left.svg";

import css from "./BackButton.module.scss";

export const BackButton = ({ to }: { to: string }) => {
  return (
    <Link to={to} className={css.Button}>
      <ArrowLeftSvg className={css.Arrow} />
      <span className={css.Text}>Back</span>
    </Link>
  );
};
