import React, { MouseEventHandler } from "react";

import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import cn from "classnames";

import { Button, Label } from "components";
import { ReactComponent as CloseIcon } from "images/close.svg";
import { FileData } from "reducks/jobs";
import { ReactComponent as PaperClipIcon } from "images/paperclip.svg";

import css from "./FileUpload.module.scss";

interface FileUploadProps {
  name: string;
  label: string;
  file: FileData | null;
  onDrop?<T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ): void;
  handleUploadCancel: MouseEventHandler;
  hasError?: boolean;
}

const FileUpload = ({
  name,
  label,
  file,
  onDrop,
  handleUploadCancel,
  hasError,
}: FileUploadProps) => {
  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    accept: [".pdf", ".doc", ".docx", ".odt"],
    multiple: false,
    onDrop,
  });

  return (
    <div className={css.FileUpload}>
      <Label htmlFor={name} text={label} />
      {file === null && (
        <div
          {...getRootProps({
            className: cn({
              [css.Dropzone]: true,
              [css.dropzoneReject]: isDragReject,
              [css.dropzoneError]: hasError,
            }),
          })}
          data-cy="dropzone"
        >
          <PaperClipIcon />
          <div>
            <p>
              Drag and drop a {name} file here <br />
              <span className={css.DropzoneLightText}>or click to browse</span>
            </p>
          </div>
          <input {...getInputProps({ name })} />
        </div>
      )}
      {file !== null && (
        <div className={css.FileUploaded} data-cy="dropzone-file">
          <div>{file.name}</div>
          <Button
            className={css.FileUploadCancelButton}
            variant="secondary"
            size="small"
            onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
              handleUploadCancel(e)
            }
            data-cy="dropzone-file-remove-button"
          >
            <CloseIcon />
          </Button>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
