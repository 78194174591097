import React from "react";
import cn from "classnames";

import {
  Referral,
  completedNotHiredStatuses,
  hiredStatuses,
} from "reducks/referrals";
import { Text } from "components";
import { numberWithCommas } from "utilities/formatting";

import { ReactComponent as BriefcaseSvg } from "images/briefcase.svg";
import { ReactComponent as CheckSvg } from "images/check.svg";

import css from "./ReferralsListItem.module.scss";

export const ReferralsSummary = ({
  referral_set,
  hiredReferral,
}: {
  referral_set: Referral[];
  hiredReferral?: Referral;
}) => {
  return (
    <div className={css.ReferralsWrapper}>
      <Text tag="span" color="light">
        Referred to
      </Text>
      {referral_set.map((r) => {
        const otherReferralHired =
          !!hiredReferral && !hiredStatuses.includes(r.status);
        const isCompletedNotHired =
          completedNotHiredStatuses.includes(r.status) || otherReferralHired;
        const isHired = hiredStatuses.includes(r.status);

        return (
          <div
            key={`${r.job.name}-${r.job.company.name}`}
            className={cn(css.JobWrapper, {
              [css.CompletedNotHired]: isCompletedNotHired,
            })}
            data-cy="ReferralsList-JobItem"
          >
            <BriefcaseSvg className={css.BriefcaseSvg} />
            <Text
              tag="span"
              className={css.ReferralReward}
              color={isCompletedNotHired ? "white" : "green"}
              background={isCompletedNotHired ? "gray" : "green"}
              size="large"
            >
              ${numberWithCommas(r.job.reward)}
            </Text>
            <Text tag="span" className={css.ReferralText}>
              <Text tag="span" className={css.JobName} size="large">
                {r.job.name}
              </Text>
              <Text tag="span" size="large" color="light">
                &nbsp;at&nbsp;
              </Text>
              <Text tag="span" className={css.CompanyName} size="large">
                {r.job.company.name}
              </Text>
            </Text>
            {isHired && (
              <div className={css.ReferralHiredWrapper}>
                <CheckSvg className={css.ReferralHiredCheck} />
                <Text
                  tag="span"
                  color="green"
                  size="large"
                  className={css.ReferralHiredText}
                >
                  Hired
                </Text>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
