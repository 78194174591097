import { Action, PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AuthState {
  accessToken: string;
  authenticatedWith: string;
}

export const initialAuthState: AuthState = {
  accessToken: "",
  authenticatedWith: "",
};

export interface LoginOauthPayload {
  service: "github" | "facebook" | "linkedin"; // add more services here when we have them
  code: string;
  redirect_uri: string;
}

export interface LoginWithEmailPayload {
  email: string;
  password: string;
}

export interface SignUpWithEmailPayload {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    updateAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    updateAuthenticatedWith: (state, action: PayloadAction<string>) => {
      state.authenticatedWith = action.payload;
    },
    updateRefreshToken: (state, action: PayloadAction<string>) => state,
    loginOAuth: (state, action: PayloadAction<LoginOauthPayload>) => state,
    oauthFailure: (state, action: PayloadAction<string>) => state,
    oauthSuccess: (state, action: Action) => state,
    loginWithEmail: (state, action: PayloadAction<LoginWithEmailPayload>) =>
      state,
    loginWithEmailFailure: (state, action: PayloadAction<string>) => state,
    loginWithEmailSuccess: (state, action: Action) => state,
    loginWithEmailReset: (state, action: Action) => state,
    logOut: (state) => {
      state.accessToken = "";
      state.authenticatedWith = "";
    },
    logOutSuccess: (state) => state,
    logOutFailure: (state) => state,
    signUpWithEmail: (state, action: PayloadAction<SignUpWithEmailPayload>) =>
      state,
    signUpWithEmailFailure: (state, action: PayloadAction<string>) => state,
    signUpWithEmailSuccess: (state, action: Action) => state,
    signUpWithEmailReset: (state, action: Action) => state,
    checkAuth: (state, action: Action) => state,
    checkAuthSuccess: (state, action: Action) => state,
    checkAuthFailure: (state, action: PayloadAction<string>) => state,
    pollCheckAuth: (state, action: Action) => state,
    stopPollCheckAuth: (state, action: Action) => state,
    fetchNewToken: (state, action: Action) => state,
    fetchNewTokenComplete: (state, action: Action) => state,
  },
});

// Forgot password
interface ForgotPasswordState {
  email: string;
  password: string;
}
export const initialForgotPasswordState: ForgotPasswordState = {
  email: "",
  password: "",
};

export interface ResetPasswordPayload {
  password: string;
  passwordDoubleTake: string;
  token: string;
}

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: initialForgotPasswordState,
  reducers: {
    sendForgotPasswordEmail: (state, action: PayloadAction<string>) => state,
    sendForgotPasswordEmailSuccess: (state, action: Action) => state,
    sendForgotPasswordEmailFailure: (state, action: Action) => state,
    resetPassword: (state, action: PayloadAction<ResetPasswordPayload>) =>
      state,
    resetPasswordSuccess: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    resetPasswordError: (state, action: Action) => state,
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
export const {
  actions: forgotPasswordActions,
  reducer: forgotPasswordReducer,
} = forgotPasswordSlice;
