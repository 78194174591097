import { RootState } from "reducks/rootReducer";
import { createSelector } from "reselect";

export const FILTER_QS_DIVIDER = ".";

export const selectJobsFilters = (state: RootState) => state.jobsFilters;

export const selectJobsFiltersInitialized = (state: RootState) =>
  state.jobsFilters.initialized;

export const selectAppliedJobTypeFilters = createSelector(
  selectJobsFilters,
  (jobsFilters) => jobsFilters.job_type
);

export const selectAppliedIndustryFilters = createSelector(
  selectJobsFilters,
  (jobsFilters) => jobsFilters.industry
);

export const selectAppliedPositionTypeFilters = createSelector(
  selectJobsFilters,
  (jobsFilters) => jobsFilters.position_type
);

export const selectAppliedCityFilters = createSelector(
  selectJobsFilters,
  (jobsFilters) => jobsFilters.city
);

export const selectQuery = createSelector(
  selectJobsFilters,
  (jobsFilters) => jobsFilters.query
);

export const selectFilterUrl = createSelector(
  selectJobsFilters,
  (jobsFilters) => jobsFilters.url
);

export const selectAppliedFilterCount = createSelector(
  selectAppliedJobTypeFilters,
  selectAppliedIndustryFilters,
  selectAppliedPositionTypeFilters,
  selectAppliedCityFilters,
  (
    appliedJobTypeFilters,
    appliedIndustryFilters,
    appliedPositionTypeFilters,
    appliedCityFilters
  ) =>
    appliedJobTypeFilters.length +
    appliedIndustryFilters.length +
    appliedPositionTypeFilters.length +
    appliedCityFilters.length
);
