import React, { useEffect, useState } from "react";

import { Heading, MarketingHero, MarketingLayout, Text } from "components";

import sb, { StoryblokService } from "utilities/storyblok";
import { useWindowSize } from "utilities/hooks";

import bgImage from "images/marketing/faq-hero.png";

import css from "./FAQ.module.scss";

const HeroContent = () => {
  const { isBigMobile } = useWindowSize();

  return (
    <MarketingHero bgImage={bgImage} style={{ display: "grid" }}>
      <Heading className={css.HeroHeading} variant="h1">
        FAQ
      </Heading>
    </MarketingHero>
  );
};

interface Question {
  _uid: string;
  question: string;
  answer: string;
}

interface FAQSection {
  title: string;
  questions: Array<Question>;
}

export const FAQIndex = () => {
  const [faqs, setFaqs] = useState<Array<FAQSection>>([]);

  useEffect(() => {
    const getStories = async () => {
      const stories = await sb.get("faq");
      const faqData = StoryblokService.getStories("FAQ", stories);

      setFaqs(faqData as Array<FAQSection>);
    };

    getStories();
  }, []);

  return (
    <MarketingLayout hero={<HeroContent />}>
      <div className={css.content}>
        {faqs.map((faq, index) => (
          <div key={faq.title} className={css.faqSection}>
            <h2 className={css.title}>{faq.title}</h2>
            {faq.questions.map((question) => (
              // eslint-disable-next-line no-underscore-dangle
              <div key={question._uid} className={css.faqQuestion}>
                <h3 className={css.question}>{question.question}</h3>
                <p
                  className={css.answer}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: question.answer }}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </MarketingLayout>
  );
};
