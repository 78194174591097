import React, { useEffect, useState } from "react";

import { Heading, MarketingHero, MarketingLayout } from "components";
import Storyblok from "utilities/storyblok";

import css from "./Marketing.module.scss";

export interface TextAndHeading {
  _uid: string;
  heading: string;
  alignment: string;
  paragraph: string;
}

export interface TextOnlyPageProps {
  heading: string;
  story: string;
}

const Hero = ({ heading }: { heading: string }) => (
  <MarketingHero color="blue">
    <div className={css.HeroPadding}>
      <Heading className={css.HeroHeadingSmall} variant="h1">
        {heading}
      </Heading>
    </div>
  </MarketingHero>
);

export const TextOnlyPage = ({ heading, story }: TextOnlyPageProps) => {
  const [pageData, setPageData] = useState([]);

  const loadData = async () => {
    const data = await Storyblok.get(`cdn/stories/${story}`);
    setPageData(
      data.data.story.content.body.filter(
        (i: any) => i.component === "Section"
      )[0].content
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <MarketingLayout hero={<Hero heading={heading} />}>
      <div className={css.TextContent}>
        {pageData.map((item: TextAndHeading) => (
          // eslint-disable-next-line no-underscore-dangle
          <div key={item._uid}>
            {item.heading && (
              <Heading variant="h3" align="left">
                {item.heading}
              </Heading>
            )}
            <p>{item.paragraph}</p>
          </div>
        ))}
      </div>
    </MarketingLayout>
  );
};
