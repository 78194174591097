import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { Button, FormMessage, Input, RuleWithText } from "components";
import { validateEmail, validatePassword } from "utilities/forms";
import { JOBS_PATH } from "routes";
import { authActions } from "reducks/auth";
import { selectLoader } from "reducks/loaders";
import { selectUser } from "reducks/user";

import css from "./SignUpWithEmail.module.scss";

const SignUpWithEmail = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const signUpLoader = useSelector(selectLoader(authActions.signUpWithEmail));

  const validateFirstName = (): string => {
    if (firstName.length === 0) {
      return "Your first name can't be blank.";
    }
    return "";
  };

  const validateLastName = (): string => {
    if (lastName.length === 0) {
      return "Your last name can't be blank.";
    }
    return "";
  };

  const validateConfirmPassword = (): string => {
    if (password.length > 0 && password !== confirmPassword) {
      return "Password fields must match.";
    }
    return "";
  };

  const validate = () => {
    const errors = {
      firstName: validateFirstName(),
      lastName: validateLastName(),
      email: validateEmail(email),
      password: validatePassword(password),
      confirmPassword: validateConfirmPassword(),
    };
    setFormErrors(errors);

    // Make sure the length of all the errors equals 0.
    if (Object.values(errors).join("").length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    dispatch(authActions.signUpWithEmailReset());
    const isValid = validate();
    if (isValid) {
      dispatch(
        authActions.signUpWithEmail({ firstName, lastName, email, password })
      );
    }
  };

  const user = useSelector(selectUser);
  // If we're done loading and we have the user, let's go to the app.
  if (!signUpLoader.loading && user.id) {
    return <Redirect to={JOBS_PATH} />;
  }

  return (
    <div className={css.SignUpWithEmail}>
      <RuleWithText text="or sign up with email" />
      <form onSubmit={(e) => handleSubmit(e)}>
        {signUpLoader.error && <FormMessage text={signUpLoader.error} error />}
        <div className={css.FormRowNames}>
          <Input
            label="First Name"
            name="firstName"
            className={css.FirstName}
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={formErrors.firstName}
            autoCapitalize="on"
            autoComplete="given-name"
            autoCorrect="off"
          />
          <Input
            label="Last Name"
            name="lastName"
            className={css.LastName}
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={formErrors.lastName}
            autoCapitalize="on"
            autoComplete="family-name"
            autoCorrect="off"
          />
        </div>
        <div className={css.FormRow}>
          <Input
            label="Email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={formErrors.email}
            autoCapitalize="off"
            autoComplete="email"
            autoCorrect="off"
          />
        </div>
        <div className={css.FormRow}>
          <Input
            label="Password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={formErrors.password}
            autoCapitalize="off"
            autoComplete="new-password"
            autoCorrect="off"
          />
        </div>
        <div className={css.FormRow}>
          <Input
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={formErrors.confirmPassword}
            autoCapitalize="off"
            autoComplete="new-password"
            autoCorrect="off"
          />
        </div>
        <Button
          className={css.SignUpButton}
          id="SignUpWithEmailButton"
          isLoading={signUpLoader.loading}
          type="submit"
        >
          Sign Up
        </Button>
      </form>
    </div>
  );
};

export default SignUpWithEmail;
