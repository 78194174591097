import React, { useRef } from "react";

import { Heading, JobsFilterPanel, JobsList, Nav, Text } from "components";
import { Link, useLocation } from "react-router-dom";
import { SIGN_UP_PATH, getLoginPath } from "routes";
import { ReactComponent as LockSvg } from "images/lock.svg";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import { selectIsUserLoggedIn } from "reducks/user";
import { selectMeta } from "reducks/meta";
import { useSelector } from "react-redux";

import css from "./JobsPage.module.scss";

export const JobsPage = () => {
  const jobsListRef = useRef(null);
  const meta = useSelector(selectMeta);
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const { pathname } = useLocation();

  const getResultListOffset = () => {
    if (jobsListRef?.current) {
      const offset = window.innerWidth >= 992 ? 170 : 105;
      const offsetPosition =
        jobsListRef.current.getBoundingClientRect().top - offset;

      return offsetPosition;
    }

    return 0;
  };
  const executeScroll = () => {
    if (jobsListRef?.current) {
      elementScrollIntoView(jobsListRef.current, {
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const handleSearchInputFocus = () => {
    const currentPosition = window.scrollY;
    const targetOffset = getResultListOffset();

    if (currentPosition < targetOffset) {
      executeScroll();
    }
  };

  const handleSearchQueryChange = () => {
    executeScroll();
  };

  return (
    <div>
      <Nav />
      <div className={css.Content}>
        <Heading variant="h1" align="left" className={css.PageHeading}>
          Jobs
        </Heading>
        <div className={css.FilterPanelWrapper}>
          <JobsFilterPanel
            meta={meta}
            onSearchInputFocus={handleSearchInputFocus}
            onSearchQueryChange={handleSearchQueryChange}
          />
        </div>
        {!isLoggedIn && (
          <div className={css.LogInBannerContainer}>
            <div className={css.LogInBanner}>
              <LockSvg className={css.Lock} />
              <Text>
                <Link to={getLoginPath(pathname)}>Log in</Link> or{" "}
                <Link to={SIGN_UP_PATH}>sign up</Link> to view salary and
                company information
              </Text>
            </div>
          </div>
        )}
        <JobsList ref={jobsListRef} />
      </div>
    </div>
  );
};
