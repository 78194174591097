export const referralStateMap: { [key: number]: string } = {
  5: "Received",
  10: "Reviewed Candidate",
  15: "Presented to Employer",
  20: "Interviewing",
  25: "Didn't Work Out",
  28: "Offer Extended",
  30: "Hired",
  35: "Referral Reward Paid",
};

export const statusToInternalStatusMap: { [key: string]: string } = {
  Received: "received",
  "Reviewed Candidate": "reviewed_candidate",
  "Presented to Employer": "presented_to_employer",
  Interviewing: "interviewing",
  "Didn't Work Out": "didnt_work_out",
  "Offer Extended": "offer_extended",
  Hired: "hired",
  "Referral Reward Paid": "referral_reward_paid",
};

export const didntWorkOutStatus = 25;

// Obviously only one status in here for now, but this will make
// it easy to add more later and helps readability
export const completedNotHiredStatuses = [didntWorkOutStatus];

export const hiredStatuses = [30, 35];

export const dateFormat = "MMM d, yyyy";

export const lastContactTimeframes = {
  Received: [4, 6],
  "Reviewed Candidate": [8, 12],
  "Presented to Employer": [8, 6],
  Interviewing: [2, 3],
  "Offer Extended": [2, 3],
} as { [key: string]: number[] };
