import React, { useEffect, useMemo } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { JOBS_PATH, getLoginPath } from "routes";
import { LoginOauthPayload, authActions } from "reducks/auth";
import { AgilityLoader } from "components";
import { selectLoader } from "reducks/loaders";
import { selectUser } from "reducks/user";
import { useQuery } from "utilities/hooks";

export const OauthCompletePage = () => {
  const { service } = useParams<{ service: LoginOauthPayload["service"] }>();
  const dispatch = useDispatch();
  const query = useQuery();

  const codeQuery = query.get("code") || "";

  // Path to page the user was trying to access before logging in
  const stateQuery = query.get("state");
  const redirectPath = stateQuery ? atob(stateQuery) : JOBS_PATH;

  const oauthLoader = useSelector(selectLoader(authActions.loginOAuth));

  const isLoading = useMemo(() => {
    return oauthLoader.loading || (!oauthLoader.loaded && !oauthLoader.loading);
  }, [oauthLoader]);

  const serviceName = useMemo(() => {
    switch (service) {
      case "linkedin":
        return "LinkedIn";
      case "github":
        return "GitHub";
      case "facebook":
        return "Facebook";
      default:
        return service;
    }
  }, [service]);

  useEffect(() => {
    dispatch(
      authActions.loginOAuth({
        service,
        code: codeQuery,
        redirect_uri: window.location.href.split("?")[0],
      })
    );
  }, [codeQuery, service, dispatch]);

  const user = useSelector(selectUser);

  if (!isLoading && user.id) {
    return <Redirect to={redirectPath} />;
  }

  if (oauthLoader.error) {
    return <Redirect to={getLoginPath(redirectPath)} />;
  }

  return <AgilityLoader text={`Logging in via ${serviceName}...`} />;
};
