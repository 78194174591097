import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button, FormMessage, Input, RuleWithText } from "components";
import { validateEmail, validatePassword } from "utilities/forms";
import { authActions } from "reducks/auth";
import { getForgotPasswordPath } from "routes";
import { selectLoader } from "reducks/loaders";

import css from "./LoginWithEmail.module.scss";

const LoginWithEmail = ({
  routeAfterLogin,
  includeHeader = true,
}: {
  routeAfterLogin?: string;
  includeHeader?: boolean;
}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const loginLoader = useSelector(selectLoader(authActions.loginWithEmail));

  const validate = () => {
    const errors = {
      email: validateEmail(email),
      password: validatePassword(password),
    };
    setFormErrors(errors);

    // Make sure the length of all the errors equals 0.
    if (Object.values(errors).join("").length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    dispatch(authActions.loginWithEmailReset());
    const isValid = validate();
    if (isValid) {
      dispatch(authActions.loginWithEmail({ email, password }));
    }
  };

  return (
    <div className={css.LoginWithEmail}>
      {includeHeader && <RuleWithText text="or log in with email" />}
      <form onSubmit={handleSubmit}>
        {loginLoader.error && <FormMessage text={loginLoader.error} error />}
        <Input
          data-cy="EmailInput"
          label="Email"
          name="email"
          type="email"
          value={email}
          className={css.EmailInput}
          onChange={(e) => setEmail(e.target.value)}
          error={formErrors.email}
          autoCapitalize="off"
          autoComplete="email"
          autoCorrect="off"
        />
        <Input
          data-cy="PasswordInput"
          label="Password"
          name="password"
          type="password"
          className={css.PasswordInput}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={formErrors.password}
          autoCapitalize="off"
          autoComplete="current-password"
          autoCorrect="off"
        />
        <Link
          to={getForgotPasswordPath(routeAfterLogin)}
          className={css.ForgotPasswordLink}
        >
          Forgot password?
        </Link>
        <Button
          className={css.LogInButton}
          data-cy="LogInWithEmailButton"
          id="LogInWithEmailButton"
          isLoading={loginLoader.loading}
          type="submit"
        >
          Log In
        </Button>
      </form>
    </div>
  );
};

export default LoginWithEmail;
