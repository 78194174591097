/* eslint-disable import/no-cycle */

import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Modal, Text } from "components";
import { ReactComponent as FiltersSvg } from "images/filter.svg";
import { selectAppliedFilterCount } from "reducks/jobsFilters";

import {
  JobsFilterPanelContent,
  JobsFilterPanelProps,
} from "./JobsFilterPanel";

import css from "./JobsFilterPanel.module.scss";

export const JobsFilterPanelMobile = (props: JobsFilterPanelProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleFiltersBtnClick = () => setIsOpen(true);
  const handleFilterMenuDismiss = () => setIsOpen(false);
  const appliedFilterCount = useSelector(selectAppliedFilterCount);

  return (
    <>
      {!isOpen && (
        <button
          type="button"
          className={css.FilterMobileBtn}
          onClick={handleFiltersBtnClick}
          data-cy="JobsFilterMobile-Button"
        >
          <FiltersSvg className={css.FiltersIcon} />
          <Text
            tag="span"
            allCaps
            size="small"
            color="white"
            className={css.FilterMobileBtnText}
          >
            {`Filters (${appliedFilterCount})`}
          </Text>
        </button>
      )}
      <Modal
        isOpen={isOpen}
        onDismiss={handleFilterMenuDismiss}
        allowPinchZoom
        size="full"
        className={css.Modal}
        data-cy="JobsFilterMobile-Modal"
      >
        <JobsFilterPanelContent
          {...props}
          isMobile
          onCloseModal={handleFilterMenuDismiss}
        />
      </Modal>
    </>
  );
};
/* eslint-enable import/no-cycle */
