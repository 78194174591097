import React from "react";

import { Link } from "react-router-dom";

// Dependencies
import { PROACTIVE_REFERRAL_PATH } from "routes";
import { Text } from "components";
import noReferralsImg from "images/no-referrals.png";

// Component
import css from "./ProactiveReferralLink.module.scss";

const ProactiveReferralLink = () => {
  return (
    <div
      className={css.ProactiveReferralLink}
      data-cy="ProactiveReferralLinkContainer"
    >
      <img
        src={noReferralsImg}
        alt="No Referrals"
        className={css.NoReferralsImg}
      />
      <Text
        size="large"
        tag="span"
        weight={700}
        color="black"
        className={css.Text}
      >
        Don&apos;t see a job you like?
      </Text>
      <Link
        to={PROACTIVE_REFERRAL_PATH}
        data-cy="ProactiveReferralLink"
        className={css.Link}
      >
        Submit General Referral
      </Link>
    </div>
  );
};

export default ProactiveReferralLink;
