import React from "react";
import cn from "classnames";

import css from "./Panel.module.scss";

interface PanelProps {
  children: React.ReactNode;
  className?: string;
}

const Panel = ({ children, className = "", ...rest }: PanelProps) => (
  <section
    className={cn({ [css.Panel]: true, [className]: !!className })}
    {...rest}
  >
    {children}
  </section>
);

export default Panel;
