import React from "react";
import cn from "classnames";

import css from "./Heading.module.scss";

interface HeadingProps {
  align?: "left" | "right" | "center";
  children: React.ReactNode;
  className?: string;
  variant: "h1" | "h2" | "h3" | "h4";
  isInverted?: boolean;
}

const Heading = ({
  align = "center",
  children,
  className = "",
  variant,
  isInverted = false,
}: HeadingProps) => {
  const Tag = variant;
  return (
    <Tag
      className={cn({
        [css[`align-${align}`]]: true,
        [css.Heading]: true,
        [className]: !!className,
        [css.IsInverted]: isInverted,
      })}
    >
      {children}
    </Tag>
  );
};

export default Heading;
