import React from "react";
import cn from "classnames";

import { Heading, Panel, Text } from "components";

import css from "./ReferralsListItem.module.scss";

export const ReferralListItemLoading = () => {
  return (
    <Panel
      className={cn(css.ReferralsListItem, css.isDetailPage)}
      data-cy="ReferralListItem-Loading"
    >
      <div className={css.WrapperLink}>
        <div className={css.TopSection}>
          <div>
            <Heading variant="h1" className={cn(css.LoadingItem, css.Name)}>
              Loading
            </Heading>
            <div>
              <Text className={cn(css.LoadingItem, css.Email)}>Loading</Text>
              <Text className={cn(css.LoadingItem, css.Phone)}>Loading</Text>
            </div>
          </div>
          <div className={cn(css.LoadingItem, css.RewardInfo)}>Loading</div>
        </div>
        <div className={cn(css.LoadingItem, css.ReferralLoadingItem)}>
          Loading
        </div>
        <div className={cn(css.LoadingItem, css.ReferralLoadingItem)}>
          Loading
        </div>
        <div className={cn(css.LoadingItem, css.ReferralLoadingItem)}>
          Loading
        </div>
      </div>
    </Panel>
  );
};
